import React, { Fragment, useEffect, useState } from "react";
import MainPost from "../components/post/MainPost";
import ShareModal from "../components/common/ShareModal";
import ReportModal from "../components/common/ReportModal";
import PostBlockModal from "../components/common/PostBlockModal";
import { useDispatch, useSelector } from "react-redux";
import { postBlock } from "../redux/apislice/postBlockSlice";
import { REACT_SHARE_MAIN_URL } from "../utils/mainUrl";
import { getHomeData } from "../redux/apislice/homeSlice";
// import { setLabel } from "../redux/apislice/copyRightsSlice";

const HomePage = ({ setLable }) => {
   const dispatch = useDispatch();
   const [sharePost, setSharePost] = useState(false);
   const [reportPost, setReportPost] = useState(false);
   const [postId, setPostId] = useState(null);
   const [postData, setPostData] = useState(null);
   const [postType, setPostType] = useState(null);
   const [blockUser, setBlockUser] = useState(false);
   const [MainData, setMainData] = useState([])
   const { data } = useSelector(state => state.home);


   console.log(MainData, "MainData")
   const handleBlockPost = async (postId) => {
      setPostId(postId);

      try {
         const response = await dispatch(postBlock({ postId }));
         if (postBlock.fulfilled.match(response)) {
            setBlockUser(false)
            setMainData((prevResults) =>
               prevResults.filter((item) => {
                  if ( item.data.id !== postId) {
                     return item;
                  }
               })
            );

            const params = new URL(data.next).searchParams;
            const pageNumber = params.get('page');
            console.log(pageNumber, "pageNumber")
            dispatch(getHomeData(pageNumber)).then((result) => {

               setMainData((prevData) => {
                  const newResults = (result?.results || []).filter(newItem => {
                     switch (newItem.type) {
                        case "post":
                           return !prevData.some(prevItem => prevItem?.data.id === newItem?.data.id);
                        case "repost":
                           return !prevData.some(prevItem => prevItem?.data.id === newItem?.data.id)
                        default:
                           return true;
                     }
                  }
                  );
                  const newData = [...(prevData || []), ...newResults];
                  return newData;
               });
            })

            setBlockUser(false);
         }
      } catch (error) {
         console.log(error)

      }
   }


   useEffect(() => {
      setLable("C / FE / HomePage / V6 /DEC 20, 2024");
   }, [setLable])
   return (
      <Fragment>
         <div className="absolute h-[-webkit-fill-available] w-full">
            <div className="px-2 h-[-webkit-fill-available] overflow-hidden relative">
               <MainPost
                  setSharePost={setSharePost}
                  setReportPost={setReportPost}
                  setPostId={setPostId}
                  setPostData={setPostData}
                  setBlockUser={setBlockUser}
                  setMainData={setMainData}
                  MainData={MainData}
                  setPostType={setPostType}
               />
            </div>

            {sharePost &&
               <ShareModal
                  url={`${REACT_SHARE_MAIN_URL}/postview/${postId}`}
                  onClose={() => setSharePost(false)}
               />
            }

            {/* Report Modal */}
            {reportPost &&
               <ReportModal
                  setReportPost={setReportPost}
                  postId={postId}
                  postData={postData}
               />
            }

            {/* Block Modal */}
            {blockUser &&
               <PostBlockModal
                  setBlockUser={setBlockUser}
                  postData={postData}
                  handleSubmit={handleBlockPost}
               />
            }
         </div>
      </Fragment>
   )
}

export default HomePage