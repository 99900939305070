import React, { useState, useEffect, useRef } from "react";
import { LuDot } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircle } from "react-icons/io5";
import { MdOutlineAddReaction } from "react-icons/md";
import { messageChat } from "../../data";
import { IoSend } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import { IoImage } from "react-icons/io5";
import { IoCameraSharp } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";

const MessageChat = () => {
   const lable = "C / FE / CHATTING / V2 / MAY 09, 2024";

   const navigate = useNavigate();
   const [openReactionId, setOpenReactionId] = useState(null);
   const [isInputFocused, setIsInputFocused] = useState(false);
   const [isMenuOpen, setIsMenuOpen] = useState(false);
   const menuRef = useRef(null);

   const toggleEmoji = (id) => {
      setOpenReactionId((prevId) => (prevId === id ? null : id));
   };

   const handleInputFocus = () => {
      setIsInputFocused(true);
   };

   const handleInputBlur = () => {
      setIsInputFocused(false);
   };

   const handleMenuToggle = () => {
      setIsMenuOpen((prev) => !prev);
   };

   const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
         setIsMenuOpen(false);
      }
   };

   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   const menuItems = ["Pin Chat", "Mute Chat", "Delete Chat", "Block"];

   return (
      <div className="pb-0">
         <div className="sticky top-0 bg-white">
            <div className="bg-[#d9d9d9] text-center">
               {/* <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p> */}
               <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
            </div>

            <div className="flex items-center justify-between bg-white border-b border-gray-300">
               <div className="flex items-center py-1 px-2">
                  <IoCaretBackCircle
                     onClick={() => navigate(-1)}
                     className="text-[20px] cursor-pointer"
                  />
                  <img
                     alt="img"
                     className="h-10 w-10 rounded-full object-cover cursor-pointer ml-3"
                     src="https://images.unsplash.com/photo-1534180477871-5d6cc81f3920?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  />
                  <div className="ml-2">
                     <p className="text-[14px] font-bold cursor-pointer">
                        Emily Johnson
                     </p>
                     <p className="text-[10px] text-gray-500">
                        Last seen 2 hours ago
                     </p>
                  </div>
               </div>
               <div className="relative">
                  <BsThreeDotsVertical className="text-[25px] cursor-pointer" onClick={handleMenuToggle} />
                  {isMenuOpen && (
                     <div ref={menuRef} className="absolute right-2 mt-1 w-28 bg-white border border-gray-400 overflow-hidden rounded-md shadow-lg">
                        {menuItems.map((item, index) => (
                           <p key={index} className="px-2 py-1 text-[12px] cursor-pointer text-gray-600 hover:bg-gray-100 hover:text-black">
                              {item}
                           </p>
                        ))}
                     </div>
                  )}
               </div>
            </div>
         </div>

         <div className="px-2 pt-2 h-[72vh] overflow-y-scroll">
            {messageChat.map((data) => (
               <div key={data.id}>
                  {data.isSender ? (
                     <div className="flex flex-col items-start justify-start mb-1">
                        {data.message &&
                           <p className="bg-gray-800 text-white py-2 px-2 text-[13px] leading-[14px] rounded-br-xl rounded-tl-xl rounded-tr-xl max-w-[70%]">
                              {data.message}
                           </p>
                        }
                        {openReactionId === data.id &&
                           <div className="mt-1 flex flex-row items-center gap-1 text-[15px] border border-gray-500 rounded-full bg-[#ffffff27] px-2 py-[4px] w-fit">
                              <p className="cursor-pointer transform transition-transform hover:scale-125" onClick={() => setOpenReactionId(null)}>👍</p>
                              <p className="cursor-pointer transform transition-transform hover:scale-125" onClick={() => setOpenReactionId(null)}>❤️</p>
                              <p className="cursor-pointer transform transition-transform hover:scale-125" onClick={() => setOpenReactionId(null)}>😃</p>
                              <p className="cursor-pointer transform transition-transform hover:scale-125" onClick={() => setOpenReactionId(null)}>😥</p>
                              <p className="cursor-pointer transform transition-transform hover:scale-125" onClick={() => setOpenReactionId(null)}>👎</p>
                              <p className="cursor-pointer transform transition-transform hover:scale-125" onClick={() => setOpenReactionId(null)}>😡</p>
                              <IoIosCloseCircle className="cursor-pointer text-[18px] transform transition-transform hover:scale-125" onClick={() => setOpenReactionId(null)} />
                           </div>
                        }
                        {data.imageMedia &&
                           <img
                              alt="img"
                              src={data.imageMedia}
                              className="rounded-2xl h-[11rem] w-[11rem] object-cover cursor-pointer"
                           />
                        }
                        <div className="flex items-start gap-1 mt-[3px]">
                           <p className="text-[10px]">{data.messageTime}</p>
                           {openReactionId !== data.id && (
                              <MdOutlineAddReaction
                                 className="text-[13px] cursor-pointer"
                                 onClick={() => toggleEmoji(data.id)}
                              />
                           )}
                        </div>
                     </div>
                  ) : (
                     <div className="flex flex-col items-end justify-end mb-1">
                        {data.message &&
                           <p className="bg-blue-600 text-white py-2 px-2 text-[13px] leading-[14px] rounded-bl-xl rounded-tl-xl rounded-tr-xl max-w-[70%]">
                              {data.message}
                           </p>
                        }
                        {data.imageMedia &&
                           <img
                              alt="img"
                              src={data.imageMedia}
                              className="rounded-2xl h-[11rem] w-[11rem] mt-[2px] object-cover cursor-pointer"
                           />
                        }
                        {data.videoMedia &&
                           <video className="h-full max-w-[75%] rounded-lg" controls autoPlay muted>
                              <source src={data.videoMedia} type="video/mp4" />
                           </video>
                        }
                        <div className="flex items-center mt-[3px]">
                           <p className="text-[10px]">{data.messageTime}</p>
                           {data.seen && <LuDot className="text-[10px]" />}
                           {data.seen && <p className="text-[10px]">SEEN</p>}
                        </div>
                     </div>
                  )}
               </div>
            ))}
         </div>

         <div className="absolute w-full bottom-0 bg-gray-300 px-2 py-2">
            <div className="flex items-center justify-between gap-1">
               <div className="bg-black p-2 rounded-full cursor-pointer">
                  <IoCameraSharp className="text-white" />
               </div>
               <div className="bg-black p-2 rounded-full cursor-pointer">
                  <IoImage className="text-white" />
               </div>
               <input
                  type="text"
                  placeholder="Type message"
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  className="bg-black rounded-full focus:outline-none w-full px-3 py-1 text-white"
               />
               {isInputFocused && (
                  <div className="bg-black p-2 rounded-full cursor-pointer">
                     <IoSend className="text-white" />
                  </div>
               )}
            </div>
         </div>

      </div>
   )
}

export default MessageChat