import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoCaretBackCircle } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { Textarea } from "@material-tailwind/react";
import { IoImage, IoVideocam, IoCloseCircle } from "react-icons/io5";
import { fetchProfile } from "../../redux/apislice/profileSlice";
import ScaleLoader from "react-spinners/ScaleLoader";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { createGroupPost, updateGroupPost } from "../../redux/apislice/group/groupPostSlice";
import { getGroupDetails } from "../../redux/apislice/group/userGroupSlice";

const GroupUpload = () => {
    const label = "C / FE / JOINED GROUP / UPLOAD / V1 / JULY 25, 2024";

    const params = useParams();
    const { groupId, postId } = params;

    // Fetching user profile
    const [userDetails, setUserDetails] = useState(null);
    const [groupDetails, setGroupDetails] = useState(null);
    const profile = useSelector(state => state.profile);
    const groupPosts = useSelector(state => state.groupPosts);
    const { loading, posts } = groupPosts
    // const groupDetail = useSelector((state) => state.userGroup)
    // const { groupDetails } = groupDetail

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageFile, setSelectedImageFile] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedVideoFile, setSelectedVideoFile] = useState(null);
    const [text, setText] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const imageInputRef = useRef();
    const videoInputRef = useRef();

    const handleImageChange = (e) => {
        setSelectedImage(URL.createObjectURL(e.target.files[0]));
        setSelectedImageFile(e.target.files[0]);
        setSelectedVideo(null);
        e.target.value = null;
    };

    const handleVideoChange = (e) => {
        setSelectedVideo(URL.createObjectURL(e.target.files[0]));
        setSelectedVideoFile(e.target.files[0]);
        setSelectedImage(null);
        e.target.value = null;
    };

    const handleClear = () => {
        setSelectedImage(null);
        setSelectedVideo(null);
    };

    const handleCreatePost = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("body", text);
        if (selectedImageFile instanceof File) {
            formData.append("image", selectedImageFile);
        }
        if (selectedVideoFile instanceof File) {
            formData.append("video", selectedVideoFile);
        }

        try {
            const response = await dispatch(createGroupPost({ groupId, formData }));
            if (response.payload) {
                setSelectedImage(null);
                setSelectedVideo(null);
                setText("");
                navigate(`/group/joined/home/${groupId}`);
            }
        } catch (error) {
            console.log(error, "errorList")

        }
    }

    const handleUpdateGroupPost = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("body", text);
        if (selectedImageFile instanceof File) {
            formData.append("image", selectedImageFile);
        }
        if (selectedVideoFile instanceof File) {
            formData.append("video", selectedVideoFile);
        }

        try {
            const response = await dispatch(updateGroupPost({ postId, groupId, formData }));
            if (response.payload) {
                setSelectedImage(null);
                setSelectedVideo(null);
                setText("");
                navigate(`/group/joined/home/${groupId}`);
            }
        } catch (error) {
            console.log(error, "errorList")

        }

    }

    const fetchGroupDetails = useCallback(async () => {
        try {
            const response = await dispatch(getGroupDetails({ groupId })).unwrap();
            console.log(response , "response")
            setGroupDetails(response);
        } catch (error) {
            console.error("Failed to fetch group details:", error);
        }
    }, [groupId, dispatch])

    useEffect(() => {

        if (postId) {
            setSelectedImage(posts?.image);
            setSelectedVideo(posts?.video);
            setText(posts?.body);
        }

        dispatch(fetchProfile());
        fetchGroupDetails();
    }, [dispatch, postId, posts?.image, posts?.video, posts?.body, fetchGroupDetails]);

    useEffect(() => {
        setUserDetails(profile.user);
    }, [profile]);

    return (
        <div className="bg-white">
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p> */}
                <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
            </div>

            <form className="mt-2 mb-[7rem]" onSubmit={(e) => postId ? handleUpdateGroupPost(e) : handleCreatePost(e)}>
                <div className="px-2 flex items-center justify-between">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[30px] md:text-[23px] cursor-pointer hover:text-[#1a1a1a]"
                    />
                    {postId ? loading ? <ScaleLoader
                        color={"#000"}
                        loading={loading}
                        height={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    /> : <button type="submit" className="text-[15px] md:text-[12px] bg-[#000000] hover:bg-[#1a1a1a] text-white px-3 py-1 rounded-full">
                        Update
                    </button>
                        : loading ? <ScaleLoader
                            color={"#000"}
                            loading={loading}
                            height={10}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        /> : <button type="submit" className="text-[15px] md:text-[12px] bg-[#000000] hover:bg-[#1a1a1a] text-white px-3 py-1 rounded-full">
                            Post
                        </button>}
                </div>

                <div className="px-2 flex items-center justify-between mt-3 mb-3">
                    <div className="flex items-center justify-start gap-2">
                        {profile.loading ? <Skeleton circle width={40} height={40} /> :
                            <img
                                alt="profile"
                                src={userDetails?.avatar_url}
                                className="h-11 w-11 md:h-8 md:w-8 rounded-full object-cover"
                            />
                        }
                        <button className="border border-[#000000] text-[#000000] text-[18px] md:text-[10px] px-3 py-[3px] rounded-full">
                            {groupDetails?.name}
                        </button>
                    </div>
                    <div className="flex items-center gap-1">
                        <div
                            onClick={() => imageInputRef.current.click()}
                            className="bg-[#000000] hover:bg-[#1a1a1a] text-white p-[7px] md:p-[5px] cursor-pointer rounded-full"
                        >
                            <IoImage className="text-[20px] md:text-[15px]" />
                        </div>
                        <div
                            onClick={() => videoInputRef.current.click()}
                            className="bg-[#000000] hover:bg-[#1a1a1a] text-white p-[7px] md:p-[5px] cursor-pointer rounded-full"
                        >
                            <IoVideocam className="text-[20px] md:text-[15px]" />
                        </div>
                    </div>
                </div>

                <div className="px-2">
                    <Textarea required variant="outlined" label="What's happening?" value={text} onChange={(e) => setText(e.target.value)} />
                    <input type="file" ref={imageInputRef} style={{ display: 'none' }} onChange={handleImageChange} accept='image/*' />
                    <input type="file" ref={videoInputRef} style={{ display: 'none' }} onChange={handleVideoChange} accept='video/*' />
                    {(selectedImage || selectedVideo) && (
                        <div className="flex flex-col items-end">
                            <IoCloseCircle
                                onClick={handleClear}
                                className="text-black hover:text-[#1a1a1a] text-[28px] md:text-[22px] cursor-pointer mb-1"
                            />
                            {selectedImage ?
                                <img
                                    alt="Selected"
                                    src={selectedImage}
                                    className="w-[65%] md:w-1/2 h-fit rounded-md"
                                />
                                :
                                <video
                                    autoPlay
                                    muted
                                    loop
                                    src={selectedVideo}
                                    className="w-[65%] md:w-1/2 h-fit rounded-md"
                                />
                            }
                        </div>
                    )}
                </div>
            </form>
        </div>
    )
}

export default GroupUpload