import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import { post } from "../../data";
import { FaBookmark, FaRegBookmark } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export default function NewsSearch() {
    const [bookmarks, setBookmarks] = useState([]);
    const navigate = useNavigate()

    const handleBookmark = (id) => {
        if (!bookmarks.includes(id)) {
            setBookmarks((prev) => [...prev, id]);
        }
        else {
            setBookmarks(bookmarks.filter((bookmark) => bookmark !== id));
        }
    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    const responsive1 = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            slidesToSlide: 1
        }
    };

    const uniquePosts = [
        {
            id: 1,
            img: "https://plus.unsplash.com/premium_photo-1699577272317-a64e1eaaa9c8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExNXxfaGItZGw0US00VXx8ZW58MHx8fHx8",
            headline: "Breaking News: Market Reaches New Highs",
        },
        {
            id: 2,
            img: "https://plus.unsplash.com/premium_photo-1664033881643-d9911fbf3d5e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDY5fF9oYi1kbDRRLTRVfHxlbnwwfHx8fHw%",
            headline: "Tech Industry Sees Major Innovations in AI",
        },
        {
            id: 3,
            img: "https://images.unsplash.com/photo-1478144849792-57dda02c07f7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExMXxfaGItZGw0US00VXx8ZW58MHx8fHx8",
            headline: "Global Summit Addresses Climate Change Solutions",

        },
    ];

    const latestNews = [
        {
            headline: "Breaking News: Market Reaches New Highs",
            time: "17:20",
            date: "December 12, 2024"
        },
        {
            headline: "Tech Industry Sees Major Innovations in AI",
            time: "16:45",
            date: "December 12, 2024"
        },
        {
            headline: "Global Summit Addresses Climate Change Solutions",
            time: "15:30",
            date: "December 12, 2024"
        }
    ];

    return (
        <div className="grid grid-cols-12 overflow-y-scroll">
            <div className="col-span-12 px-2 mt-1">
                <p className="text-[18px] font-extrabold py-1">Latest News</p>
            </div>
            <div className="px-2 col-span-12 mt-1">
                <Carousel
                    arrows={true}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true}
                    infinite={false}
                    slidesToSlide={1}
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                >
                    {latestNews?.map((data) => (
                        <div className="rounded-md px-[8px] py-[3px] cursor-pointer h-28 w-full bg-[#7c3aed] p-2" onClick={() => navigate("/news/read")} key={data}>
                            <p className="text-white text-[22px] font-semibold line-clamp-2 p-2 ">{data.headline}....</p>
                            <div className="text-[12px] text-white flex">
                                <p className="px-1">{data.date}</p> -
                                <p className="px-1">{data.time}</p>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
            <div className="col-span-12 px-2 my-2 ">
                <div className="flex justify-between items-center">
                    <p className="text-[18px] font-extrabold py-1">Around the world</p>
                    <p className="text-[11px] font-semibold py-1 text-gray-600 underline cursor-pointer" onClick={() => navigate("/news/seemore")}>See more</p>
                </div>
                <div className="mt-0">
                    <Carousel
                        arrows={true}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={responsive1}
                        ssr={true}
                        infinite={false}
                        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                        className="flex gap-2"
                    >
                        {uniquePosts?.map((data) => (
                            <div className="w-36 rounded-xl gap-2 flex justify-center items-center">
                                <div className="h-44 relative w-full cursor-pointer" onClick={() => navigate("/news/read")}>
                                    <img src={data.img} alt="img" className="w-full h-full object-cover rounded-xl" />
                                    <div className="bg-gray-200 absolute bottom-0 h-14.5 w-full rounded-xl">
                                        <p className="text-[12px] p-2 font-bold line-clamp-3">{data.headline}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
            <div className="col-span-12 px-2 mt-2">
                {post.map((data) => (
                    <div className="w-full grid grid-cols-12 mt-2">
                        <div className="col-span-4">
                            <img src={data.profileImage} alt="img" className="rounded-md h-24 w-32 object-cover cursor-pointer" onClick={() => navigate("/news/read")} />
                        </div>
                        <div className="col-span-8 p-2 grid grid-cols-12">
                            <p className="col-span-12 text-[14px] font-normal leading-[16px] mt-[-8px] cursor-pointer" onClick={() => navigate("/news/read")}>{data.bio}</p>
                            <div className="col-span-12 flex justify-between items-center">
                                <p className="text-[12px] font-normal text-gray-400">{data.postTimeAgo}</p>
                                <div>
                                    {bookmarks.includes(data.id) ? (
                                        <FaBookmark
                                            className="text-[12px] text-blue-500 cursor-pointer"
                                            onClick={() => handleBookmark(data.id)}
                                        />
                                    ) : (
                                        <FaRegBookmark
                                            className="text-[12px] cursor-pointer"
                                            onClick={() => handleBookmark(data.id)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
