import React, { Fragment, useState } from "react";
import SliderMenu from "./SliderMenu";
import { HiMiniBars3 } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircle, IoLocationOutline } from "react-icons/io5";
import News from "./News";

const YourNeighborPost = () => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <Fragment>
            <div className="sticky top-0 bg-white pb-[2px] z-10">
                <div className="flex items-center justify-between border-b border-gray-400 p-2">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[24px] cursor-pointer"
                    />
                    <div className="flex flex-col items-center">
                        <p className="font-bold text-[18px]">
                            Your Neighborhood
                        </p>
                        <p className="flex items-center text-[10px] text-gray-500">
                            <IoLocationOutline className="text-[14px] ml-[-3px]" />
                            Downtown, Springfield
                        </p>
                    </div>
                    <HiMiniBars3
                        onClick={toggleMenu}
                        className="text-[24px] cursor-pointer"
                    />
                </div>
            </div>

            <div className="p-2 flex items-center justify-between gap-x-2 border-b border-gray-400">
                <div className="flex items-center justify-center mx-2 text-white h-[30px] w-full cursor-pointer rounded-full transition-colors duration-300 bg-[#8a8686]">
                    <p className="text-[12px]">
                        Your Neighbor Post
                    </p>
                </div>
            </div>

            <div className="mt-2">
                <News />
            </div>

            {menuOpen &&
                <SliderMenu
                    toggleMenu={toggleMenu}
                    onClose={() => setMenuOpen(false)}
                />
            }
        </Fragment>
    )
}

export default YourNeighborPost