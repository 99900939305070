import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoAddOutline, IoCaretBackCircle, IoPersonAddSharp, IoSearch } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaImage, FaShareAlt } from "react-icons/fa";
import { MdReport, MdLogout, MdNotificationAdd } from "react-icons/md";
// import { suggestedGroups } from "../../../data";
import { IoMdAddCircle } from "react-icons/io";
import ShareModal from "../../common/ShareModal";
import { Avatar } from "@material-tailwind/react";
import AccountOwnerGroupAbout from "./AccountOwnerGroupAbout";
import AccountOwnerGroupPost from "./AccountOwnerGroupPost";
import AccountOwnerGroupMedia from "./AccountOwnerGroupMedia";
import YesNoModal from "../../common/YesNoModal";
import { deleteGroup, getGroupDetails, updateGroup } from "../../../redux/apislice/group/userGroupSlice";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin3Fill } from "react-icons/ri";
import { assignAdmin } from "../../../redux/apislice/group/membersListSlice";
import { getGroupPosts } from "../../../redux/apislice/group/groupPostSlice";
import { CiEdit } from "react-icons/ci";
import { assignOwner, removeGroupSelf } from "../../../redux/apislice/group/groupAdminSlice";


const GroupOwnerHome = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const { groupId } = params
    const [activeTab, setActiveTab] = useState('All');
    const [prevTab, setPrevTab] = useState('All');
    const [direction, setDirection] = useState('left');
    const [menuOpen, setMenuOpen] = useState(false);
    const [showShareModel, setShareModalOpen] = useState(false);
    const [reportModalOpen, setReportModalOpen] = useState(false);
    const [leaveModalOpen, setLeaveModalOpen] = useState(false);
    const [groupDetails, setGroupDetails] = useState({});
    const profile = useSelector(state => state.profile);
    const groupPosts = useSelector(state => state.groupPosts)
    const adminData = useSelector(state => state.groupAdmin)
    const { loading, posts } = groupPosts;
    const [postsData, setPostsData] = useState([]);


    const mediaData = postsData?.filter(post => post.video || post.image ? post : null);
    const navigate = useNavigate();
    const menuRef = useRef(null);
    const threeDotsRef = useRef(null);
    const handleShareClick = (url) => {
        setShareModalOpen(true);
    }


    function getRelativeTime(isoDateString) {
        const inputDate = new Date(isoDateString); // Parse the ISO date string
        const now = new Date(); // Current time
        const diffInMs = now - inputDate; // Difference in milliseconds

        // Convert the difference to seconds, minutes, hours, days, and years
        const diffInSeconds = Math.floor(diffInMs / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
        const diffInYears = Math.floor(diffInDays / 365);

        // Determine the relative time string
        if (diffInSeconds < 60) {
            return `${diffInSeconds}s`;
        } else if (diffInMinutes < 60) {
            return `${diffInMinutes}m`;
        } else if (diffInHours < 24) {
            return `${diffInHours}h`;
        } else if (diffInDays < 365) {
            return `${diffInDays}d`;
        } else {
            return `${diffInYears}y`;
        }
    }


    const handleTabChange = (newTab) => {
        if (newTab !== activeTab) {
            setDirection(newTab > activeTab ? 'right' : 'left');
            setPrevTab(activeTab);
            setActiveTab(newTab);
        }
    };


    const renderContent = () => {
        switch (activeTab) {
            case 'GroupInfo':
                return <AccountOwnerGroupAbout groupDetails={groupDetails} groupId={groupId} moderatorsPath={`/account/owner/group/moderators/${groupId}`} />
            case 'Media':
                return <AccountOwnerGroupMedia onShare={handleShareClick} getRelativeTime={getRelativeTime} mediaData={mediaData} />
            default:
                return <AccountOwnerGroupPost onShare={handleShareClick} getRelativeTime={getRelativeTime} groupId={groupId} loading={loading} postData={postsData} />;
        }
    };

    const getLabel = () => {
        switch (activeTab) {
            case 'Media':
                return "C-FE-ACCOUNT OWNER GROUP HOME-MEDIA-AUGUST-17, 2024";
            case 'GroupInfo':
                return "C-FE-ACCOUNT OWNER GROUP HOME-GroupInfo -AUGUST 16, 2024";
            default:
                return "C-FE-ACCOUNT OWNER GROUP HOME-V1-AUGUST 16, 2024";
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickOutside = (event) => {
        if (
            menuRef.current && !menuRef.current.contains(event.target) &&
            threeDotsRef.current && !threeDotsRef.current.contains(event.target)
        ) {
            setMenuOpen(false);
        }
    };

    const handleReport = () => {
        setMenuOpen(false);
        setReportModalOpen(true);
    }

    const handleLeave = async () => {
        console.log(groupDetails.admin_count > 2, groupDetails.created_by.id === profile.user.id, "test")

        if (groupDetails.admin_count > 2 && groupDetails.created_by.id === profile.user.id) {
            console.log("admin count is greater than 2")
            await dispatch(removeGroupSelf({ groupId }))
            await dispatch(assignOwner(true))
            setMenuOpen(false);
            setLeaveModalOpen(true);// assign new owner
        } else if (groupDetails.admin_count > 1 && groupDetails.admin_count < 2) {
            try {
                await dispatch(removeGroupSelf({ groupId }))
                // navigate("/group/list")

            } catch (error) {
                console.log(error)

            }
        } else {
            setMenuOpen(false);
            setLeaveModalOpen(true);
        }
    }
    const handleAssignAdmin = () => {
        setLeaveModalOpen(false)
        dispatch(assignAdmin(true))
        navigate(`/account/owner/group/members/${groupId}`)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    /* GET GROUP DETAILS API CALL */
    const fetchGroupDetails = useCallback(async () => {
        try {
            const response = await dispatch(getGroupDetails({ groupId })).unwrap();
            await dispatch(getGroupPosts({ groupId })).unwrap();
            setGroupDetails(response);
        } catch (error) {
            console.error("Failed to fetch group details:", error);
        }
    }, [groupId, dispatch])

    /* GROUP DELETE API CALL */
    /* WHEN USER CREATE THE GROUP NO ONE JOIN, USER CAN ABLE TO DELETE THIS GROUP */

    const handleDeleteGroup = async () => {
        try {
            const responce = await dispatch(deleteGroup({ groupId }));
            if (responce.payload.message) {
             
                navigate("/group/list")
            }
        } catch (error) {
            console.error("Failed to delete group:", error);
        }
    }

    /* this api for get the Posts list  */

    const fetchPosts = useCallback(async () => {
        // fetch posts
        await dispatch(getGroupPosts({ groupId })).unwrap();
    }, [dispatch, groupId]);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('background_image', file);

            try {
                const response = await dispatch(updateGroup({ groupId, formData })).unwrap();
                if (response) {
                    fetchGroupDetails()
                    setMenuOpen(false);
                }
                // Handle success (e.g., show a success message or update the UI)
            } catch (error) {
                console.error("Failed to upload wallpaper:", error);
                // Handle error (e.g., show an error message)
            } finally {
                // Clear the input
                event.target.value = null;
            }
        }
    };

    const handleClick = () => {
        document.getElementById('wallpaperInput').click();
    };


    useEffect(() => {
        fetchPosts();
    }, [fetchPosts]);

    useEffect(() => {
        setPostsData(Array.isArray(posts) ? posts : []);
    }, [posts])

    useEffect(() => {
        fetchGroupDetails()
    }, [fetchGroupDetails])


    // Label & CopyRight
    const label = getLabel();

    return (
        <div className="bg-white h-full overflow-y-scroll overflow-x-hidden relative ">
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p> */}
                <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
            </div>
            <div className="relative">
                <div className="relative">
                    <img
                        alt="nature"
                        className="w-full h-[10rem] object-cover opacity-75"
                        src={groupDetails?.background_image}
                    />
                    <div className="absolute top-0 left-2 flex justify-start items-center w-full h-full">
                        <img alt="nature"
                            className="w-[5rem] h-[5rem] object-cover rounded-full border-2 border-white "
                            src={groupDetails?.image} />
                    </div>

                    <div className="absolute w-full flex justify-between px-2 top-2">
                        <div className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full">
                            <IoCaretBackCircle
                                onClick={() => navigate(-1)}
                                className="text-white cursor-pointer"
                            />
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full">
                                <IoSearch
                                    className="text-white cursor-pointer"
                                    onClick={() => navigate(`/group/post/search/${groupId}`)}
                                />
                            </div>

                            <div
                                className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full"
                                onClick={toggleMenu}
                                ref={threeDotsRef}
                            >
                                <BsThreeDotsVertical
                                    className="text-white cursor-pointer"
                                />
                            </div>
                        </div>
                        {menuOpen && (
                            <div
                                className="absolute top-12 right-3 bg-white text-black rounded-md border-[0.4px] border-[#adadad]"
                                ref={menuRef}
                            >
                                <div className="flex items-center justify-between gap-3 px-3 py-1 border-b-[0.4px] border-[#b2b2b2] hover:font-bold" onClick={() => setShareModalOpen(true)}>
                                    <p className="text-[13px] cursor-pointer">Share</p>
                                    <FaShareAlt className="h-4 w-4" />
                                </div>
                                <div className="flex items-center cursor-pointer justify-between gap-3 px-3 py-1 border-b-[0.4px] border-[#b2b2b2] hover:font-bold" onClick={() => handleReport()}>
                                    <p className="text-[13px]" >Report</p>
                                    <MdReport className="h-4 w-4" />
                                </div>
                                {groupDetails?.created_by?.id === profile?.user?.id && <div className="flex cursor-pointer items-center justify-between gap-3 px-3 py-1 border-b-[0.4px] border-[#b2b2b2] hover:font-bold" onClick={handleClick}>
                                    <p className="text-[13px] cursor-pointer">Wallpaper</p>
                                    <FaImage className="h-4 w-4" />
                                    <input
                                        type="file"
                                        id="wallpaperInput"
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                        accept="image/*"
                                    />
                                </div>}
                                {groupDetails?.created_by?.id === profile?.user?.id && <div className="flex cursor-pointer items-center justify-between gap-3 px-3 py-1 border-b-[0.4px] border-[#b2b2b2] hover:font-bold" onClick={() => navigate(`/group/update/${groupId}`)}>
                                    <p className="text-[13px] ">Update</p>
                                    <CiEdit className="h-4 w-4" />
                                </div>}

                                {groupDetails?.members_count <= 1 && groupDetails?.created_by?.id === profile?.user?.id ?
                                    <div className="flex items-center justify-between cursor-pointer gap-3 px-3 py-1 hover:font-bold" onClick={() => handleDeleteGroup()}> 
                                        <p className="text-[13px] " >Delete</p>
                                        <RiDeleteBin3Fill className="h-[14px] w-[14px]" />
                                    </div>
                                    : <div className="flex items-center cursor-pointer justify-between gap-3 px-3 py-1 hover:font-bold" onClick={() => handleLeave()}>
                                        <p className="text-[13px] " >Leave</p>
                                        <MdLogout className="h-[14px] w-[14px]" />
                                    </div>
                                }
                            </div>
                        )}
                    </div>

                    <div className="flex gap-2 absolute bottom-0 w-full  justify-between px-2">
                        <p className="font-bold text-[23px] text-white shadow-md">{groupDetails?.name}</p>
                        <div className="flex items-center justify-end w-24 gap-2">
                            <MdNotificationAdd
                                onClick={() => navigate("/group/notification")}
                                className="text-white cursor-pointer border border-white rounded-full p-1 text-[24px]"
                            />
                            <IoPersonAddSharp
                                onClick={() => navigate(`/account/owner/group/invitemembers/${groupId}`)}
                                className="text-white cursor-pointer border border-white rounded-full p-1 text-[24px]"
                            />
                        </div>
                    </div>
                </div>
                <div className="px-2 pb-1 bg-[#0a0a12] text-white">
                    <p className="text-sm line-clamp-2 pt-2">{groupDetails?.type}</p>

                    <div className="flex justify-between items-start pt-[6px]">
                        {/* {suggestedGroups.filter(group => group.id === 2).map((data) => ( */}
                            <div className="flex items-center -space-x-3">
                                {groupDetails?.latest_members_avatars?.map((member ,index) => (
                                    <Avatar
                                        key={member.index}
                                        variant="circular"
                                        alt={member.index}
                                        className="border-2 border-white h-5 w-5 cursor-pointer hover:z-10 focus:z-10"
                                        src={member.avatar}
                                    />
                                ))}
                                <p className="pl-5 text-xs">{groupDetails.members_count} Members</p>
                            </div>
                        {/* ))} */}

                    </div>

                    <div className="flex flex-wrap mt-1 gap-2">
                        {["#Nimal", "#Nature", "#Birds", "#Nature", "#Nature", "#Birds"].slice(0, 3).map((data, index) => (
                            <p key={index} className="border-2 border-white rounded-full px-3 text-sm inline-block text-center cursor-pointer hover:bg-blue-500">{data}</p>
                        ))}

                        <IoMdAddCircle
                            className="text-white cursor-pointer  rounded-full p-1 text-[24px]"
                        />
                    </div>
                </div>
                <div className="sticky top-0 w-full flex items-center justify-between px-4 py-2 bg-black z-10 text-white text-[14px] border-b-[0.4px] border-[#424242]">
                    <p
                        className={`cursor-pointer ${activeTab === 'All' ? 'text-white border-b-2 border-blue-500' : 'text-gray-400'}`}
                        onClick={() => handleTabChange('All')}
                    >
                        All Post
                    </p>
                    <p
                        className={`cursor-pointer ${activeTab === 'Media' ? 'text-white border-b-2 border-blue-500' : 'text-gray-400'}`}
                        onClick={() => handleTabChange('Media')}
                    >
                        Media
                    </p>
                    <p
                        className={`cursor-pointer ${activeTab === 'GroupInfo' ? 'text-white border-b-2 border-blue-500' : 'text-gray-400'}`}
                        onClick={() => handleTabChange('GroupInfo')}
                    >
                        GroupInfo
                    </p>
                </div>
                <div className="relative mb-28">
                    <div className={`mt-2 mb-4 transition-transform duration-300 ease-in-out ${activeTab !== prevTab ? (direction === 'left' ? 'animate-slideInLeft' : 'animate-slideInRight') : ''}`}>
                        {renderContent()}
                    </div>
                    {activeTab === "All" &&
                        <div onClick={() => navigate(`/account/owner/group/upload/${groupId}`)} className="sticky bottom-40 md:bottom-28  h-10 w-10 z-40  text-white text-[14px] float-end mx-3 cursor-pointer">
                            <div className="w-full h-full flex justify-end">
                                <div className="h-10 w-10 rounded-full bg-light-blue-500 flex justify-center items-center">
                                    <IoAddOutline className="text-white text-[1.3rem]" />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {showShareModel && (
                <ShareModal url={`https://cagzeynida.com/account/owner/group/${groupId}`} onClose={() => setShareModalOpen(false)} />
            )}
            {reportModalOpen && (
                <YesNoModal
                    type="report"
                    heading="Submit Report"
                    onClose={() => setReportModalOpen(false)}
                />
            )}
            {leaveModalOpen && (
                <YesNoModal
                    type="leave"
                    heading={adminData?.OwnerAssigning ? "":"Are You Sure You Want to Leave?"}
                    onClose={() => setLeaveModalOpen(false)}
                    onYes={handleAssignAdmin}
                />
            )}
        </div>
    );
};

export default GroupOwnerHome;


