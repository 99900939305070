import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import securityReducer from "./apislice/securitySlice";
import authReducer from "./apislice/authSlice";
import profileReducer from "./apislice/profileSlice";
import postReducer from "./apislice/postSlice";
import homeReducer from "./apislice/homeSlice";
import commentReducer from "./apislice/commentSlice";
import likedPostReducer from "./apislice/LikedPostsSlice";
import totalUserReducer from "./apislice/totalUserSlice";
import otherUserProfileReducer from "./apislice/otherUserSlice";
import hashtagReducer from "./apislice/hashtagSlice";
import notificationsReducer from "./apislice/notificationsSlice";
import repostReducer from "./apislice/repostSlice";
import savedReducer from "./apislice/savedSilce";
import userGroupReducer from "./apislice/group/userGroupSlice";
import membersListReducer from "./apislice/group/membersListSlice";
import groupPostsReducer from "./apislice/group/groupPostSlice";
import groupAdminReducer from "./apislice/group/groupAdminSlice";
import reportReducer from "./apislice/postRepostSlice";
import contactUsReducer from "./apislice/contactUsSlice";
import postBlockReducer from "./apislice/postBlockSlice"
import joinedGroupsReducer from "./apislice/group/joinedGroupSlice";
import suggestedGroupsReducer from "./apislice/group/groupSuggestionSlice";
import mainSearchReducer from "./apislice/mainSearchSlice";
import blockUserReducer from "./apislice/blockUserSlice";
import copyRightReaducer from "./apislice/copyRightsSlice";
import groupReportReducer from "./apislice/group/groupReportSlice";
import followersReducer from "./apislice/otheruser/followersSlice";
import followingReducer from "./apislice/otheruser/followingSlice";


// Combine all reducers
const rootReducer = combineReducers({
    copyRigth:copyRightReaducer,
    security: securityReducer,
    auth: authReducer,
    profile: profileReducer,
    post: postReducer,
    home: homeReducer,
    comments: commentReducer,
    likedPost: likedPostReducer,
    totalUsers: totalUserReducer,
    otherUserProfile: otherUserProfileReducer,
    hashtag: hashtagReducer,
    notifications: notificationsReducer,
    repost: repostReducer,
    saved: savedReducer,
    userGroup: userGroupReducer,
    membersList: membersListReducer,
    groupPosts: groupPostsReducer,
    groupAdmin: groupAdminReducer,
    joinedGroups: joinedGroupsReducer,
    suggestedGroups: suggestedGroupsReducer,
    groupReport: groupReportReducer,
    postReport: reportReducer,
    contactUs: contactUsReducer,
    postBlock: postBlockReducer,
    userBlocked  : blockUserReducer,
    mainSearch: mainSearchReducer,
    followersList: followersReducer,
    followingList : followingReducer,
});

// Persist configuration
const persistConfig = {
    key: "root",
    storage,
    whitelist: ["auth", "profile", "userGroup", "membersList"], // Add the reducers you want to persist
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

// Create a persistor
export const persistor = persistStore(store);