import React, { Fragment, useCallback, useEffect, useState, useRef } from "react";
import SideMenu from "../components/SideMenu";
import MainPost from "../components/otherUserAccount/MainPost";
import Media from "../components/otherUserAccount/Media";
import About from "../components/otherUserAccount/About";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchOtherUserProfile } from "../redux/apislice/otherUserSlice";
import Skeleton from "react-loading-skeleton";
import { startFollow } from "../redux/apislice/profileSlice";
import toast, { Toaster } from "react-hot-toast";
import { BsThreeDotsVertical } from "react-icons/bs";
import { SiAdblock } from "react-icons/si";
import { FaShareAlt } from "react-icons/fa";
import { blockUser } from "../redux/apislice/blockUserSlice";
import ShareModal from "../components/common/ShareModal";
import { REACT_SHARE_MAIN_URL } from "../utils/mainUrl";
import { IoCaretBackCircle } from "react-icons/io5";

const OtherAccount = () => {
    const initialLabel = "C / FE / Other user account / V1 / July 29, 2024";

    const navigate = useNavigate()
    const params = useParams()
    const { user_id } = params
    const dispatch = useDispatch()
    const [follow, setFollow] = useState(false);
    const [selectedTab, setSelectedTab] = useState("post");
    const [label, setLabel] = useState(initialLabel);
    const userDetails = useSelector(state => state.otherUserProfile)
    const { loading } = userDetails || {}
    const [profileDetail, setProfileDetail] = useState({})
    const [menuShow, setMenuShow] = useState(null);
    const menuRef = useRef(null);
    const [shareModal, setShareModal] = useState(false);
    const [postId, setPostId] = useState(null)
    console.log(profileDetail, "profileDetail")
    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        switch (tab) {
            case "post":
                setLabel("C / FE / Other user account post / V1 / July 29, 2024");
                break;
            case "media":
                setLabel("C / FE / Other user account / media / V1 / July 30, 2024");
                break;
            case "about":
                setLabel("C / FE / Other user account / about / V1 / aug 02, 2024");
                break;
            default:
                setLabel(initialLabel);
        }
    };

    const handleFollow = (userId) => {
        setFollow(!follow)
        dispatch(startFollow(userId))?.then((result) => {
            if (result.error) {
                toast.success(result.error)
            } else {
                console.log(result.payload, "result.payload")
                follow ? toast.success(`You Unfollow ${profileDetail.first_name} ${profileDetail.last_name} `) : toast.success(`You Follow ${profileDetail.first_name} ${profileDetail.last_name} `)
                follow ? setProfileDetail({ ...profileDetail, followers_count: profileDetail.followers_count - 1 }) : setProfileDetail({ ...profileDetail, followers_count: profileDetail.followers_count + 1 })
            }
        });
    }

    const fetchData = useCallback(async () => {
        await dispatch(fetchOtherUserProfile(user_id)).then((result) => {
            if (result.error) {
                toast.error(result.error)
            } else {
                setProfileDetail(result?.payload)
                setFollow(result?.payload?.user_follow)
            }
        })
    }, [dispatch, user_id])

    const handleBlock = async () => {
        try {
            const response = await dispatch(blockUser({ user_id }))
            if (response.payload.detail) {
                toast.success(response.payload.detail)
                setTimeout(() => {
                    navigate("/home")
                }, 2000)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const handleOutsideClick = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuShow(false);
        }
    };

    useEffect(() => {
        if (menuShow) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [menuShow]);



    useEffect(() => {
        fetchData();
    }, [fetchData])

    return (
        <Fragment>
            <Toaster position="top-right" />
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p> */}
                <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
            </div>

            <div className="sticky top-0 w-full z-50 bg-white">
                <div className="flex justify-between items-center px-2 pt-2 relative">
                    <div className="flex items-center justify-between gap-x-2">
                        <SideMenu />
                        <IoCaretBackCircle 
                            onClick={() => navigate(-1)}
                            className="text-black cursor-pointer text-[22px]"
                        />
                    </div>
                    <div className="relative flex justify-center gap-x-4 items-center">
                        <button
                            onClick={() => handleFollow(user_id)}
                            className="bg-black text-white px-3 py-[2px] text-[12px] rounded-full"
                        >
                            {follow ? "Unfollow" : "Follow"}
                        </button>
                        <BsThreeDotsVertical className="cursor-pointer" onClick={() => setMenuShow(true)} />
                        {menuShow && (
                            <div ref={menuRef} className="absolute top-7 right-3 z-50">
                                <div className="bg-[#d9d9d9] flex flex-col rounded-[5px] border border-[#000000]">
                                    <div className="flex gap-2 justify-start items-center p-[5px]" onClick={handleBlock}>
                                        <SiAdblock className="text-[14px]" />
                                        <p className="text-[10px] text-black font-bold cursor-pointer" >Block</p>
                                    </div>
                                    <div className="border-t border-black" />
                                    <div className="flex gap-2 justify-start items-center p-[5px] whitespace-nowrap" onClick={handleBlock}>
                                        <FaShareAlt className="text-[14px]" />
                                        <p className="text-[10px] text-black font-bold cursor-pointer" >Share account</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex flex-col justify-center items-center">
                    <p className="text-[18px] md:text-[15px] font-bold mb-2 flex gap-[2px]"> {profileDetail?.first_name || (loading && <Skeleton width={50} />)} {profileDetail?.last_name || (loading && <Skeleton width={50} />)}</p>
                    {loading ?
                        <Skeleton circle width={80} height={80} /> :
                        <img
                            alt="img"
                            className="object-cover h-20 w-20 mb-1 rounded-full"
                            src={profileDetail?.avatar_url}
                        />
                    }
                    <p className="text-[14px] md:text-[13px] font-semibold lowercase"> {profileDetail?.username || (loading && <Skeleton width={50} />)}</p>
                </div>

                <div className="grid grid-cols-12 items-center px-3 py-2">
                    <div className="col-span-4 text-center">
                        <p className="text-[18px] md:text-[15px] font-bold">{profileDetail?.posts_count}</p>
                        <p className="text-[15px] md:text-[13px] mt-[-4px] cursor-pointer">Posts</p>
                    </div>
                    <div className="col-span-4 text-center" onClick={() => navigate(`/otheruser/account/followers/${profileDetail.id}`)}>
                        <p className="text-[18px] md:text-[15px] font-bold">{profileDetail?.followers_count}</p>
                        <p className="text-[15px] md:text-[13px] mt-[-4px] cursor-pointer" >Followers</p>
                    </div>
                    <div className="col-span-4 text-center">
                        <p className="text-[18px] md:text-[15px] font-bold">{profileDetail?.following_count}</p>
                        <p className="text-[15px] md:text-[13px] mt-[-4px] cursor-pointer" onClick={() => navigate(`/otheruser/account/following/${profileDetail.id}`)}>Following</p>
                    </div>
                </div>

                <div className="flex items-center justify-between bg-[#d9d9d9] text-[14px] md:text-[12px] font-bold px-9 py-2">
                    <p className={selectedTab === "post" ? "cursor-pointer text-purple-600" : "cursor-pointer"} onClick={() => handleTabClick("post")}>Posts</p>
                    <p className={selectedTab === "media" ? "cursor-pointer text-purple-600" : "cursor-pointer"} onClick={() => handleTabClick("media")}>Media</p>
                    <p className={selectedTab === "about" ? "cursor-pointer text-purple-600" : "cursor-pointer"} onClick={() => handleTabClick("about")}>About</p>
                </div>
            </div>

            <div className="pt-1 pb-28">
                {selectedTab === "post" && <MainPost user_id={user_id} setShareModal={setShareModal} setPostId={setPostId} />}
                {selectedTab === "media" && <Media user_id={user_id} setShareModal={setShareModal} setPostId={setPostId} />}
                {selectedTab === "about" && <About user_details={profileDetail} />}
            </div>

            {shareModal && <ShareModal
                url={`${REACT_SHARE_MAIN_URL}/postview/${postId}`}
                onClose={() => setShareModal(false)}
            />}
        </Fragment>
    );
};

export default OtherAccount;
