import React, { Fragment } from 'react'
import Header from './Header';
import { multiPackDiscountProducts } from '../../data';
import { FaArrowDownLong } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';

export default function ViewMore() {
    const lable = "C / FE / DISCOUNTED VIEW MORE PAGE / V2 / SEP 19, 2024";

    const params = useParams();
    const { id } = params;
    const navigate = useNavigate();

    const handleTitle = (id) => {
        switch (id) {
            case "1":
                return "Biggest Discount";
            case "2":
                return "Multi-Pack Discount";
            case "3":
                return "Limited Time Discount";
            case "4":
                return "Best Selling In Cloth";
            case "5":
                return "Best Selling In Tech";
            default:
                return "Discounted";
        }
    }
    const title = handleTitle(id);

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p> */}
                <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
            </div>
            <div className="mb-24">
                <Header />
                <div className="px-2 mt-1 mb-5">
                    <div className="flex justify-between items-center mb-1">
                        <p className="text-[16px] font-bold cursor-pointer text-gray-900 hover:text-black">
                            {title}
                        </p>
                    </div>
                    <div className="grid grid-cols-12 gap-1">
                        {multiPackDiscountProducts.map((data) => (
                            <div className="col-span-4 select-none" key={data.id}>
                                <img
                                    src={data.imgUrl}
                                    alt={data.name}
                                    onClick={() => navigate("/marketplace/productdetails")}
                                    className="img-shadow w-full border-gray-400 border h-[6rem] rounded-xl object-cover cursor-pointer"
                                />
                                <div className="text-black mt-1 px-1 text-start">
                                    <p className="line-clamp-1 text-[12px] font-bold text-green-800 flex items-center gap-[2px]">
                                        <FaArrowDownLong className="text-[10px]" /> {data.discount}%
                                    </p>
                                    <p className="line-clamp-2 text-[9px] leading-3">{data.description}</p>
                                    <div className="flex justify-between items-center pt-[2px]">
                                        <p className="text-[10px] text-gray-600 line-through">${data.price}</p>
                                        <p className="text-[10px] font-bold">
                                            ${Math.floor(
                                                parseFloat(data.price) -
                                                (parseFloat(data.price) * parseFloat(data.discount) / 100)
                                            )}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
