export const TimeFormat = (inputDate) => {
    const currentDate = new Date();
    const pastDate = new Date(inputDate);
    const diffInSeconds = Math.floor((currentDate - pastDate) / 1000);
    const diffInDays = Math.floor(diffInSeconds / 86400);

    if (diffInDays > 30) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return pastDate.toLocaleDateString('en-US', options);
    }

    const timeIntervals = {
        year: 31536000,
        month: 2592000,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
    };

    for (const [unit, secondsInUnit] of Object.entries(timeIntervals)) {
        const interval = Math.floor(diffInSeconds / secondsInUnit);

        if (interval >= 1) {
            return `${interval} ${unit}${interval > 1 ? 's' : ''} ago`;
        }
    }
    return "just now";
}
