import React from "react";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaSnapchatGhost, FaLinkedinIn, FaFacebook, FaYoutube, FaApple } from "react-icons/fa";
import { C_LOGO } from "../../utils/mainUrl";

const Signup = () => {
   const navigate = useNavigate();

   const signupData = [
      { id: 1, icon: <MdEmail />, title: "Email", path: "/signupbyemail", textColour: "#929292", bgColour: "#ffffff" },
      { id: 2, text: "CC", title: "Chaa Chingg", textColour: "#00A3FF", bgColour: "#ffffff" },
      { id: 3, text: "KV", title: "Kind Viewer", textColour: "#00A3FF", bgColour: "#ffffff" },
      { id: 4, icon: <FaFacebook />, title: "Facebook", textColour: "#ffffff", bgColour: "#1877F2" },
      { id: 5, icon: <FaSnapchatGhost />, title: "Snapchat", textColour: "#ffffff", bgColour: "#FFFC00" },
      { id: 6, icon: <FaApple />, title: "Apple", textColour: "#ffffff", bgColour: "#000000" },
      { id: 7, icon: <FaYoutube />, title: "Youtube", textColour: "#ffffff", bgColour: "#FF0000" },
      { id: 8, icon: <FaLinkedinIn />, title: "Linked In", textColour: "#ffffff", bgColour: "#2867B2" },
   ];

   return (
      <div className="flex flex-col justify-center items-center px-4">
         {/* <p className="text-[#7000FF] font-bold text-[3.3rem] md:text-[40px]">Creative</p> */}
        <img src={C_LOGO} alt="logo" className="h-32 w-32" />

         <p className="text-black font-bold text-[20px] md:text-[21px] mb-5 text-center underline underline-offset-[5px]">Chirrpp Sign-up</p>
         <div className="w-full rounded-[20px] pt-10 pb-4 md:py-4 px-8 md:px-4 bg-white">
            {signupData.map((data) => (
               <div
                  key={data.id}
                  onClick={() => data.path && navigate(data.path)}
                  className="grid grid-cols-12 bg-[#d9d9d9] w-full py-[8px] md:py-[5px] mb-2 cursor-pointer rounded-md"
               >
                  <div className="col-span-4">
                     <div className="flex justify-start items-center">
                        <div
                           style={{ backgroundColor: data.bgColour, color: data.textColour }}
                           className={`h-8 w-8 md:h-7 md:w-7 rounded-full text-[14px] font-bold ml-4 md:ml-2 flex justify-center items-center`}
                        >
                           {data.icon ? data.icon : data.text}
                        </div>
                     </div>
                  </div>
                  <div className="col-span-5 flex justify-center items-center">
                     <p className="text-[16px] md:text-[14px] font-semibold">{data.title}</p>
                  </div>
               </div>
            ))}
            <p className="text-[#686868] text-[14px] mt-4 text-center">
               Already have an account?
               <span
                  onClick={() => navigate("/login")}
                  className="text-[#7000FF] cursor-pointer ml-1"
               >
                  Login
               </span>
            </p>
         </div>
      </div>
   )
}

export default Signup;