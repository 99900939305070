import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL

export const searchQuery = createAsyncThunk(
    "searchQuery",
    async ({ data }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/users/search/`, data, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getSearchHistory = createAsyncThunk(
    "getSearchHistory",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/users/search/history/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const deleteSearchHistory = createAsyncThunk(
    "deleteSearchHistory",
    async ({ id }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.delete(`${mainUrl}/api/users/search/history/${id}/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const clearSearchHistory = createAsyncThunk(
    "clearSearchHistory",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.delete(`${mainUrl}/api/users/search/history/clear/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);




const mainSearchSlice = createSlice({
    name: "mainSearchSlice",
    initialState: {
        loading: false,
        data: [],
        history: [],
        error: null,
    },
    reducers: {
        clearRecentSearch: (state) => {
            state.data = [];
        },
    },
    extraReducers: (builder) => {
        // Fetch OtherUserProfile
        builder.addCase(searchQuery.pending, (state) => {
            state.loading = true;
            state.data = [];
            state.error = null;
        });
        builder.addCase(searchQuery.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(searchQuery.rejected, (state) => {
            state.loading = false;
            state.data = null;
            state.error = true;
        });
        // Fetch 
        builder.addCase(getSearchHistory.pending, (state) => {
            // state.loading = true;
            state.error = null;
        });
        builder.addCase(getSearchHistory.fulfilled, (state, action) => {
            // state.loading = false;
            state.history = action.payload;
            state.error = null;
        });
        builder.addCase(getSearchHistory.rejected, (state) => {
            // state.loading = false;
            state.history = null;
            state.error = true;
        });
        // delete all search history 
        builder.addCase(clearSearchHistory.pending, (state) => {
            // state.loading = true;
            state.error = null;
        });
        builder.addCase(clearSearchHistory.fulfilled, (state) => {
            // state.loading = false;
            state.history = [];
            state.error = null;
        });
        builder.addCase(clearSearchHistory.rejected, (state) => {
            // state.loading = false;
            state.history = null;
            state.error = true;
        });
        // delete all search history 
        builder.addCase(deleteSearchHistory.pending, (state) => {
            // state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteSearchHistory.fulfilled, (state, action) => {
            // state.loading = false;
            // state.history = [];
            state.history = state.history?.filter((data) => data.id !== action.meta.arg.id);
            state.error = null;
        });
        builder.addCase(deleteSearchHistory.rejected, (state) => {
            // state.loading = false;
            state.history = null;
            state.error = true;
        });
    }
});

export const { clearRecentSearch } = mainSearchSlice.actions;
export default mainSearchSlice.reducer;