import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL

export const userLogin = createAsyncThunk(
    "login",
    async (loginDetails, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${mainUrl}/api/login/`, loginDetails);
            // localStorage.setItem("refreshToken", response.data.refresh);
            localStorage.setItem("accessToken", response.data.access);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const register = createAsyncThunk(
    "register",
    async (registerDetails, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${mainUrl}/api/signup/`, registerDetails);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const recoverPassword = createAsyncThunk(
    "recoverPassword",
    async (email, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${mainUrl}/api/request_otp/`, { email });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const verifyOtp = createAsyncThunk(
    "verifyOtp",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${mainUrl}/api/verify_otp/`, data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const setNewPassword = createAsyncThunk(
    "setNewPassword",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${mainUrl}/api/editpassword/`, data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const authSlice = createSlice({
    name: "auth",
    initialState: {
        loading: false,
        user: null,
        error: null,
    },
    extraReducers: (builder) => {
        // Login
        builder.addCase(userLogin.pending, (state) => {
            state.loading = true;
            state.user = null;
            state.error = null;
        });
        builder.addCase(userLogin.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.error = null;
        });
        builder.addCase(userLogin.rejected, (state) => {
            state.loading = false;
            state.user = null;
            state.error = true;
        });

        // Register
        builder.addCase(register.pending, (state) => {
            state.loading = true;
            state.user = null;
            state.error = null;
        });
        builder.addCase(register.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.error = null;
        });
        builder.addCase(register.rejected, (state) => {
            state.loading = false;
            state.user = null;
            state.error = true;
        });
        /* RECOVEY PASSWORD */
        builder.addCase(recoverPassword.pending, (state) => {
            state.loading = true;
            state.user = null;
            state.error = null;
        });
        builder.addCase(recoverPassword.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.error = null;
        });
        builder.addCase(recoverPassword.rejected, (state) => {
            state.loading = false;
            state.user = null;
            state.error = true;
        });
        /* OTP VERIFY */

        builder.addCase(verifyOtp.pending, (state) => {
            state.loading = true;
            state.user = null;
            state.error = null;
        });
        builder.addCase(verifyOtp.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.error = null;
        });
        builder.addCase(verifyOtp.rejected, (state) => {
            state.loading = false;
            state.user = null;
            state.error = true;
        });
        /*  setNewpassword */
        builder.addCase(setNewPassword.pending, (state) => {
            state.loading = true;
            state.user = null;
            state.error = null;
        });
        builder.addCase(setNewPassword.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.error = null;
        });
        builder.addCase(setNewPassword.rejected, (state) => {
            state.loading = false;
            state.user = null;
            state.error = true;
        });
    }
});

export default authSlice.reducer;