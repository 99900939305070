import React from "react";
import NewPass from "../components/auth/NewPass";

const NewPassPage = () => {
   const lable = "C / FE / NEW PASSWORD PAGE / V1 / MAR 23, 2024";
   const copyright = "Copyright © 2024  Galileo One Inc";

   return (
      <div className="bg-[#ffffff] h-screen md:h-[87vh]">
         <div className="text-center">
            {/* <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p> */}
            <p className="text-black text-[9px]">{copyright}</p>
         </div>
         <NewPass />
      </div>
   )
}

export default NewPassPage