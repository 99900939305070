import React from "react";
import { IoClose } from "react-icons/io5";
import { groupUserData } from "../../data";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import toast, { Toaster } from 'react-hot-toast';
import { MdOutlineContentCopy } from "react-icons/md";
import { FacebookIcon, PinterestIcon, RedditIcon, TelegramIcon, WhatsappIcon, XIcon } from "react-share";
import { FacebookShareButton, PinterestShareButton, RedditShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5.5,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        slidesToSlide: 1
    }
};

const ShareModal = ({ url, onClose }) => {

    const handleCopy = (e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(url).then(() => {
            toast.success("Copied", {
                duration: 1000,
            });
            setTimeout(onClose, 1000);
        });
    };

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-50" onClick={onClose}>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <div className="bg-white px-2 py-4 rounded-lg shadow-lg w-11/12 max-w-lg" onClick={handleModalClick}>
                <div className="flex justify-between items-center">
                    <p className="text-xl font-bold">Sharing Link</p>
                    <button className="bg-[#000000] hover:bg-[#222222] rounded p-1">
                        <IoClose className="cursor-pointer text-white text-[20px]" onClick={onClose} />
                    </button>
                </div>
                <div className="grid grid-cols-12 items-center mt-5 px-2 py-2 bg-[#d4d4d4] rounded-md">
                    <div className="col-span-11 overflow-x-scroll">
                        <p className="text-black text-[15px] truncate">{url}</p>
                    </div>
                    <div className="col-span-1 flex justify-center">
                        <MdOutlineContentCopy className="cursor-pointer" onClick={handleCopy} />
                    </div>
                </div>

                <div className="w-full h-[0.4px] bg-[#b2b2b2] my-2" />

                <p className="text-[14px] text-[#525252]">Share with friends</p>
                <div className="ml-[-5px]">
                    <Carousel
                        arrows={true}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={responsive}
                        ssr={true}
                        infinite={true}
                        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                        className="pt-1"
                    >
                        {groupUserData.map((data) => (
                            <div className="flex flex-col items-center select-none" key={data.id}>
                                <img
                                    alt={data.userTagname}
                                    src={data.userProfileImage}
                                    className="w-10 h-10 rounded-full object-cover cursor-pointer"
                                />
                            </div>
                        ))}
                    </Carousel>
                </div>

                <div className="w-full h-[0.4px] bg-[#b2b2b2] my-2" />
                <p className="text-[14px] text-[#525252]">Share with social media</p>

                <Carousel
                    arrows={true}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true}
                    infinite={true}
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    className="pt-1"
                >
                    <FacebookShareButton url={url}>
                        <FacebookIcon size={39} round={true} />
                    </FacebookShareButton>
                    <WhatsappShareButton url={url}>
                        <WhatsappIcon size={39} round={true} />
                    </WhatsappShareButton>
                    <TwitterShareButton url={url}>
                        <XIcon size={39} round={true} />
                    </TwitterShareButton>
                    <TelegramShareButton url={url}>
                        <TelegramIcon size={39} round={true} />
                    </TelegramShareButton>
                    <RedditShareButton url={url}>
                        <RedditIcon size={39} round={true} />
                    </RedditShareButton>
                    <PinterestShareButton media={url}>
                        <PinterestIcon size={39} round={true} />
                    </PinterestShareButton>
                </Carousel>

            </div>
        </div>
    );
};

export default ShareModal;
