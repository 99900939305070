import React, { useState } from "react";
import { post } from "../../data";
import { LuDot } from "react-icons/lu";
import Carousel from "react-multi-carousel";
import { MdRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function PopularSearch() {
   const [seeMore, setSeeMore] = useState(false);
   const navigate = useNavigate()
   const responsive = {
      desktop: {
         breakpoint: { max: 3000, min: 1024 },
         items: 1,
         slidesToSlide: 1
      },
      tablet: {
         breakpoint: { max: 1024, min: 464 },
         items: 1,
         slidesToSlide: 1
      },
      mobile: {
         breakpoint: { max: 464, min: 0 },
         items: 1,
         slidesToSlide: 1
      }
   };

   const sortedPosts = post.sort((a, b) => {
      if (a.firstName < b.firstName) return -1;
      if (a.firstName > b.firstName) return 1;
      return 0;
   });


   const uniquePosts = [
      {
         id: 1,
         img: "https://plus.unsplash.com/premium_photo-1714573189973-018546b10078?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDE4fE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D"
      },
      {
         id: 2,
         img: "https://images.unsplash.com/photo-1722562984868-f2b52f26ac72?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDQ0fE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D"
      },
      {
         id: 3,
         img: "https://images.unsplash.com/photo-1722660636530-bb860009ae5b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDU0fE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D"
      },
   ];

   const trending_hashtags = [
      {
         hashtag: "#ClimateActionNow",
         tweet_count: 152300,
         trending_rank: 1,
         last_updated: "2024-08-11T10:00:00Z"
      },
      {
         hashtag: "#QuantumLeap",
         tweet_count: 98400,
         trending_rank: 2,
         last_updated: "2024-08-11T10:00:00Z"
      },
      {
         hashtag: "#WildfireCrisis",
         tweet_count: 76500,
         trending_rank: 3,
         last_updated: "2024-08-11T10:00:00Z"
      },
      {
         hashtag: "#TechSummit2024",
         tweet_count: 53000,
         trending_rank: 4,
         last_updated: "2024-08-11T10:00:00Z"
      },
      {
         hashtag: "#GlobalEconomy",
         tweet_count: 47200,
         trending_rank: 5,
         last_updated: "2024-08-11T10:00:00Z"
      }
      ,
      {
         hashtag: "#GlobalEconomy",
         tweet_count: 47200,
         trending_rank: 6,
         last_updated: "2024-08-11T10:00:00Z"
      }
      ,
      {
         hashtag: "#GlobalEconomy",
         tweet_count: 47200,
         trending_rank: 7,
         last_updated: "2024-08-11T10:00:00Z"
      }
      ,
      {
         hashtag: "#GlobalEconomy",
         tweet_count: 47200,
         trending_rank: 8,
         last_updated: "2024-08-11T10:00:00Z"
      }
      ,
      {
         hashtag: "#GlobalEconomy",
         tweet_count: 47200,
         trending_rank: 9,
         last_updated: "2024-08-11T10:00:00Z"
      }
   ]

   const displayedData = seeMore ? trending_hashtags : trending_hashtags.slice(0, 5);

   return (
      <div className="grid grid-cols-12 overflow-y-scroll">
         <div className="col-span-12">
            <Carousel
               autoPlay={true}
               arrows={true}
               swipeable={true}
               draggable={true}
               showDots={false}
               responsive={responsive}
               infinite={true}
               ssr={true}
               slidesToSlide={1}
               removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
               {uniquePosts.map((data) => (
                  <div className="rounded-md px-[8px] w-auto py-[3px] cursor-pointer my-1 text-center z-0" key={data.id}>
                     <img src={data.img} alt="img" className="w-full h-28 object-cover rounded-md" />
                  </div>
               ))}
            </Carousel>
         </div>

         <hr className="col-span-12 w-full mt-[2px] mb-[2px] h-[0.5px] bg-[#c9c9c9] border-0" />

         <div className="col-span-12 flex flex-wrap gap-4 mb-24">
            <div className="">
               <div className="px-2 py-1">
                  <h1 className="font-extrabold text-[20px]">Trending</h1>
               </div>
               {displayedData.map((data) => (
                  < div key={data.id} className="flex gap-2 mb-2 px-2 py-1">
                     <div className="flex flex-col">
                        <div className="flex items-center">
                           <p className="text-xs pr-1 cursor-pointer">{data.trending_rank}{")"}</p>
                           <p className="text-[12px] font-bold cursor-pointer" onClick={() => navigate(`/hashtag/${data.hashtag.replace("#", "")}`)}>{data.hashtag}</p>
                           <LuDot className="h-3 w-3" />
                           <p className="text-xs">1 day ago</p>
                        </div>
                        <p className="text-[10px] text-gray-600 leading-3 line-clamp-2 mr-4 flex gap-1">
                           <MdRemoveRedEye /> {data.tweet_count}
                        </p>
                     </div>
                  </div >
               ))}
               {seeMore ? <p className="text-red-500 px-2 text-[12px] font-bold cursor-pointer" onClick={() => setSeeMore(false)}>
                  close
               </p> : <p className="text-blue-500 px-2 text-[12px] font-bold cursor-pointer" onClick={() => setSeeMore(true)}>
                  See more
               </p>}
            </div>
         </div>
      </div >
   )
}
