import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL

export const fetchUserNotifications = createAsyncThunk(
    "fetchUserNotifications",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/notify/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const readNotifications = createAsyncThunk(
    "readNotifications",
    async (notify_Id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.patch(`${mainUrl}/notify/mark-as-read/${notify_Id}/`, {}, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const notificationsSlice = createSlice({
    name: "otherUserProfile",
    initialState: {
        loading: false,
        data: [],
        error: null,
    },
    extraReducers: (builder) => {
        // Fetch OtherUserProfile
        builder.addCase(fetchUserNotifications.pending, (state) => {
            state.loading = true;
            state.data = null;
            state.error = null;
        });
        builder.addCase(fetchUserNotifications.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(fetchUserNotifications.rejected, (state) => {
            state.loading = false;
            state.data = null;
            state.error = true;
        });
    }
});

export default notificationsSlice.reducer;