import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;


export const getJoinedGroupList = createAsyncThunk(
    "getJoinedGroupList",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/group/joined/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)





const joinedGroupSlice = createSlice({
    name: "joinedGroupSlice",
    initialState: {
        loading: false,
        groupList: {},
        error: null,
    },
    extraReducers: (builder) => {
        // Get Admin List 
        builder.addCase(getJoinedGroupList.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getJoinedGroupList.fulfilled, (state, action) => {
            state.loading = false;
            state.groupList = action.payload
            state.error = null;
        });
        builder.addCase(getJoinedGroupList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
})
export default joinedGroupSlice.reducer;
