import React, { useEffect } from "react"

const ErrorPage = ({setLable}) => {

   useEffect(() => {
      // setLable("C / FE / ERROR Page / V1 / MAR 19, 2024");
      setLable("")
   });

   return (
      <div className="flex flex-col justify-center items-center h-[59.5vh]">
         <p className="uppercase font-semibold text-8xl text-gray-800">404</p>
         <p className="text-xl text-gray-800">Page Not Found</p>
      </div>
   )
}

export default ErrorPage