import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { neighborhoodNewsData } from "../../data";
import { IoLocationOutline } from "react-icons/io5";
import { BsBookmarkCheckFill } from "react-icons/bs";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { CiBookmarkPlus, CiSearch } from "react-icons/ci";
import { FaRegComment, FaRegHeart, FaHeart, FaRegShareSquare } from "react-icons/fa";

const News = () => {
    const [activeMenuId, setActiveMenuId] = useState(null);
    const [likes, setLikes] = useState({});
    const [bookmarks, setBookmarks] = useState({});
    const [sharePopupId, setSharePopupId] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const menuRef = useRef(null);
    const navigate = useNavigate();

    const handleMenuToggle = (id, event) => {
        event.stopPropagation();
        setActiveMenuId(activeMenuId === id ? null : id);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setActiveMenuId(null);
            setSharePopupId(null);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleLikeToggle = (id) => {
        setLikes((prevLikes) => ({
            ...prevLikes,
            [id]: !prevLikes[id],
        }));
    };

    const handleBookmarkToggle = (id) => {
        setBookmarks((prevBookmarks) => ({
            ...prevBookmarks,
            [id]: !prevBookmarks[id],
        }));
    };

    const handleShareClick = (id) => {
        setSharePopupId(sharePopupId === id ? null : id);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredNews = neighborhoodNewsData.filter((news) =>
        news.firstName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const menuItems = ["Mute", "Report", "Block"];

    return (
        <div className="mb-32">
            {neighborhoodNewsData.map((news) => (
                <div key={news.id} className="px-2 pb-2 mb-2 border-b border-gray-300">
                    <div className="flex items-start w-full" onClick={() => navigate(`/neighborhood/news/${news.id}`)}>
                        <div className="w-14">
                            <img
                                alt={news.userName}
                                src={news.profileImage}
                                className="w-10 h-10 object-cover rounded-full"
                            />
                        </div>
                        <div className="flex items-start justify-between w-full ml-1">
                            <div className="flex flex-col">
                                <p className="text-[12px] font-bold">
                                    {news.userName}
                                </p>
                                <p className="text-[10px] text-gray-500">
                                    {news.time}
                                </p>
                                <p className="flex items-center text-[10px] text-gray-500">
                                    <IoLocationOutline className="text-[14px] ml-[-3px]" />
                                    {news.location}
                                </p>
                            </div>
                            <div className="relative">
                                <HiOutlineDotsVertical
                                    ref={menuRef}
                                    className="text-[18px] cursor-pointer text-gray-600 mr-[-5px]"
                                    onClick={(event) => handleMenuToggle(news.id, event)}
                                />
                                {activeMenuId === news.id && (
                                    <div className="absolute right-0 mt-2 w-16 bg-white border border-gray-400 overflow-hidden rounded shadow-lg">
                                        {menuItems.map((item, index) => (
                                            <div
                                                key={index}
                                                className="px-2 py-1 text-[12px] text-gray-700 hover:bg-gray-100 hover:text-black cursor-pointer"
                                            >
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="mt-2">
                        <p className="text-[12px] font-bold" onClick={() => navigate(`/neighborhood/news/${news.id}`)}>
                            {news.title}
                        </p>
                        <img
                            alt={news.title}
                            src={news.postImage}
                            onClick={() => navigate(`/neighborhood/news/${news.id}`)}
                            className="w-full h-[160px] object-cover rounded-lg mt-1 cursor-pointer"
                        />
                        <p className="text-[10px] leading-[15px] mt-2" onClick={() => navigate(`/neighborhood/news/${news.id}`)}>
                            {news.description}
                        </p>
                        <div className="flex items-center justify-between mt-1">
                            <div className="flex items-center gap-x-4 relative">
                                <div className="flex flex-col items-center relative">
                                    {likes[news.id] ? (
                                        <FaHeart className="text-[16px] text-red-600 cursor-pointer" onClick={() => handleLikeToggle(news.id)} />
                                    ) : (
                                        <FaRegHeart className="text-[16px] text-gray-600 cursor-pointer" onClick={() => handleLikeToggle(news.id)} />
                                    )}
                                    <p className="text-[8px] text-gray-500">{news.likes + (likes[news.id] ? 1 : 0)}</p>
                                </div>
                                <div className="flex flex-col items-center">
                                    <FaRegComment
                                        onClick={() => navigate(`/neighborhood/news/${news.id}`)}
                                        className="text-[16px] text-gray-600 cursor-pointer"
                                    />
                                    <p className="text-[8px] text-gray-500">{news.comments}</p>
                                </div>
                                <div className="flex flex-col items-center">
                                    <FaRegShareSquare className="text-[16px] text-gray-600 cursor-pointer" onClick={() => handleShareClick(news.id)} />
                                    <p className="text-[8px] text-gray-500">{news.shares}</p>
                                </div>
                                {sharePopupId === news.id && (
                                    <div className="absolute left-24 -top-10 p-1 w-[10rem] bg-white border border-gray-400 overflow-hidden rounded shadow-lg">
                                        <div className="relative">
                                            <input
                                                type="text"
                                                placeholder="search"
                                                value={searchTerm}
                                                onChange={handleSearchChange}
                                                className="w-full h-6 pl-2 pr-6 py-[2px] border border-gray-300 placeholder:text-[12px] text-[10px] rounded-lg focus:outline-none"
                                            />
                                            <CiSearch className="absolute right-1 top-1/2 transform -translate-y-1/2 transition-transform text-gray-500 hover:text-gray-700 text-[18px] cursor-pointer" />
                                        </div>
                                        <div className="flex items-center justify-between p-1">
                                            <div className="flex items-center gap-x-3 overflow-x-scroll">
                                                {filteredNews.map((filteredNewsItem) => (
                                                    <div className="flex flex-col items-center cursor-pointer" key={filteredNewsItem.id}>
                                                        <div className="h-6 w-6">
                                                            <img
                                                                alt={filteredNewsItem.firstName}
                                                                src={filteredNewsItem.profileImage}
                                                                className="w-6 h-6 object-cover rounded-full"
                                                            />
                                                        </div>
                                                        <p className="text-[8px]">{filteredNewsItem.firstName}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {bookmarks[news.id] ? (
                                <BsBookmarkCheckFill className="text-[18px] text-gray-600 mr-[-2px] cursor-pointer" onClick={() => handleBookmarkToggle(news.id)} />
                            ) : (
                                <CiBookmarkPlus className="text-[22px] text-gray-600 mr-[-5px] cursor-pointer" onClick={() => handleBookmarkToggle(news.id)} />
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default News;