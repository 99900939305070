import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { bestSellInCloth } from "../../data";
import { FaArrowDownLong } from "react-icons/fa6";
import { IoStar, IoStarHalf, IoStarOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const responsive = {
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1
   }
};

const BestSellCloth = () => {
   const navigate = useNavigate();

   return (
      <div className="px-2 mt-1 mb-5">
         <div className="flex justify-between items-center mb-1">
            <p className="text-[16px] font-bold cursor-pointer text-gray-900 hover:text-black">Best Selling In Cloth</p>
            <p className="text-[10px] font-bold cursor-pointer text-[#47d1ff] hover:text-[#36b5f0]" onClick={() => navigate("/marketplace/viewmore/4")}>View More</p>
         </div>
         <div className="-mx-[4px]">
            <Carousel
               arrows={true}
               swipeable={true}
               draggable={true}
               showDots={false}
               responsive={responsive}
               ssr={true}
               infinite={true}
               removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
               className="pt-1"
            >
               {bestSellInCloth.map((data) => (
                  <div className="px-[4px] select-none" key={data.id}>
                     <img
                        src={data.imgUrl}
                        alt={data.name}
                        onClick={() => navigate("/marketplace/productdetails")}
                        className="img-shadow border-gray-400 border w-full h-[6.4rem] rounded-xl object-cover cursor-pointer"
                     />
                     <div className="text-black mt-1 px-1 text-start">
                        <p className="line-clamp-1 text-[15px] font-bold text-green-800 flex items-center gap-[2px]">
                           <FaArrowDownLong className="text-[12px]" /> {data.discount}%
                        </p>
                        <p className="line-clamp-2 text-[10px] leading-3">{data.description}</p>
                        <div className="flex justify-between items-center pt-[2px]">
                           <p className="text-[10px] text-gray-600 line-through">${data.price}</p>
                           <p className="text-[10px] font-bold">
                              ${Math.floor(
                                 parseFloat(data.price) -
                                 (parseFloat(data.price) * parseFloat(data.discount) / 100)
                              )}
                           </p>
                        </div>
                        <div className="flex gap-x-[2px] items-center mt-[-5px]">
                           <p className="text-[11px] pt-[2px]">3.5</p>
                           <div className="flex justify-start items-center text-[11px] pt-[2px]">
                              <IoStar className="text-yellow-800" />
                              <IoStar className="text-yellow-800" />
                              <IoStar className="text-yellow-800" />
                              <IoStarHalf className="text-yellow-800" />
                              <IoStarOutline className="text-yellow-800" />
                           </div>
                        </div>
                     </div>
                  </div>
               ))}
            </Carousel>
         </div>
      </div>
   )
}

export default BestSellCloth