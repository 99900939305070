import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";

const mainUrl = REACT_APP_MAIN_URL;

export const submitContactUs = createAsyncThunk(
    "submitContactUs",
    async ({ data }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/contact_us/contact/`, data, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const contactUsSlice = createSlice({
    name: "contactUs",
    initialState: {
        loading: false,
        data: null,
        error: null,
    },
    extraReducers: (builder) => {

        // Post Like
        builder.addCase(submitContactUs.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(submitContactUs.fulfilled, (state, action) => {
            state.error = null;
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(submitContactUs.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default contactUsSlice.reducer;
