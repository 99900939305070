import React, { useRef, useState, useEffect } from "react";

import { IoMdClose } from "react-icons/io";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { FaHeart, FaRegComment, FaRegHeart } from "react-icons/fa";

const Comment = ({ comments }) => {
    const replyPopupRef = useRef(null);
    const [openPopoverId, setOpenPopoverId] = useState(null);
    const [openReplyPopup, setOpenReplyPopup] = useState(null);
    const menuItems = ["Mute", "Report", "Reply"];
    const [likes, setLikes] = useState({});

    const handleLikeToggle = (id) => {
        setLikes((prevLikes) => ({
            ...prevLikes,
            [id]: !prevLikes[id],
        }));
    };

    const handlePopoverToggle = (id) => {
        setOpenPopoverId((prevId) => (prevId === id ? null : id));
    };

    const handleReplyPopupToggle = (comment) => {
        setOpenReplyPopup((prevComment) => (prevComment && prevComment.id === comment.id ? null : comment));
        setOpenPopoverId(null); // Close popover when reply popup is opened
    };

    const handleClickOutside = (event) => {
        if (replyPopupRef.current && !replyPopupRef.current.contains(event.target)) {
            setOpenReplyPopup(null);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="pt-1">
            {comments.map((comment) => (
                <div key={comment.id} className="py-2 border-b px-2 boreder-gray-300">
                    <div className="flex items-start">
                        <div className="w-7 h-6">
                            <img
                                alt="User"
                                src={comment.userProfileImage}
                                className="w-6 h-6 object-cover rounded-full"
                            />
                        </div>
                        <div className="flex flex-col ml-2 w-full">
                            <div className="flex items-center justify-between gap-x-1">
                                <div className="flex items-center gap-x-1">
                                    <p className="text-[11px] font-bold">
                                        {comment.userName}
                                    </p>
                                    <div className="relative">
                                        <HiOutlineDotsVertical
                                            className="text-[12px] cursor-pointer text-gray-600 mr-[-5px]"
                                            onClick={() => handlePopoverToggle(comment.id)}
                                        />
                                        {openPopoverId === comment.id && (
                                            <div className="absolute -right-12 -top-1 w-10 bg-white border border-gray-400 overflow-hidden rounded shadow-lg">
                                                {menuItems.map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className="px-2 py-[1px] text-[8px] text-gray-700 hover:bg-gray-300 hover:text-black cursor-pointer"
                                                        onClick={() => item === "Reply" && handleReplyPopupToggle(comment)}
                                                    >
                                                        {item}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex items-center gap-x-4">
                                    <div className="flex flex-col items-center">
                                        {likes[comment.id] ? (
                                            <FaHeart className="text-[10px] text-red-600 cursor-pointer" onClick={() => handleLikeToggle(comment.id)} />
                                        ) : (
                                            <FaRegHeart className="text-[10px] text-gray-600 cursor-pointer" onClick={() => handleLikeToggle(comment.id)} />
                                        )}
                                        <p className="text-[8px] text-gray-500">{comment.likes}</p>
                                    </div>
                                    <div className="flex flex-col items-center">
                                        <FaRegComment className="text-[10px] text-gray-600 cursor-pointer" />
                                        <p className="text-[8px] text-gray-500">{comment.replies.length}</p>
                                    </div>
                                </div>
                            </div>
                            <p className="text-[8px] text-gray-500 mt-[-5px]">
                                {comment.timeAgo}
                            </p>
                            <p className="text-[10px] mt-[2px]">
                                {comment.body}
                            </p>
                            <div className="h-[0.5px] bg-gray-300 w-[86%] my-1" />
                            {comment.replies.map((reply) => (
                                <div key={reply.id} className="flex mt-1">
                                    <div className="w-7 h-6">
                                        <img
                                            alt="User"
                                            className="w-6 h-6 object-cover rounded-full"
                                            src={reply.userProfileImage}
                                        />
                                    </div>
                                    <div className="flex flex-col ml-2 w-full">
                                        <div className="flex items-center justify-between gap-x-1">
                                            <div className="flex items-center gap-x-1">
                                                <p className="text-[11px] font-bold">
                                                    {reply.userName}
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-[8px] text-gray-500 mt-[-3px]">
                                            {reply.timeAgo}
                                        </p>
                                        <p className="text-[10px] mt-[2px]">
                                            {reply.body}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
            {openReplyPopup && (
                <div className="absolute inset-0 flex items-center justify-center h-[75vh] bg-black bg-opacity-50 z-20">
                    <div ref={replyPopupRef} className="bg-white p-4 m-2 rounded shadow-lg w-80">
                        <div className="flex justify-between items-center mb-2">
                            <div className="flex items-start">
                                <img
                                    alt="User"
                                    className="w-6 h-6 object-cover rounded-full"
                                    src={openReplyPopup.userProfileImage}
                                />
                                <div className="ml-2">
                                    <p className="text-[11px] font-bold">{openReplyPopup.userName}</p>
                                    <p className="text-[8px] text-gray-500">{openReplyPopup.timeAgo}</p>
                                </div>
                            </div>
                            <IoMdClose
                                onClick={() => setOpenReplyPopup(null)}
                                className="text-[20px] text-gray-900 hover:text-red-700 cursor-pointer"
                            />
                        </div>
                        <p className="text-[10px] mb-2">{openReplyPopup.body}</p>
                        <textarea
                            rows={2}
                            placeholder="Write your reply..."
                            className="w-full p-2 border border-gray-300 rounded resize-none focus:outline-none text-[10px]"
                        />
                        <button className="mt-2 bg-gray-900 hover:bg-black text-[12px] text-white px-4 py-1 rounded transition-colors duration-500">
                            Reply
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Comment;