import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaCrown } from "react-icons/fa";
import { IoCaretBackCircle, IoPersonAddSharp, IoSearch } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { assignAdmin, assignMemberToAdmin, getGroupMembers } from "../../../redux/apislice/group/membersListSlice";
import Skeleton from "react-loading-skeleton";
import { removeMember } from "../../../redux/apislice/group/userGroupSlice";
import { BsThreeDotsVertical } from "react-icons/bs";
import { removeAdmin, removeGroupSelf } from "../../../redux/apislice/group/groupAdminSlice";
import toast, { Toaster } from "react-hot-toast";
import { startFollow } from "../../../redux/apislice/profileSlice";

const AccountOwnerGroupMember = () => {

    const params = useParams();
    const { groupId } = params;

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [activeMenuId, setActiveMenuId] = useState(null)
    const groupList = useSelector((state) => state.membersList)
    const profile = useSelector(state => state.profile);
    const menuRef = useRef(null);

    const ifAdmin = groupList.assignAdmin && groupList?.membersList?.some((data) => data.user.id === profile.user.id && data.role === "admin");

    const toggleMenu = (commentId) => {
        setActiveMenuId((prevId) => (prevId === commentId ? null : commentId));
    };

    const fetchData = useCallback(async () => {
        try {
            await dispatch(getGroupMembers({ groupId })).unwrap();
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    }, [dispatch, groupId]);

    const assignAdminHandler = async (memberId) => {
        try {
            const response = await dispatch(assignMemberToAdmin({ groupId, memberId })).unwrap();
    
            if (response.message) {
                setFilteredData((prev) =>
                    prev.map((data) =>
                        data.user.id === memberId ? { ...data, role: "admin" } : data
                    )
                );
            }
        } catch (error) {
            console.error("Failed to assign admin:", error);
        }
    };

    const handleRemoveMember = async (memberId) => {
        try {
            await dispatch(removeMember({ groupId, memberId })).unwrap();
          
            setFilteredData((prev) => prev.filter((data) => data.id !== memberId));
        
        } catch (error) {
            console.error("Failed to remove member:", error);
        }
    }
    const handleRemoveAdmin = async (memberId) => {
        try {
            await dispatch(removeAdmin({ groupId, memberId })).unwrap();
           
            setFilteredData((prev) => prev.filter((data) => data.user.id !== memberId));
           
        } catch (res) {
            console.error("Failed to remove member:", res.error);
            toast.error(res.error);
        }
    }
    const handleRemoveSelf = async (memberId) => {
        try {
            await dispatch(removeGroupSelf({ groupId })).unwrap();
            
            setFilteredData((prev) => prev.filter((data) => data.user.id !== memberId));
        
        } catch (res) {
            console.error("Failed to remove member:", res.error);
            toast.error(res.error);
        }
    }

    const handleFollow = async (user_id) => {

        try {
            dispatch(startFollow(user_id))?.then((result) => {
                if (result.error) {
                    toast.success(result.error)
                } else {
                    // toast.success(result.payload.message)
                    setFilteredData((prev) => prev.map((data) => data.user.id === user_id ? { ...data, user: { ...data.user, user_follow: !data.user.user_follow } } : data));

                }
            });
        } catch (error) {
            console.error("error", error);
        }
    }


    useEffect(() => {
        fetchData();
        return (() => {
            dispatch(assignAdmin(false))
        })
    }, [fetchData , dispatch]);

    useEffect(() => {
        if (searchValue === '') {
            setFilteredData(groupList?.membersList);
        } else {
            setFilteredData((prev) =>
                prev?.filter((data) =>
                    data?.user?.first_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    data?.user?.last_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    data?.user?.username?.toLowerCase().includes(searchValue.toLowerCase())
                )
            );
        }
    }, [searchValue, groupList]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setActiveMenuId(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    const label = groupList.assignAdmin ? "C / FE / -ACCOUNT OWNER GROUP / ASSIGN ADMIN / V1 / JULY 20, 2024" : "C / FE / GROUP / MEMBERS / V1 / JULY 20, 2024";


    return (
        (<div className="bg-white h-full overflow-y-scroll pb-[6.5rem]">
            <Toaster position="top-right" />
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p> */}
                <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
            </div>
            <div className="sticky top-0 w-full bg-black z-50">
                <div className="px-2 flex justify-between items-center gap-3 pt-1 border-b-[0.4px] border-[#242424] pb-2">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="h-8 w-8 cursor-pointer text-white"
                    />
                    <div className="relative w-full">
                        <input
                            type="text"
                            placeholder="Search members"
                            className="w-full focus:outline-none rounded-full pl-3 pr-9 py-1 text-white bg-[#2e2e2e]"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        {searchValue.length >= 1 && (
                            <IoSearch
                                className="absolute top-1/2 right-1 transform -translate-y-1/2 text-[24px] text-black bg-white rounded-full p-1 cursor-pointer"
                            />
                        )}
                    </div>
                    <IoPersonAddSharp
                        onClick={() => navigate(`/account/owner/group/invitemembers/${groupId}`)}
                        className="h-7 w-7 cursor-pointer text-white"
                    />
                </div>

                <div className="flex items-center justify-around border-b-[0.4px] border-[#242424] py-[6px]">
                    <p
                        onClick={() => navigate(`/account/owner/group/moderators/${groupId}`)}
                        className="text-gray-400 text-[14px] cursor-pointer"
                    >
                        Moderators
                    </p>
                    <p
                        onClick={() => navigate('/group/joined/members')}
                        className="text-white border-b-2 border-blue-500 text-[14px] cursor-pointer"
                    >
                        Members
                    </p>
                </div>
            </div>
            {groupList.loading ?
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => (
                    <div key={data} className="grid grid-cols-12 px-2">
                        <div className="col-span-2">
                            <Skeleton height={30} width={30} circle />
                        </div>
                        <div className="col-span-8">
                            <Skeleton height={20} width={100} />
                            <Skeleton height={30} width={150} />
                        </div>
                        <div className="col-span-2 flex justify-center items-center">
                            <Skeleton height={30} width={50} />
                        </div>

                    </div>
                )) :
                filteredData?.length > 0 ? (
                    filteredData?.map((data) => (
                        <div className="grid grid-cols-12 px-2 py-1 border-b-[0.4px] border-[#b2b2b2]" key={data.id}>
                            <div className="col-span-2">
                                <img
                                    alt={data.user.first_name}
                                    src={data.user.avatar}
                                    className="h-8 w-8 object-cover rounded-full cursor-pointer"
                                />
                            </div>
                            <div className="col-span-10">
                                <p className="text-black text-[12px] leading-[14px] pt-1">{data?.about_me}</p>
                                <div className="flex justify-between items-start relative">
                                    <div className="flex flex-col leading-[16px]">
                                        <div className="flex items-center gap-2">
                                            <p className="text-black text-[14px]">{data.userName}</p>
                                            {data.role === "admin" && <FaCrown className="text-[14px] text-yellow-900" />}
                                        </div>
                                        <p className="text-[#5c5c5c] text-[12px]">{data.user.first_name}{data.user.last_name}</p>
                                    </div>
                                    <div className="flex gap-2">
                                        {/* {
                                            ifAdmin ? (<button onClick={() => assignAdminHandler(data.user.id)} className="px-3 py-[2px] text-[12px] hover:font-bold font-normal rounded-full bg-black text-white">
                                                {data.role === "admin" ? "Admin" : "Assign an admin"}
                                            </button>) :
                                                data.user.id !== profile.user.id && <button onClick={() => data.role === "admin" ? "" : handleRemoveMember(data.id)} className="px-3 py-[2px] text-[12px] hover:font-bold font-normal rounded-full bg-black text-white">
                                                    remove
                                                </button>
                                        } */}
                                        {

                                            ifAdmin ? (<button onClick={() => assignAdminHandler(data.user.id)} className="px-3 py-[2px] text-[12px] hover:font-bold font-normal rounded-full bg-black text-white">
                                                {data.role === "admin" ? "Admin" : "Assign an admin"}
                                            </button>) : data.user.id !== profile.user.id && <button onClick={() => handleFollow(data.user.id)} className="px-3 py-[2px] text-[12px] hover:font-bold font-normal rounded-full bg-black text-white">
                                                {data.user.user_follow ? "Unfollow" : "Follow"}
                                            </button>}
                                        <BsThreeDotsVertical
                                            onClick={() => toggleMenu(data.id)} // 0 to represent post menu
                                            className="text-[#5c5c5c] cursor-pointer"
                                        />
                                        {activeMenuId === data.id && (
                                            <div className="absolute top-0 min-w-16 z-40 right-2 bg-black py-1 rounded-md text-[12px]" ref={menuRef}>
                                                <p className="px-3 cursor-pointer hover:text-red-800 text-white border-b-[0.4px] border-[#b2b2b2]" onClick={() => data.role === "admin" ? "" : assignAdminHandler(data.user.id)}> {data.role === "admin" ? "Drop Admin" : "Add Admin"}</p>
                                                <p className="px-3 cursor-pointer hover:text-red-800 text-white border-b-[0.4px] border-[#b2b2b2]" onClick={() => data.role === "admin" ? profile.user.id === data.user.id ? handleRemoveSelf(data.user.id) : handleRemoveAdmin(data.user.id) : handleRemoveMember(data.id)}>Remove</p>
                                                <p className="px-3 cursor-pointer hover:text-red-800 text-white">Block</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="text-center py-4">
                        <p className="text-gray-700 overflow-x-scroll">No results for "{searchValue}"</p>
                    </div>
                )}
        </div>)
    );
}

export default AccountOwnerGroupMember;
