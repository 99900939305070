import { Avatar } from "@material-tailwind/react";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { suggestedGroups } from "../../data";
import { IoCaretBackCircle, IoSearch, IoPersonAddSharp } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaShareAlt } from "react-icons/fa";
import { MdNotificationAdd, MdReport, MdLogout } from "react-icons/md";
import { FiUpload } from "react-icons/fi";
// import GroupPost from "./GroupPost";
// import GroupAbout from "./GroupAbout";
// import GroupMedia from "./GroupMedia";
import ShareModal from "../common/ShareModal";
import YesNoModal from "../common/YesNoModal";
import AccountOwnerGroupPost from "./accountowner/AccountOwnerGroupPost";
import { useDispatch, useSelector } from "react-redux";
import { getGroupPosts } from "../../redux/apislice/group/groupPostSlice";
import AccountOwnerGroupMedia from "./accountowner/AccountOwnerGroupMedia";
import { getGroupDetails, joinGroupRequest } from "../../redux/apislice/group/userGroupSlice";
import AccountOwnerGroupAbout from "./accountowner/AccountOwnerGroupAbout";
import { postGroupReport } from "../../redux/apislice/group/groupReportSlice";
import { assignOwner, removeGroupSelf } from "../../redux/apislice/group/groupAdminSlice";
import { Toaster } from "react-hot-toast";

const GroupJoinedHome = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const { groupId } = params;
    const [activeTab, setActiveTab] = useState('All');
    const [prevTab, setPrevTab] = useState('All');
    const [direction, setDirection] = useState('left');
    const [menuOpen, setMenuOpen] = useState(false);
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [reportModalOpen, setReportModalOpen] = useState(false);
    const [leaveModalOpen, setLeaveModalOpen] = useState(false);
    const [shareUrl, setShareUrl] = useState(`https://cagzeynida.com/group/joined/home/${groupId}`);
    const [groupDetails, setGroupDetails] = useState({});
    const groupPosts = useSelector(state => state.groupPosts)
    const profile = useSelector(state => state.profile)
    // const adminData = useSelector(state => state.groupAdmin)
    const { loading, posts } = groupPosts;
    const [groupJoined, setGroupJoined] = useState(null)
    console.log(groupJoined, "groupJoined")

    useState(() => {

    }, [groupDetails])


    const mediaData = (Array.isArray(posts) ? posts : [])?.filter(post => post.video || post.image ? post : null);

    const navigate = useNavigate();
    const menuRef = useRef(null);
    const threeDotsRef = useRef(null);

    const handleTabChange = (newTab) => {
        if (newTab !== activeTab) {
            setDirection(newTab > activeTab ? 'right' : 'left');
            setPrevTab(activeTab);
            setActiveTab(newTab);
        }
    };


    const fetchPosts = useCallback(async () => {
        // fetch posts
        await dispatch(getGroupPosts({ groupId })).unwrap();
    }, [dispatch, groupId]);


    const handleLeave = async () => {
        console.log(groupDetails.admin_count > 2, groupDetails.created_by.id === profile.user.id, "test")

        if (groupDetails.admin_count >= 1 && groupDetails.created_by.id === profile.user.id) {
            console.log("admin count is greater than 2")
            await dispatch(removeGroupSelf({ groupId }))
            await dispatch(assignOwner(true))
            setMenuOpen(false);
            setLeaveModalOpen(true);// assign new owner
        } else if (groupDetails.admin_count <= 1 && groupDetails.admin_count <= 1) {
            try {
                await dispatch(removeGroupSelf({ groupId }))
                navigate("/group")
                setGroupJoined(!groupJoined)

            } catch (error) {
                console.log(error)

            }
        } else {
            setMenuOpen(false);
            setLeaveModalOpen(true);
        }
    }


    /* GET GROUP DETAILS API CALL */
    const fetchGroupDetails = useCallback(async () => {
        try {
            const response = await dispatch(getGroupDetails({ groupId })).unwrap();
            setGroupDetails(response);
            setGroupJoined(response?.joined)

        } catch (error) {
            console.error("Failed to fetch group details:", error);
        }
    }, [groupId, dispatch])


    function getRelativeTime(isoDateString) {
        const inputDate = new Date(isoDateString); // Parse the ISO date string
        const now = new Date(); // Current time
        const diffInMs = now - inputDate; // Difference in milliseconds

        // Convert the difference to seconds, minutes, hours, days, and years
        const diffInSeconds = Math.floor(diffInMs / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
        const diffInYears = Math.floor(diffInDays / 365);

        // Determine the relative time string
        if (diffInSeconds < 60) {
            return `${diffInSeconds}s`;
        } else if (diffInMinutes < 60) {
            return `${diffInMinutes}m`;
        } else if (diffInHours < 24) {
            return `${diffInHours}h`;
        } else if (diffInDays < 365) {
            return `${diffInDays}d`;
        } else {
            return `${diffInYears}y`;
        }
    }


    const renderContent = () => {
        switch (activeTab) {
            case 'Recent':
                // return <GroupPost onShare={handleShareClick} />;
                return <AccountOwnerGroupPost onShare={handleShareClick} getRelativeTime={getRelativeTime} groupId={groupId} loading={loading} postData={Array.isArray(posts) ? posts : []} />;
            case 'Media':
                // return <GroupMedia onShare={handleShareClick} />;
                return <AccountOwnerGroupMedia onShare={handleShareClick} getRelativeTime={getRelativeTime} mediaData={mediaData} />;
            case 'About':
                // return <GroupAbout />;
                return <AccountOwnerGroupAbout groupDetails={groupDetails} groupId={groupId} moderatorsPath={`/account/owner/group/moderators/${groupId}`} />
                    ;
            default:
                return <AccountOwnerGroupPost onShare={handleShareClick} getRelativeTime={getRelativeTime} groupId={groupId} loading={loading} postData={Array.isArray(posts) ? posts : []} />;
        }
    };

    const getLabel = () => {
        switch (activeTab) {
            case 'Recent':
                return "C / FE / JOINED GROUP HOME / RECENT / JULY 18, 2024";
            case 'Media':
                return "C / FE / JOINED GROUP HOME / MEDIA / JULY 19, 2024";
            case 'About':
                return "C / FE / JOINED GROUP HOME / ABOUT / JULY 19, 2024";
            default:
                return "C / FE / JOINED GROUP HOME / V1 / JULY 18, 2024";
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickOutside = (event) => {
        if (
            menuRef.current && !menuRef.current.contains(event.target) &&
            threeDotsRef.current && !threeDotsRef.current.contains(event.target)
        ) {
            setMenuOpen(false);
        }

    };

    useEffect(() => {
        fetchPosts();
        fetchGroupDetails()
    }, [fetchPosts, fetchGroupDetails])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Label & CopyRight
    const label = getLabel();

    const handleShare = () => {
        setMenuOpen(false);
        setShareModalOpen(true);
    }

    const handleReport = () => {
        setMenuOpen(false);
        setReportModalOpen(true);
    }

    // const handleLeave = () => {
    //     setMenuOpen(false);
    //     setLeaveModalOpen(true);
    // }

    const handleShareClick = (url) => {
        setShareUrl(url);
        setShareModalOpen(true);
    }


    const reportGroup = async (value) => {
        try {
            const data = {
                reason: value[0].reason
            }
            await dispatch(postGroupReport({ groupId, data }))
            setReportModalOpen(false)


        } catch (error) {
            console.log(error)

        }
    }
    const handleJoinGroup = async (groupId) => {
        try {
            await dispatch(joinGroupRequest({ groupId })).unwrap();
            // fetchJoinedGroups();
            setGroupJoined(!groupJoined)
        } catch (error) {
            console.log("Error", error);
        }
    }


    return (
        <div className="bg-white h-full overflow-y-scroll pb-24">
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p> */}
                <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
            </div>

            <Toaster position="top-right" />
            <div className="relative">
                <div className="relative">
                    <img
                        alt="nature"
                        className="w-full h-[7.5rem] object-cover"
                        src={groupDetails?.image}
                    />
                    <div className="absolute w-full flex justify-between px-2 top-2">
                        <div className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full">
                            <IoCaretBackCircle
                                onClick={() => navigate(-1)}
                                className="text-white cursor-pointer"
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full">
                                <IoSearch
                                    onClick={() => navigate(`/group/post/search/${groupId}`)}
                                    className="text-white cursor-pointer"
                                />
                            </div>
                            <div className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full">
                                <FiUpload
                                    onClick={() => navigate(`/group/upload/${groupId}`)}
                                    className="text-white cursor-pointer"
                                />
                            </div>
                            <div
                                className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full"
                                onClick={toggleMenu}
                                ref={threeDotsRef}
                            >
                                <BsThreeDotsVertical
                                    className="text-white cursor-pointer"
                                />
                            </div>
                        </div>
                    </div>
                    {menuOpen && (
                        <div
                            className="absolute top-12 right-3 bg-white text-black rounded-md border-[0.4px] border-[#adadad]"
                            ref={menuRef}
                        >
                            <div className="flex items-center justify-between gap-3 px-3 py-1 border-b-[0.4px] border-[#b2b2b2] hover:font-bold">
                                <p className="text-[13px] cursor-pointer" onClick={handleShare}>Share</p>
                                <FaShareAlt className="h-4 w-4" />
                            </div>
                            <div className="flex items-center justify-between gap-3 px-3 py-1 border-b-[0.4px] border-[#b2b2b2] hover:font-bold">
                                <p className="text-[13px] cursor-pointer" onClick={handleReport}>Report</p>
                                <MdReport className="h-4 w-4" />
                            </div>
                            <div className="flex items-center justify-between gap-3 px-3 py-1 hover:font-bold">
                                <p className="text-[13px] cursor-pointer" onClick={handleLeave}>Leave</p>
                                <MdLogout className="h-[14px] w-[14px]" />
                            </div>
                        </div>
                    )}
                </div>
                <div className="px-2 pb-1 bg-[#0a0a12] text-white">
                    <div className="grid grid-cols-12 ">
                        <p className=" col-span-8 font-bold text-[23px]">{groupDetails?.name}</p>
                        <div className="col-span-4 flex justify-end mt-1">
                            <button type="button" onClick={() => groupJoined ?  handleLeave() : handleJoinGroup(groupDetails?.id)} className="text-white h-8 w-auto px-2 font-semibold bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br dark:focus:ring-cyan-800 rounded-[2rem] text-sm   text-center">{groupJoined ? "Joined" : "Join"}</button>
                        </div>
                        <div className="col-span-12">
                            <p className="border border-white rounded-full px-3 text-sm inline-block">{groupDetails?.type}</p>
                        </div>
                    </div>


                    <div className="flex justify-between items-start pt-[6px]">
                        {suggestedGroups.filter(group => group.id === 1).map((data) => (
                            <div className="flex items-center -space-x-3">
                                {groupDetails?.latest_members_avatars?.map((member, index) => (
                                    <Avatar
                                        key={member.index}
                                        variant="circular"
                                        alt={member.index}
                                        className="border-2 border-white h-5 w-5 cursor-pointer hover:z-10 focus:z-10"
                                        src={member.avatar}
                                    />
                                ))}
                                <p className="pl-5 text-xs">{groupDetails.members_count}  Members</p>
                            </div>
                        ))}
                        <div className="flex items-center gap-2">
                            <MdNotificationAdd
                                onClick={() => navigate('/group/notification')}
                                className="text-white cursor-pointer border border-white rounded-full p-1 text-[24px]"
                            />
                            <IoPersonAddSharp
                                onClick={() => navigate(`/account/owner/group/invitemembers/${groupId}`)}
                                className="text-white cursor-pointer border border-white rounded-full p-1 text-[24px]"
                            />
                        </div>
                    </div>

                    <p className="text-sm">{groupDetails.purpose}</p>
                </div>

                <div className="sticky top-0 w-full flex items-center justify-between px-4 py-2 bg-black z-10 text-white text-[14px] border-b-[0.4px] border-[#424242]">
                    <p
                        className={`cursor-pointer ${activeTab === 'All' ? 'text-white border-b-2 border-blue-500' : 'text-gray-400'}`}
                        onClick={() => handleTabChange('All')}
                    >
                        All Post
                    </p>
                    <p
                        className={`cursor-pointer ${activeTab === 'Media' ? 'text-white border-b-2 border-blue-500' : 'text-gray-400'}`}
                        onClick={() => handleTabChange('Media')}
                    >
                        Media
                    </p>
                    <p
                        className={`cursor-pointer ${activeTab === 'About' ? 'text-white border-b-2 border-blue-500' : 'text-gray-400'}`}
                        onClick={() => handleTabChange('About')}
                    >
                        About
                    </p>
                </div>

                <div className={`mt-2 mb-4 transition-transform duration-300 ease-in-out ${activeTab !== prevTab ? (direction === 'left' ? 'animate-slideInLeft' : 'animate-slideInRight') : ''}`}>
                    {renderContent()}
                </div>
            </div>

            {shareModalOpen && (
                <ShareModal url={shareUrl} onClose={() => setShareModalOpen(false)} />
            )}
            {reportModalOpen && (
                <YesNoModal
                    type="report"
                    heading="Submit Report"
                    onClose={() => setReportModalOpen(false)}
                    onYes={reportGroup}
                />
            )}
            {leaveModalOpen && (
                <YesNoModal
                    type="leave"
                    heading="Are You Sure You Want to Leave?"
                    onClose={() => setLeaveModalOpen(false)}
                    onYes={setLeaveModalOpen(false)}
                />
            )}
        </div>
    );
};

export default GroupJoinedHome;
