import React, { useEffect, useRef, useState } from "react";
import { LuDot } from "react-icons/lu";
import { LuRepeat2 } from "react-icons/lu";
import { FiBarChart2 } from "react-icons/fi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { IoCaretBackCircle } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaBookmark } from "react-icons/fa6";

import Skeleton from "react-loading-skeleton";
import ReportModal from "../components/common/ReportModal";
import BlockModal from "../components/common/BlockModal";
import { useNavigate } from "react-router-dom";
import { createRepost, getRepost } from "../redux/apislice/repostSlice";
import { useDispatch, useSelector } from "react-redux";
import { FaRegComment, FaRegHeart, FaHeart, FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa6";
import { BiRepost } from "react-icons/bi";
import ShareModal from "../components/common/ShareModal";
import { TimeFormat } from "../hooks/TimeFormat";
import ReactPlayer from "react-player";
import { GoMute, GoUnmute } from "react-icons/go";
import { DecryptFn } from "../hooks/DecryptFn";
import { postLike } from "../redux/apislice/homeSlice";
import toast, { Toaster } from "react-hot-toast";
import { postSave } from "../redux/apislice/postSlice";


export default function Reposts() {
    const dispatch = useDispatch()
    const [reportPost, setReportPost] = useState(false);
    const [blockUser, setBlockUser] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [visibleMenuId, setVisibleMenuId] = useState(null);
    const menuRef = useRef(null);
    const navigate = useNavigate();
    const repostedData = useSelector((state) => state.repost)
    const { data, loading } = repostedData
    const decryptUserId = localStorage.getItem("userId") ? DecryptFn(localStorage.getItem("userId")) : null;
    const [showToast , setShowToast] = useState(false)


    console.log(data, "repost data")

    useEffect(() => {
        dispatch(getRepost())
    }, [dispatch]);

    const [bookmarks, setBookmarks] = useState({});



    const handleBookmark = (postId) => {
        setBookmarks((prevBookmarks) => ({
            ...prevBookmarks,
            [postId]: !prevBookmarks[postId],
        }));
    };

    const handleClickOutside = (event) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target)
        ) {
            setVisibleMenuId(null);
        }
    };


    // VIDEO PLAYER FUNCTIONALITY
    const [playing, setPlaying] = useState({});
    const [muted, setMuted] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState({});
    const [duration, setDuration] = useState({});

    const handlePlayPause = (id) => {
        setPlaying(prev => {
            const newState = { ...prev };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[id] = !prev[id]; // Toggle between play and pause
            return newState;
        });
    };

    // SOUND FUNCTIONALITY
    const handleMute = () => {
        setMuted(!muted);
    };

    const handleProgress = (id, state) => {
        setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
    };

    const handleDuration = (id, duration) => {
        setDuration(prev => ({ ...prev, [id]: duration }));
    };

    const formatDuration = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds);
        const timeString = date.toISOString().substr(14, 5);
        return timeString;
    };

    // LIKE FUNCTIONALITY
    const handleLike = async (postId) => {
        const response = await dispatch(postLike(postId));
        if (response.meta.requestStatus === 'fulfilled') {
            dispatch(getRepost())
            // Refresh mainPost data after successful like
        } else {
            console.error('Like action failed');
        }
    };


    const handleRepost = (postId) => {
        setShowToast(true)
      const payloadData = { original_post_id: postId }
        dispatch(createRepost(payloadData)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                dispatch(getRepost())
                if (result.payload) {
                    toast.success("Removed the repost");
                } else {
                    toast.error("Removed the repost");
                }
            } else {
                console.error('Repost action failed');
            }
        });
    }

    const handleSave = (postId) => {
        setShowToast(true)
        dispatch(postSave(postId)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                const { detail } = result.payload
                handleBookmark(postId)
                toast.success(detail);
            } else {
                console.error('Save action failed');
            }
        });
    }



    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <div className="mb-24">
           {showToast && <Toaster position="top-right" />}
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-700 text-[10px] uppercase font-bold">
                    C / FE / REPOST PAGE / V2 / SEP 13, 2024
                </p> */}
                <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
            </div>

            <div className="sticky top-0 bg-[#FF9900] border-b-[0.4px] border-[#b2b2b2] z-50">
                <div className="grid grid-cols-12 items-center px-2 py-[6px]">
                    <div className="col-span-2">
                        <IoCaretBackCircle
                            onClick={() => navigate(-1)}
                            className="h-5 w-5 cursor-pointer text-black"
                        />
                    </div>
                    <div className="col-span-8 flex items-center justify-center">
                        <p className="text-[16px] font-semibold text-[#000000]">Reposts</p>
                    </div>
                </div>
            </div>
            {loading ?
                [1, 2, 3].map((index) => (
                    <div className="mb-2 border border-gray-300 p-1" key={index}>
                        <div className="grid grid-cols-12">
                            <div className="col-span-2">
                                <Skeleton height={43} width={43} />
                            </div>
                            <div className="col-span-10">
                                <div className="flex flex-col">
                                    <div className="flex justify-between items-center">
                                        <div className="flex gap-1">
                                            <Skeleton width={100} />
                                            <Skeleton width={45} />
                                        </div>
                                        <Skeleton width={28} />
                                    </div>
                                    <Skeleton width={100} />
                                    <Skeleton count={1} />
                                </div>
                                <Skeleton height={125} />
                                <div className="flex justify-between items-center">
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                :
                <>
                    {data?.results?.map((data) => {
                        const ls = data?.original_post?.likes.filter((item) => item.created_by === decryptUserId)
                        const liked = ls.length > 0 ? true : false
                        return (
                            <div
                                key={data.id}
                                className="px-2 py-1 grid grid-cols-12 border-b-[0.4px] border-[#b2b2b2] relative"
                            >
                                <div className="col-span-2"></div>

                                <div className="col-span-10 -ml-2 flex items-center gap-x-[5px] mb-1">
                                    <BiRepost className="h-4 w-4 text-[#5c5c5c]" />
                                    <p className="text-[#7b7b7b] text-[12px] font-semibold">You reposted</p>
                                </div>

                                <div className="col-span-2">
                                    <img
                                        src={data.original_post.created_by.avatar}
                                        alt={data.original_post.created_by.first_name}
                                        className="h-8 w-8 rounded-full object-cover"
                                    />
                                </div>

                                <div className="col-span-10 -ml-2">
                                    <div className="flex flex-col">
                                        <div className="flex items-center justify-between relative">
                                            <div className="flex flex-col items-start mt-[-2px]">
                                                <p className="text-black text-[12px] font-bold">
                                                    {data.original_post.created_by.first_name} {data.original_post.created_by.last_name}
                                                </p>
                                                <div className="flex items-center mt-[-2px]">
                                                    <p className="text-[#5c5c5c] text-[10px] font-thin">
                                                        {data.original_post.created_by.username ?
                                                            data.original_post.created_by.username
                                                            : "Anonymous"
                                                        }
                                                    </p>
                                                    <LuDot className="text-[#5c5c5c]" />
                                                    <p className="text-[#5c5c5c] text-[10px] font-thin">
                                                        {TimeFormat(data.reposted_at)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="absolute top-0 -right-[6px]">
                                                <BsThreeDotsVertical
                                                    className="text-[#5c5c5c] cursor-pointer"
                                                    onClick={() => setVisibleMenuId(data.original_post.id)}
                                                />
                                            </div>
                                            {visibleMenuId === data.original_post.id && (
                                                <div className="absolute top-0 bg-[#f4f4f4] rounded-md z-30 right-[-2px]" ref={menuRef}>
                                                    <p
                                                        onClick={() => setBlockUser(true)}
                                                        className="px-2 py-[4px] hover:text-red-600 text-[10px] border-b-[0.2px] border-[#d4d4d4] whitespace-nowrap cursor-pointer"
                                                    >
                                                        Block
                                                    </p>
                                                    <p
                                                        onClick={() => setReportPost(true)}
                                                        className="px-2 py-[4px] hover:text-red-600 text-[10px] border-b-[0.2px] border-[#b2b2b2] whitespace-nowrap cursor-pointer"
                                                    >
                                                        Report
                                                    </p>
                                                    <p
                                                        onClick={() => handleRepost(data.original_post.id)}
                                                        className="px-2 py-[4px] hover:text-red-600 text-[10px] whitespace-nowrap cursor-pointer"
                                                    >
                                                        Remove repost
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        <p className="text-black text-[12px] leading-4">
                                            {data.original_post.body}
                                        </p>
                                    </div>

                                    {data.original_post.image && (
                                        <img
                                            alt={data.postContent}
                                            src={data.original_post.image}
                                            className="w-full h-auto object-cover rounded-[6px] mt-[2px]"
                                        />
                                    )}

                                    {data?.original_post.video &&
                                        <div className="mt-1 rounded-sm relative z-20" >
                                            <ReactPlayer
                                                width="100%"
                                                height="100%"
                                                muted={muted}
                                                url={data?.original_post.video}
                                                className="cursor-pointer rounded"
                                                playing={playing[data.id] || false}
                                                onClick={() => navigate(`/postview/${data.id}`)}
                                                onProgress={(state) => handleProgress(data?.id, state)}
                                                onDuration={(duration) => handleDuration(data?.id, duration)}
                                            />
                                            <button
                                                onClick={() => handlePlayPause(data?.id)}
                                                className="absolute z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                                            >
                                                {playing[data.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                                            </button>
                                            <button
                                                onClick={handleMute}
                                                className="absolute z-30 bottom-2 right-2 text-[13px] text-white bg-[#0000008b] p-[4px] rounded-full"
                                            >
                                                {muted ? <GoMute /> : <GoUnmute />}
                                            </button>
                                            <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                                                {formatDuration(duration[data?.id] - playedSeconds[data?.id] || 0)}
                                            </button>
                                        </div>
                                    }
                                    <div className="flex items-center justify-between mt-2 text-[#4d4d4d]">
                                        <div className="flex items-center cursor-pointer gap-1" onClick={() => navigate(`/post/comment/${data.original_post.id}`)}>
                                            <FaRegComment className="text-[14px]" />
                                            <p className="text-[12px]">{data.original_post.comments_count}</p>
                                        </div>
                                        <div className="flex items-center cursor-pointer gap-1" onClick={() => handleLike(data.original_post.id)}>
                                            {liked ? <FaHeart className="text-[14px] text-red-500" /> : <FaRegHeart className="text-[14px]" />}
                                            <p className="text-[12px]">{data.original_post.likes_count}</p>
                                        </div>
                                        <div className="flex items-center cursor-pointer gap-1">
                                            <LuRepeat2 className="text-[18px] text-blue-500"  onClick={() => handleRepost(data.original_post.id)} />
                                            <p className="text-[12px]">{data.original_post.reposts_count}</p>
                                        </div>
                                        <div className="flex items-center cursor-pointer gap-1">
                                            <FiBarChart2 className="text-[14px]" />
                                            <p className="text-[12px]">{data.original_post.view_count}</p>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            {bookmarks[data.original_post.id] ?
                                                <FaBookmark
                                                    className="text-[12px] text-blue-500 cursor-pointer"
                                                  
                                                /> :
                                                <FaRegBookmark
                                                    className="text-[12px] cursor-pointer"
                                                    onClick={() => handleSave(data.original_post.id)}
                                                />}
                                            <AiOutlineShareAlt
                                                className="text-[14px] cursor-pointer"
                                                onClick={() => setShareModal(true)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {
                        data?.results?.length === 0 &&
                        <div className="text-center mt-5">
                            <p className="text-[14px] text-black">No reposts available</p>
                        </div>
                    }
                </>
            }
            {/* <ReportModal /> */}
            {reportPost && <ReportModal setReportPost={setReportPost} />}
            {/* BlockModal */}
            {blockUser && <BlockModal setBlockUser={setBlockUser} />}
            {/* share modal */}
            {shareModal && <ShareModal url={"https://www.post.share.com"} onClose={() => setShareModal(false)} />}
        </div>
    )
}
