import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";

const mainUrl = REACT_APP_MAIN_URL;


export const getTashTags = createAsyncThunk(
    "getTashTags",
    async (hashtag, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/post/tags/${hashtag}/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const getAllHashTags = createAsyncThunk(
    "getAllHashTags",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/post/tags/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const hashtagSlice = createSlice({
    name: "hashtagSlice",
    initialState: {
        loading: false,
        data: [],
        allData: [],
        error: null,
    },
    extraReducers: (builder) => {

        // Fetch hashtag Data
        builder.addCase(getTashTags.pending, (state) => {
            state.loading = true;
            state.data = [];
            state.error = null;
        });
        builder.addCase(getTashTags.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(getTashTags.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.payload;
        });
        // Fetch hashtag Data
        builder.addCase(getAllHashTags.pending, (state) => {
            state.loading = true;
            state.allData = [];
            state.error = null;
        });
        builder.addCase(getAllHashTags.fulfilled, (state, action) => {
            state.loading = false;
            state.allData = action.payload
            state.error = null;
        });
        builder.addCase(getAllHashTags.rejected, (state, action) => {
            state.loading = false;
            state.allData = [];
            state.error = action.payload;
        });
    },
});

export default hashtagSlice.reducer;
