

import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Card, CardHeader, CardBody, Button, Typography } from "@material-tailwind/react";
import { IoCaretBackCircle, IoCloseOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { HiMenu } from 'react-icons/hi';


const GetHelpPage = ({login}) => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 1,
        },
        desktop: {
            breakpoint: { max: 1024, min: 768 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    const navigate = useNavigate()
    const lable = "C / FE / GET HELP / PAGE / V1 / SEP 14, 2024";
    const copyright = "Copyright © 2024  Galileo One Inc";


    const faqs = [
        {
            question: "How do I reset my password?",
            answer: "If you've forgotten your password, you can reset it by clicking the 'Forgot Password' link on the login page. Follow the instructions to receive a reset link in your email.",
        },
        {
            question: "How do I delete my account?",
            answer: "To delete your account, go to the account settings page and look for the option to delete your account. Please note that this action is irreversible.",
        },
        {
            question: "How can I change my privacy settings?",
            answer: "You can change your privacy settings by navigating to the privacy section in your account settings. Here, you can manage who can see your posts and personal information.",
        },
        {
            question: "What should I do if my account is hacked?",
            answer: "If you suspect your account has been hacked, immediately change your password and review your account activity. Contact our support team for further assistance.",
        },
        {
            question: "How can I report inappropriate content?",
            answer: "If you come across content that violates our community guidelines, you can report it by clicking the 'Report' button next to the post or profile.",
        },
        {
            question: "How do I recover my account if I no longer have access to my email?",
            answer: "If you can't access the email associated with your account, contact our support team for help. You'll need to verify your identity to recover your account.",
        },
    ];
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredFaqs = faqs.filter(faq =>
        faq.question.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="bg-gray-900 text-white min-h-screen">
            <div className="text-center bg-[#d9d9d9]">
                {/* <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p> */}
                <p className="text-black text-[9px]">{copyright}</p>
            </div>

            <header className="relative py-12 bg-gradient-to-r from-purple-600 to-blue-500 text-center">
                <h1 className="text-3xl font-extrabold mb-4 animate-pulse">Need Help?</h1>
                <p className="text-[14px] px-2 leading-4">We're here to assist you with any issue you might have.</p>
                <div className="absolute w-full flex justify-between px-2 top-2">
                    <div className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full">
                        <IoCaretBackCircle
                            onClick={() => navigate(-1)}
                            className="text-white text-[16px] cursor-pointer"
                        />
                    </div>
                    <div className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full">
                        <div className="cursor-pointer" >
                            <HiMenu className="text-[18px]" onClick={toggleMenu} />
                        </div>
                    </div>
                </div>
            </header>

            {isMenuOpen && (
                <div className="absolute top-0 left-0 w-full h-full bg-white shadow-lg z-50">
                    <div className="px-2 py-4 grid grid-cols-12 justify-between items-center">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder="Search..."
                            className="w-full py-1 px-3 text-black border border-gray-300 rounded-full outline-none col-span-10 bg-gray-100 placeholder:text-[14px]"
                        />
                        <div className='col-span-2 flex justify-end'>
                            <div className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full cursor-pointer">
                                <IoCloseOutline className="text-[18px]" onClick={toggleMenu} />
                            </div>
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="mb-8">
                            {filteredFaqs.map((faq, index) => (
                                <div key={index} className="mb-4">
                                    <Typography
                                        variant=""
                                        className="text-black cursor-pointer mb-2 text-[13px]"
                                        onClick={() => alert(`Navigate to detailed answer for: ${faq.question}`)}
                                    >
                                        {faq.question}
                                    </Typography>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            <div className="my-8 px-2">
                <h2 className="text-[22px] font-bold text-center mb-8">Popular Help Topics</h2>
                <Carousel 
                    arrows={false}
                    autoPlay={true}
                    infinite={true}
                    autoPlaySpeed={2000}
                    responsive={responsive} 
                    className="max-w-6xl mx-auto z-20"
                >
                    <div className="p-4">
                        <Card className="transform hover:scale-105 transition-transform duration-300 h-60">
                            <CardHeader
                                color="blue-gray"
                                className="relative h-40"
                            >
                                <img
                                    src="https://images.unsplash.com/photo-1610270197941-925ce9015c40?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGFjY291bnQlMjBpc3N1ZXxlbnwwfHwwfHx8MA%3D%3D"
                                    alt="Account Issues"
                                    className="w-full h-full object-cover"
                                />
                            </CardHeader>
                            <CardBody className="text-center">
                                <h3 className="text-xl font-bold">Account Issues</h3>
                                <p className="mt-2 text-[14px] leading-4">Help with account creation, login issues, and more.</p>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="p-4">
                        <Card className="transform hover:scale-105 transition-transform duration-300 h-60">
                            <CardHeader
                                color="blue-gray"
                                className="relative h-40"
                            >
                                <img
                                    src="https://plus.unsplash.com/premium_photo-1678142526549-d9afa163b9d8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8QmlsbGluZ3xlbnwwfHwwfHx8MA%3D%3D"
                                    alt="Billing"
                                    className="w-full h-full object-cover"
                                />
                            </CardHeader>
                            <CardBody className="text-center">
                                <h3 className="text-xl font-bold">Billing</h3>
                                <p className="mt-2 text-[14px] leading-4">Questions about charges, refunds, and more.</p>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="p-4">
                        <Card className="transform hover:scale-105 transition-transform duration-300 h-60">
                            <CardHeader
                                color="blue-gray"
                                className="relative h-40"
                            >
                                <img
                                    src="https://plus.unsplash.com/premium_photo-1683134018612-560033455e43?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8VGVjaG5pY2FsJTIwU3VwcG9ydHxlbnwwfHwwfHx8MA%3D%3D"
                                    alt="Technical Support"
                                    className="w-full h-full object-cover"
                                />
                            </CardHeader>
                            <CardBody className="text-center">
                                <h3 className="text-2xl font-bold">Technical Support</h3>
                                <p className="mt-2 text-[14px] leading-4">Get help with technical issues.</p>
                            </CardBody>
                        </Card>
                    </div>
                </Carousel>
            </div>

            <div className="px-3">
                <Typography variant="h3" className="text-[22px] leading-6 font-bold mb-5">
                    Frequently Asked Questions
                </Typography>
                <div className="mb-8">
                    {faqs.map((faq, index) => (
                        <div key={index} className="mb-6">
                            <Typography
                                variant="h6"
                                className="text-blue-500 text-[14px] cursor-pointer mb-1 leading-4"
                                onClick={() => alert(`Navigate to detailed answer for: ${faq.question}`)}
                            >
                                {faq.question}
                            </Typography>
                            <p className="text-gray-700 text-[12px] leading-4 text-justify">
                                {faq.answer}
                            </p>
                        </div>
                    ))}
                </div>
            </div>

           {!login && <div className={`py-4 bg-gray-800 px-2 mb-24`}>
                <div className="max-w-4xl mx-auto text-center">
                    <h2 className="text-[22px] font-bold mb-3">Contact Us</h2>
                    <p className="text-[14px] leading-4 mb-8">If you can't find the answer you're looking for, feel free to reach out.</p>
                    <Button 
                        size="lg" 
                        ripple="light" 
                        color="lightBlue" 
                        onClick={() => navigate("/contactus")} 
                        className="transition-transform duration-300 hover:scale-105">
                        Get in Touch
                    </Button>
                </div>
            </div>}
          {login &&  <div className={`py-4 bg-gray-800 px-2 `}>
                <div className="max-w-4xl mx-auto text-center">
                    <Button 
                        size="lg" 
                        ripple="light" 
                        color="lightBlue" 
                        onClick={() => navigate("/login")} 
                        className="transition-transform duration-300 hover:scale-105">
                        Back to Login
                    </Button>
                </div>
            </div>}
        </div>
    );
};

export default GetHelpPage;

