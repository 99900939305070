import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCrown } from "react-icons/fa";
import { groupUserData } from "../../data";
import { IoCaretBackCircle, IoPersonAddSharp, IoSearch } from "react-icons/io5";

const GroupMember = () => {
    const label = "C / FE / JOINED GROUP / MEMBERS / V1 / JULY 20, 2024";

    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState(groupUserData);

    useEffect(() => {
        if (searchValue === '') {
            setFilteredData(groupUserData);
        } else {
            setFilteredData(
                groupUserData.filter((data) =>
                    data.userName.toLowerCase().includes(searchValue.toLowerCase())
                )
            );
        }
    }, [searchValue]);

    return (
        <div className="bg-white h-full overflow-y-scroll pb-[6.5rem]">
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p> */}
                <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
            </div>

            <div className="sticky top-0 w-full bg-black z-50">
                <div className="px-2 flex justify-between items-center gap-3 pt-1 border-b-[0.4px] border-[#242424] pb-2">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="h-8 w-8 cursor-pointer text-white"
                    />
                    <div className="relative w-full">
                        <input
                            type="text"
                            placeholder="Search members"
                            className="w-full focus:outline-none rounded-full pl-3 pr-9 py-1 text-white bg-[#2e2e2e]"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        {searchValue.length >= 1 && (
                            <IoSearch
                                className="absolute top-1/2 right-1 transform -translate-y-1/2 text-[24px] text-black bg-white rounded-full p-1 cursor-pointer"
                            />
                        )}
                    </div>
                    <IoPersonAddSharp
                        onClick={() => navigate('/group/joined/invitemembers')}
                        className="h-7 w-7 cursor-pointer text-white"
                    />
                </div>

                <div className="flex items-center justify-around border-b-[0.4px] border-[#242424] py-[6px]">
                    <p
                        onClick={() => navigate('/group/joined/moderators')}
                        className="text-gray-400 text-[14px] cursor-pointer"
                    >
                        Moderators
                    </p>
                    <p
                        onClick={() => navigate('/group/joined/members')}
                        className="text-white border-b-2 border-blue-500 text-[14px] cursor-pointer"
                    >
                        Members
                    </p>
                </div>
            </div>

            {filteredData.length > 0 ? (
                filteredData.map((data) => (
                    <div className="grid grid-cols-12 px-2 py-1 border-b-[0.4px] border-[#b2b2b2]" key={data.id}>
                        <div className="col-span-2">
                            <img
                                alt={data.userName}
                                src={data.userProfileImage}
                                className="h-8 w-8 object-cover rounded-full cursor-pointer"
                            />
                        </div>
                        <div className="col-span-10">
                            <div className="flex justify-between items-start">
                                <div className="flex flex-col leading-[16px]">
                                    <div className="flex items-center gap-2">
                                        <p className="text-black text-[14px]">{data.userName}</p>
                                        {data.moderators && <FaCrown className="text-[14px] text-yellow-900" />}
                                    </div>
                                    <p className="text-[#5c5c5c] text-[12px]">{data.userTagname}</p>
                                </div>
                                <button className="px-3 py-[2px] text-[12px] hover:font-bold font-normal rounded-full bg-black text-white">
                                    Follow
                                </button>
                            </div>
                            <p className="text-black text-[12px] leading-[14px] pt-1">{data.userAbout}</p>
                        </div>
                    </div>
                ))
            ) : (
                <div className="text-center py-4">
                    <p className="text-gray-700 overflow-x-scroll">No results for "{searchValue}"</p>
                </div>
            )}
        </div>
    );
}

export default GroupMember;
