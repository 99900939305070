import React, { useEffect } from "react";
import helmetImg from "../assets/7552d5ec4214add42e5e9270beeedd0a.png";
import notificationImg from "../assets/bc4591f3564d7433335488fee09a5e18.png";
import postImg from "../assets/58cda8b3c8fdbe07eb9f2353c4f300ba.png";
// import homeImg from "../assets/86a9c46abe6250364fc1569cb8239f82.png";
import advertiseImg from "../assets/65d370f1f991e43161140f8d8146a4d9.png";
import { useNavigate } from "react-router-dom";
import { fetchUserNotifications } from "../redux/apislice/notificationsSlice";
import { useDispatch, useSelector } from "react-redux";
import { FaHouse } from "react-icons/fa6";
import Ticker from "./Ticker";
import { FaStar } from "react-icons/fa";
import footerLogo from "../assets/logo/footer_logo.png";

const Footer = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const notifications = useSelector(state => state.notifications)
   const copyright = useSelector(state => state.copyRigth)
   const notifyCount = notifications.data?.filter(notify => notify.is_read === false)?.length

   useEffect(() => {
      dispatch(fetchUserNotifications())
   }, [dispatch])

   return (
      <div>
         <div className="bg-[#272727] px-2 py-2">
            <div className="grid grid-cols-12 items-center text-white ">
               <div className="col-span-10 flex justify-start items-center px-2 ml-[-4px]">
                  <Ticker />
               </div>
               <div className="col-span-2 flex justify-end items-center">
                  <img
                     src={footerLogo}
                     alt="logo"
                     className="object-cover cursor-pointer w-[3rem] bg-[#38B6FF] rounded-lg px-[4px] pt-[2px] pb-[1px] mr-[4px]"
                  />
               </div>
            </div>
            <div className="bg-[#d9d9d9] grid grid-cols-12  text-center">
            </div>
         </div>

         <div className="bg-black px-4 py-2">
            <div className="flex justify-between items-center">
               {/* <img
                  alt="helmetImg"
                  src={helmetImg}
                  onClick={() => navigate("/soon")}
                  className="h-[30px] w-[30px] md:h-6 md:w-6 cursor-pointer object-cover"
               /> */}
               <FaStar
                  onClick={() => navigate("/soon")}
                  className="h-[30px] w-[30px] md:h-6 md:w-6 cursor-pointer object-cover text-white"
               />
               <div className="relative">
                  <img
                     alt="notificationImg"
                     src={notificationImg}
                     onClick={() => navigate("/notification")}
                     className="h-[30px] w-[30px] md:h-6 md:w-6 cursor-pointer object-cover"
                  />
                  {notifyCount > 0 && (<div className="absolute -top-1 -right-1 bg-[red] text-white rounded-full h-4 w-4 flex justify-center items-center text-[8px]">{notifyCount}</div>)}
               </div>
               <img
                  alt="postImg"
                  src={postImg}
                  onClick={() => navigate("/post")}
                  className="h-[35px] w-[35px] md:h-[27px] md:w-[27px] cursor-pointer object-cover"
               />
               {/* <img
                  alt="homeImg"
                  src={homeImg}
                  onClick={() => navigate("/home")}
                  className="h-[40px] w-[40px] md:h-[30px] md:w-[30px] cursor-pointer object-cover"
               /> */}
               <FaHouse
                  onClick={() => navigate("/home")}
                  className="h-[30px] w-[30px] md:h-[24px] md:w-[24px] text-[#ffffff] cursor-pointer object-cover"
               />
               <img
                  alt="advertiseImg"
                  src={advertiseImg}
                  onClick={() => navigate("/soon")}
                  className="h-[30px] w-[30px] md:h-6 md:w-6 cursor-pointer object-cover"
               />
            </div>

         </div>
      </div>
   )
}

export default Footer