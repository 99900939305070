import React, { Fragment } from "react";
import Header from "./Header";
import { PiBasketFill } from "react-icons/pi";
import { Badge, Button } from "@material-tailwind/react";

const ProductDetails = () => {
   const lable = "C / FE / PRODUCT DETAILS PAGE / V1 / MAR 30, 2024";
   return (
      <Fragment>
         <div className="bg-[#d9d9d9] text-center">
            {/* <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p> */}
            <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
         </div>
         <Header />
         <div className="grid grid-cols-12 px-2">
            <div className="col-span-6">
               <div className="grid grid-cols-12">
                  <div className="col-span-12 overflow-hidden">
                     <img
                        alt="img"
                        src="https://m.media-amazon.com/images/I/511KgtXIs5L._SX679_.jpg"
                        className="w-full rounded object-cover"
                     />
                     <div className="flex justify-between items-center py-[2px]">
                        <img
                           alt="img"
                           src="https://m.media-amazon.com/images/I/61H2TD9ov8L._SX679_.jpg"
                           className="h-[35px] w-[35px] rounded-sm object-cover cursor-pointer"
                        />
                        <img
                           alt="img"
                           src="https://m.media-amazon.com/images/I/5116N2MMBNL._SX679_.jpg"
                           className="h-[35px] w-[35px] rounded-sm object-cover cursor-pointer"
                        />
                        <img
                           alt="img"
                           src="https://m.media-amazon.com/images/I/61Jfz-beNbL._SX679_.jpg"
                           className="h-[35px] w-[35px] rounded-sm object-cover cursor-pointer"
                        />
                        <img
                           alt="img"
                           src="https://m.media-amazon.com/images/I/61E6nF7rmXL._SX679_.jpg"
                           className="h-[35px] w-[35px] rounded-sm object-cover cursor-pointer"
                        />
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-span-6 pl-[6px]">
               <p className="text-[12px] font-bold ">Hindware Smart Appliances Skyla Neo 60 Cm kitchen chimney (Black, 60cm)</p>
               <hr className="w-full my-2 h-[0.1px] bg-gray-400 border-0" />
               <p className="text-[12px]">Price: <span className="font-bold">$178</span></p>
               <p className="text-[12px]">Color: <span className="font-bold">Black</span></p>
               <p className="text-[12px]">Size: <span className="font-bold">34D x 59.7W x 85H</span></p>
               <p className="text-[12px]">Weight: <span className="font-bold">2kg</span></p>
               <p className="text-[12px]">Condition: <span className="font-bold">Good</span></p>
            </div>
            <div className="col-span-12 mt-2 mb-[1.6rem]">
               <Button className=" w-full py-[10px] rounded mb-2">Contact seller</Button>
               <Button className=" w-full py-[10px] rounded mb-2">Make an offer</Button>
               <Button className=" w-full py-[10px] rounded mb-6">Add to favorite</Button>
               <div className="flex justify-end pr-2">
                  <Badge content="2">
                     <Button className="flex justify-center items-center">
                        <PiBasketFill className="text-white text-[20px]" />
                     </Button>
                  </Badge>
               </div>
            </div>
         </div>
      </Fragment>
   )
}

export default ProductDetails