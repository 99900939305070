import React, { useState } from 'react';
import { IoCaretBackCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const PostCreate = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        header: '',
        description: '',
        postImage: null,
        location: '',
        tags: '',
        likes: 0,
        comments: 0,
        shares: 0,
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        alert('Post submitted successfully!');
    };

    return (
        <div className="bg-gray-100 h-full overflow-y-scroll">
            {/* Header */}
            <div className="sticky top-0 bg-gray-100">
                <div className="flex items-center justify-center border-b border-gray-400 p-2 relative">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[24px] cursor-pointer absolute left-2"
                    />
                    <div className="flex flex-col items-center">
                        <p className="font-bold text-[18px]">
                            Create Your Post
                        </p>
                    </div>
                </div>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit} className="max-w-lg mt-1 px-4 mb-[8rem]">
                {/* Header */}
                <div className="mb-3">
                    <label htmlFor="title" className="block text-[14px] font-medium text-black">
                        Header
                    </label>
                    <input
                        type="text"
                        id="header"
                        name="header"
                        placeholder="Enter post header"
                        value={formData.header}
                        onChange={handleChange}
                        className="mt-1 block w-full placeholder:text-[13px] border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:ring-1 focus:ring-cyan-500 outline-none"
                    />
                </div>

                {/* Title */}
                <div className="mb-3">
                    <label htmlFor="title" className="block text-[14px] font-medium text-black">
                        Pinboard Title
                    </label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        placeholder="This is what will show on the pinboard"
                        value={formData.title}
                        onChange={handleChange}
                        className="mt-1 block w-full placeholder:text-[13px] border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:ring-1 focus:ring-cyan-500 outline-none"
                    />
                </div>

                {/* Description */}
                <div className="mb-3">
                    <label htmlFor="description" className="block text-[14px] font-medium text-black">
                        Description
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        placeholder="Write a description..."
                        value={formData.description}
                        onChange={handleChange}
                        className="mt-1 block w-full placeholder:text-[13px] border border-gray-300 rounded-md shadow-sm px-2 py-1 resize-none focus:ring-1 focus:ring-cyan-500 outline-none"
                    />
                </div>

                {/* Post Image */}
                <div className="mb-3">
                    <label htmlFor="postImage" className="block text-[14px] font-medium text-black">
                        Post Image
                    </label>
                    <input
                        type="file"
                        id="postImage"
                        name="postImage"
                        onChange={handleChange}
                        className="mt-1 block w-full placeholder:text-[13px] text-sm text-gray-500 file:mr-4 file:py-[2px] file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-black file:text-white hover:file:bg-gray-800 hover:file:cursor-pointer"
                    />
                </div>

                {/* Location */}
                <div className="mb-3">
                    <label htmlFor="location" className="block text-[14px] font-medium text-black">
                        Location
                    </label>
                    <input
                        type="text"
                        id="location"
                        name="location"
                        placeholder="Enter location"
                        value={formData.location}
                        onChange={handleChange}
                        className="mt-1 block w-full placeholder:text-[13px] border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:ring-1 focus:ring-cyan-500 outline-none"
                    />
                </div>

                {/* Tag */}
                <div className="mb-3">
                    <label htmlFor="tag" className="block text-[14px] font-medium text-black">
                        Tag
                    </label>
                    <input
                        type="text"
                        id="tag"
                        name="tag"
                        placeholder="Notify neighbors"
                        value={formData.tags}
                        onChange={handleChange}
                        className="mt-1 block w-full placeholder:text-[13px] border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:ring-1 focus:ring-cyan-500 outline-none"
                    />
                </div>

                {/* Responses */}
                <div className="mb-3">
                    <label htmlFor="tag" className="block text-[14px] font-medium text-black">
                        Allow Responses
                    </label>
                    <div className="mt-1 flex items-center space-x-2">
                        <input
                            type="radio"
                            id="yes"
                            name="responses"
                            value="yes"
                            className="text-cyan-500 focus:ring-cyan-500"
                        />
                        <label htmlFor="yes" className="text-black">
                            Yes
                        </label>
                        <input
                            type="radio"
                            id="no"
                            name="responses"
                            value="no"
                            className="text-cyan-500 focus:ring-cyan-500"
                        />
                        <label htmlFor="no" className="text-black">
                            No
                        </label>
                    </div>
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className="w-full bg-black text-white font-semibold py-2 px-4 rounded-md shadow hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                    Post
                </button>
            </form>
        </div>
    );
};

export default PostCreate;
