import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;


export const getUserFollowers = createAsyncThunk(
    "getUserFollowers",
    async ({ user_id }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/user/${user_id}/followers/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

const followersSlice = createSlice({
    name: "followersSlice",
    initialState: {
        loading: false,
        followersList: {},
        error: null,
    },
    extraReducers: (builder) => {
        // Fetch groups members list
        builder.addCase(getUserFollowers.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getUserFollowers.fulfilled, (state, action) => {
            state.loading = false;
            state.followersList = action.payload
            state.error = null;
        });
        builder.addCase(getUserFollowers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
})
export default followersSlice.reducer;



