import React, { Fragment, useState } from "react";
import { IoCaretBackCircle, IoLocationOutline } from "react-icons/io5";
import { HiMiniBars3 } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import SliderMenu from "./SliderMenu";

const NeighborLocation = () => {
    const navigate = useNavigate();
    const [currentLocation, setCurrentLocation] = useState("Downtown, Springfield");
    const [newLocation, setNewLocation] = useState("");
    const [menuOpen, setMenuOpen] = useState(false);

    const handleSaveLocation = () => {
        if (newLocation.trim()) {
            setCurrentLocation(newLocation);
            setNewLocation("");
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <Fragment>
            <div className="sticky top-0 bg-white pb-[2px] z-10">
                <div className="flex items-center justify-between border-b border-gray-400 p-2">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[24px] cursor-pointer"
                    />
                    <div className="flex flex-col items-center">
                        <p className="font-bold text-[18px]">
                            Neighbor Location
                        </p>
                        <p className="flex items-center text-[10px] text-gray-500">
                            <IoLocationOutline className="text-[14px] ml-[-3px]" />
                            {currentLocation}
                        </p>
                    </div>
                    <HiMiniBars3
                        onClick={toggleMenu}
                        className="text-[24px] cursor-pointer"
                    />
                </div>
            </div>

            <div className="p-4">
                <div className="mb-4">
                    <label className="block text-[14px] font-medium text-black">
                        Set New Location
                    </label>
                    <input
                        type="text"
                        name="header"
                        placeholder="Enter new location"
                        value={newLocation}
                        onChange={(e) => setNewLocation(e.target.value)}
                        className="mt-1 block w-full placeholder:text-[13px] border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:ring-1 focus:ring-cyan-500 outline-none"
                    />
                </div>
                <div className="flex justify-center items-center w-full">
                    <button
                        onClick={handleSaveLocation}
                        className="w-[100%] bg-[#3d3d3b] hover:bg-[#252524] text-white py-2 rounded-lg transition-colors duration-300"
                    >
                        Save Location
                    </button>
                </div>
            </div>

            {menuOpen &&
                <SliderMenu
                    onClose={() => setMenuOpen(false)}
                />
            }
        </Fragment>
    );
};

export default NeighborLocation;