import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { IoCaretBackCircle, IoCloseCircle } from 'react-icons/io5';
import BlockModal from '../common/BlockModal';
import { getBlockedUsers, userUnblock } from '../../redux/apislice/blockUserSlice';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

export default function BlockedUserList() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [searchTerm, setSearchTerm] = useState('');
    const [blockUser, setBlockUser] = useState(false);
    const blockedUsers = useSelector(state => state.userBlocked)
    const { loading, data } = blockedUsers
    const [blockedUser, setBlockedUser] = useState({})


    // Filter the blocked users based on the search term
    const filteredData = (Array.isArray(data) ? data : [])?.filter(user =>
        user.blocked_user_details.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.blocked_user_details.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.blocked_user_details.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const fetchBlockedUsers = useCallback(async () => {
        // Fetch the blocked users
        try {
            await dispatch(getBlockedUsers())
        } catch (error) {
            console.log(error)
        }
    }, [dispatch])

    const handleUnblock = (data) => {
        setBlockUser(true)
        setBlockedUser(data)
    }

    const handleUnBlockUser = async (user_id) => {
        // Unblock the user
        try {
            await dispatch(userUnblock({ user_id }))
            fetchBlockedUsers()
            setBlockUser(false)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchBlockedUsers()
    }, [fetchBlockedUsers])


    return (
        <div className='h-screen w-full'>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-700 text-[10px] uppercase font-bold">
                    C / FE / BLOCKED USERS LIST PAGE  / V2 / SEP 13, 2024
                </p> */}
                <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
            </div>

            <div className="sticky top-0 w-full bg-black z-50">
                <div className="px-2 flex justify-between items-center gap-3 py-1 border-b-[0.4px] border-[#242424]">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="h-6 w-6 cursor-pointer text-white"
                    />
                    <div className="relative w-full">
                        <input
                            type="text"
                            value={searchTerm}
                            placeholder="Search for blocked users"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full focus:outline-none placeholder:text-[14px] rounded-full pl-3 pr-9 py-1 text-white bg-[#2e2e2e]"
                        />
                        {searchTerm.length >= 1 && (
                            <IoCloseCircle
                                className="absolute top-1/2 right-1 transform -translate-y-1/2 text-[24px] text-black bg-white rounded-full p-1 cursor-pointer"
                            />
                        )}
                    </div>
                </div>
            </div>


            {loading ? [1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
                <div className="grid grid-cols-12 p-2" key={index}>
                    <div className="col-span-1 text-center">
                        <Skeleton height={30} width={30} circle />

                    </div>
                    <div className="col-span-7 flex flex-col justify-start items-center">
                        <Skeleton height={15} width={150} />
                        <Skeleton height={15} width={150} />

                    </div>
                    <div className="col-span-4 flex justify-end items-center">
                        <Skeleton height={30} width={60} style={{ borderRadius: "4rem" }} />
                    </div>
                </div>
            )) :
                filteredData?.map((data) => (
                    <div className="grid grid-cols-12 px-2 py-2 border-b-[0.4px] border-[#d7d7d7] hover:bg-gray-200" key={data.id}>
                        <div className="col-span-2">
                            <img
                                alt={data.blocked_user_details.first_name}
                                src={data.blocked_user_details.avatar}
                                className="h-8 w-8 object-cover rounded-full cursor-pointer"
                            />
                        </div>
                        <div className="col-span-10">
                            <div className="flex justify-between items-center">
                                <div className="flex flex-col leading-[16px]">
                                    <div className="flex items-center gap-2">
                                        <p className="text-black text-[14px]">{data.blocked_user_details.first_name}</p>
                                    </div>
                                    <p className="text-[#5c5c5c] text-[12px]">{data.blocked_user_details.username}</p>
                                </div>
                                <button className="px-3 py-[2px] text-[12px] hover:font-bold font-normal rounded-full bg-blue-gray-100 text-black" onClick={() => handleUnblock(data.blocked_user_details)}>
                                    unblock
                                </button>
                            </div>
                        </div>
                    </div>
                ))}

            {filteredData.length === 0 && (<div className="text-center text-[#5c5c5c] mt-4">No blocked users</div>)}
            {blockUser && <BlockModal handleUnBlockUser={handleUnBlockUser} setBlockUser={setBlockUser} unblockUser={true} unblockData={blockedUser} />}
        </div>
    )
}
