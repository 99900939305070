import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;


export const getGroupMembers = createAsyncThunk(
    "getGroupMembers",
    async ({ groupId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/group/${groupId}/memberships/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)
export const assignMemberToAdmin = createAsyncThunk(
    "assignMemberToAdmin",
    async ({ groupId, memberId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/group/${groupId}/assign-new-admin/${memberId}/`, {}, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

const membersList = createSlice({
    name: "membersList",
    initialState: {
        loading: false,
        assignAdmin: false,
        membersList: [],
        error: null,
    },
    reducers: {
        assignAdmin: (state, action) => {
            state.assignAdmin = action.payload;
        }
    },
    extraReducers: (builder) => {
        // Fetch groups members list
        builder.addCase(getGroupMembers.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getGroupMembers.fulfilled, (state, action) => {
            state.loading = false;
            state.membersList = action.payload
            state.error = null;
        });
        builder.addCase(getGroupMembers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
})
export const { assignAdmin } = membersList.actions;
export default membersList.reducer;
