import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircle } from "react-icons/io5";
import { Input, Select, Option } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { FaRegEdit } from "react-icons/fa";
import toast, { Toaster } from 'react-hot-toast';
import ScaleLoader from "react-spinners/ScaleLoader";
import { fetchProfile, editProfileDetails } from "../../redux/apislice/profileSlice";

const EditAccount = () => {
   const lable = "C / FE / ACCOUNT EDIT PAGE / V1 / MAY 17, 2024";

   const [userDetails, setUserDetails] = useState(null);
   const [selectedImage, setSelectedImage] = useState(null);
   const [previewUrl, setPreviewUrl] = useState(null);
   const [isLoading, setIsLoading] = useState(false);

   const navigate = useNavigate();
   const dispatch = useDispatch();
   const profile = useSelector(state => state.profile);

   useEffect(() => {
      dispatch(fetchProfile());
   }, [dispatch]);

   useEffect(() => {
      setUserDetails(profile.user);
      return (() => setSelectedImage(null))
   }, [profile]);

   const handleInputChange = (e, field) => {
      setUserDetails(prevState => ({
         ...prevState,
         [field]: e.target?.value,
      }));
   };

   const handleImageChange = (e) => {
      if (e.target.files && e.target.files.length > 0) {
         const file = e.target.files[0];
         setSelectedImage(file);

         // Create a preview URL
         const url = URL.createObjectURL(file);
         setPreviewUrl(url);
      }
   };

   const handleFormSubmit = (e) => {
      e.preventDefault();

      setIsLoading(true);
      const promises = [];

      const formData = new FormData();
      if (selectedImage) formData.append('avatar', selectedImage);
      if (userDetails?.first_name) formData.append('first_name', userDetails.first_name);
      if (userDetails?.last_name) formData.append('last_name', userDetails.last_name);
      if (userDetails?.username) formData.append('username', userDetails.username);
      if (userDetails?.about_me) formData.append('about_me', userDetails.about_me);
      if (userDetails?.date_of_birth) formData.append('date_of_birth', userDetails.date_of_birth);
      if (userDetails?.gender) formData.append('gender', userDetails.gender);
      if (userDetails?.location) formData.append('location', userDetails.location);
      if (userDetails?.portfolio) formData.append('portfolio', userDetails.portfolio);

      const editProfileData = dispatch(editProfileDetails(formData));
      promises.push(editProfileData);

      Promise.all(promises)
         .then((results) => {
            const allUpdatesSuccessful = results.every((res) => res.type.endsWith('/fulfilled'));

            if (allUpdatesSuccessful) {
               toast.success('Profile updated successfully');
               setTimeout(() => navigate("/account"), 1000);
            } else {
               if(results[0].payload.portfolio[0]){
                  toast.error(results[0].payload.portfolio[0]);

               }else{
                  toast.error('Failed to update profile',);
               }
            }
         })
         .finally(() => {
            setIsLoading(false);
         });
   };
   return (
      <Fragment>
         <Toaster
            position="top-right"
            reverseOrder={false}
         />
         <div className="bg-[#d9d9d9] text-center">
            {/* <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p> */}
            <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
         </div>
         <form onSubmit={handleFormSubmit} className="mb-32">
            <div className="flex justify-between items-center px-2 pt-3">
               <div className="flex justify-center items-center gap-2">
                  <IoCaretBackCircle
                     onClick={() => navigate(-1)}
                     className="text-[26px] md:text-[23px] cursor-pointer"
                  />
                  <p className="text-[16px] md:text-[14px]">Edit profile</p>
               </div>
               <button type="submit" className="text-[14px] md:text-[12px] font-bold cursor-pointer hover:text-gray-800">
                  {isLoading ?
                     <ScaleLoader
                        color={"#000"}
                        loading={isLoading}
                        height={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                     />
                     : 'Save'}
               </button>
            </div>
            <div className="px-2 pt-3">
               <div className="relative w-20 h-20">
                  <img
                     alt="profile"
                     src={previewUrl || userDetails?.avatar_url}
                     className="absolute top-0 left-0 w-full h-full rounded-full object-cover"
                  />
                  <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 rounded-full"></div>
                  <FaRegEdit className="absolute cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-[25px]" />
                  <input
                     type="file"
                     accept="image/*"
                     onChange={handleImageChange}
                     className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                  />
               </div>
               <div className="flex flex-col gap-6 mt-5">
                  <Input
                     type="text"
                     value={userDetails?.first_name}
                     variant="static"
                     label="First Name"
                     placeholder="First Name"
                     onChange={(e) => handleInputChange(e, 'first_name')}
                  />
                  <Input
                     type="text"
                     value={userDetails?.last_name}
                     variant="static"
                     label="Last Name"
                     placeholder="Last Name"
                     onChange={(e) => handleInputChange(e, 'last_name')}
                  />

                  <Input
                     required
                     type="text"
                     value={userDetails?.username}
                     minLength={8}
                     variant="static"
                     label="Username"
                     placeholder="Username"
                     onChange={(e) => handleInputChange(e, 'username')}
                  />
                  <Input
                     type="link"
                     value={userDetails?.portfolio}
                     minLength={8}
                     variant="static"
                     label="Portfolio"
                     placeholder="Portfolio"
                     onChange={(e) => handleInputChange(e, 'portfolio')}
                  />
                  <Input
                     type="text"
                     value={userDetails?.about_me}
                     variant="static"
                     label="Bio"
                     placeholder="Bio"
                     onChange={(e) => handleInputChange(e, 'about_me')}
                  />
                  <Input
                     type="text"
                     value={userDetails?.date_of_birth}
                     variant="static"
                     label="Date of birth"
                     placeholder="Date of birth"
                     onChange={(e) => handleInputChange(e, 'date_of_birth')}
                  />
                  <Select
                     variant="standard"
                     value={userDetails?.gender ?? "Other"}
                     label="Gender"
                     onChange={(e) => handleInputChange({ target: { value: e } }, 'gender')}
                  >
                     <Option value="Female">Female</Option>
                     <Option value="Male">Male</Option>
                     <Option value="Other">Other</Option>
                  </Select>
                  <Input
                     type="text"
                     value={userDetails?.location}
                     variant="static"
                     label="Location"
                     required
                     placeholder="Location"
                     onChange={(e) => handleInputChange(e, 'location')}
                  />
               </div>
            </div>
         </form>
      </Fragment>
   );
};

export default EditAccount;