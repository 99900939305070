import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;


export const getAdminList = createAsyncThunk(
    "getAdminList",
    async ({ groupId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/group/${groupId}/admins/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)
export const assignGroupOwner = createAsyncThunk(
    "assignGroupOwner",
    async ({ groupId, memberId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/group/${groupId}/assign-new-owner/${memberId}/`, {}, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const removeGroupSelf = createAsyncThunk(
    "removeGroupSelf",
    async ({ groupId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.delete(`${mainUrl}/api/group/${groupId}/remove-self/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const removeAdmin = createAsyncThunk(
    "removeAdmin",
    async ({ groupId, memberId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.delete(`${mainUrl}/api/group/${groupId}/remove-admin/${memberId}/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)


const groupAdminSlice = createSlice({
    name: "groupAdminSlice",
    initialState: {
        loading: false,
        OwnerAssigning: false,
        adminList: {},
        error: null,
    },
    reducers: {
        assignOwner: (state, action) => {
            state.OwnerAssigning = action.payload;
        }
    },
    extraReducers: (builder) => {
        // Get Admin List 
        builder.addCase(getAdminList.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAdminList.fulfilled, (state, action) => {
            state.loading = false;
            state.adminList = action.payload
            state.error = null;
        });
        builder.addCase(getAdminList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
})
export const { assignOwner } = groupAdminSlice.actions;
export default groupAdminSlice.reducer;
