import React, { useEffect, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { IoArrowBackCircle } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import RightSideMenu from "./RightSideMenu";

const Header = () => {
   const [value, setValue] = useState(10);
   const [showSection3, setShowSection3] = useState(true);

   const navigate = useNavigate();
   const location = useLocation();

   const handleChange = (event) => {
      setValue(event.target.value);
   };

   useEffect(() => {
      if (location.pathname === "/marketplace/search") {
         setShowSection3(false);
      } else {
         setShowSection3(true);
      }
   }, [location.pathname]);

   return (
      <div className="sticky top-0 pb-2 z-50">
         <div className="grid grid-cols-12 items-center bg-[#FF9900] pt-1">
            <div className="col-span-4">
               <div className="flex justify-between items-center pl-2">
                  <IoArrowBackCircle onClick={() => navigate(-1)} className="text-[25px] cursor-pointer" />
                  <select className="focus:outline-none bg-transparent text-[14px] cursor-pointer">
                     <option className="cursor-pointer">Local</option>
                     <option>Global</option>
                  </select>
               </div>
            </div>
            <div className="col-span-4">
               <p className="text-center text-[14px] font-bold">MarketPlace</p>
            </div>
            <div className="col-span-4">
               <div className="grid grid-cols-12 gap-1 items-center pr-2">
                  <div className="col-span-8 flex flex-col pt-2">
                     <input
                        type="range"
                        min="1"
                        max="100"
                        value={value}
                        onChange={handleChange}
                        className="slider w-full h-[5px] cursor-pointer bg-gray-300 appearance-none rounded-full"
                     />
                     <p className="text-[8px] mt-[5px] text-center">
                        Distance: {value} KM
                     </p>
                  </div>
                  <div className="col-span-4">
                     <div className="float-right">
                        <RightSideMenu />
                     </div>
                  </div>
               </div>
            </div>
            <hr className="w-full col-span-12 mt-1 h-[0.5px] bg-gray-800 border-0" />
         </div>

         <div className="pt-2 bg-white">
            <div className="mx-6 relative">
               <input
                  type="text"
                  placeholder="Search ..."
                  onFocus={() => navigate("/marketplace/search")}
                  className="focus:outline-none border border-gray-700 w-full rounded-full pl-8 pr-3 py-[2px]"
               />
               <IoIosSearch className="absolute top-1 left-1 text-[24px] cursor-pointer" />
            </div>
         </div>

         {showSection3 &&
            <div className="flex justify-between items-center px-2 pt-2 pb-1 bg-white">
               <button
                  onClick={() => navigate("/marketplace")}
                  className={`text-[12px] px-2 py-[2px] bg-black rounded-md ${location.pathname === "/marketplace" ? "text-[#FF9900]" : "text-white"}`}
               >
                  Popular
               </button>
               <button
                  onClick={() => navigate("/marketplace/trending")}
                  className={`text-[12px] px-2 py-[2px] bg-black rounded-md ${location.pathname === "/marketplace/trending" ? "text-[#FF9900]" : "text-white"}`}
               >
                  Trending
               </button>
               <button
                  onClick={() => navigate("/marketplace/discounted")}
                  className={`text-[12px] px-2 py-[2px] bg-black rounded-md ${location.pathname === "/marketplace/discounted" ? "text-[#FF9900]" : "text-white"}`}
               >
                  Discounted
               </button>
               <button
                  onClick={() => navigate("/marketplace/pastorders")}
                  className={`text-[12px] px-2 py-[2px] bg-black rounded-md ${location.pathname === "/marketplace/pastorders" ? "text-[#FF9900]" : "text-white"}`}
               >
                  Past Orders
               </button>
            </div>
         }
      </div>
   )
}

export default Header