import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllComments } from "../../redux/apislice/commentSlice";
import ReactPlayer from "react-player";
import { GoMute, GoUnmute } from "react-icons/go";
import { FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

const Comments = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { data , loading } = useSelector(state => state.comments)
   const profile = useSelector(state => state.profile);

   // VIDEO PLAYER FUNCTIONALITY
   const [playing, setPlaying] = useState({});
   const [muted, setMuted] = useState(false);
   const [playedSeconds, setPlayedSeconds] = useState({});
   const [duration, setDuration] = useState({});

   const handlePlayPause = (id) => {
      setPlaying(prev => {
         const newState = { ...prev };
         Object.keys(newState).forEach(key => {
            newState[key] = false;
         });
         newState[id] = !prev[id]; // Toggle between play and pause
         return newState;
      });
   };

   // SOUND FUNCTIONALITY
   const handleMute = () => {
      setMuted(!muted);
   };

   const handleProgress = (id, state) => {
      setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
   };

   const handleDuration = (id, duration) => {
      setDuration(prev => ({ ...prev, [id]: duration }));
   };

   const formatDuration = (seconds) => {
      const date = new Date(0);
      date.setSeconds(seconds);
      const timeString = date.toISOString().substr(14, 5);
      return timeString;
   };
   useEffect(() => {
      dispatch(getAllComments())
   }, [dispatch])

   return (
      <div className="pb-8">
         {loading &&
            [1, 2, 3, 4, 5, 6, 7].map((_, index) => (
               <div className="mb-2 border border-gray-300 p-1 flex gap-2" key={index}>
                  <div className="flex flex-col justify-start  items-start  ">
                     <Skeleton height={25} width={25} style={{ borderRadius: "50%" }} />
                     <Skeleton width={120} height={10} className="" />
                     <Skeleton width={120} height={10} className="" />

                  </div>

                  <div className="flex flex-col justify-start items-start mx-4 border-gray-400 border-l px-2 ">
                     <Skeleton height={25} width={25} style={{ borderRadius: "50%" }} />
                     <Skeleton width={120} height={10} className="" />
                     <Skeleton width={120} height={10} className="" />
                  </div>
               </div>
            ))}

         {!loading && data?.map((data) => {
            return (
               <div>
                  {data?.comments?.map((item) => item.created_by?.id === profile.user?.id ? <div className="grid grid-cols-12 border-gray-400 border-b-[1px]">
                     <div className="col-span-6 border-gray-400 border-r relative mt-2 mb-2 pr-2">
                        <img
                           alt="img"
                           src={profile.user.avatar_url}
                           className="h-8 w-8 rounded-full object-cover cursor-pointer"
                        />
                        <p className="text-[11px] font-bold mt-1">You Commented:</p>
                        <p className="text-[11px] leading-[14px] mt-1 line-clamp-4 text-justify">{item.body}</p>
                        {/* <button className="bg-black text-white text-[10px] rounded-full bottom-0 px-4 py-[2px]">
                           # replies
                        </button> */}
                     </div>
                     <div className="col-span-6 mt-2 mb-2 pl-2">
                        <img
                           alt="img"
                           src={data.created_by.avatar}
                           className="h-8 w-8 rounded-full object-cover cursor-pointer"
                        />
                        <p className="text-[11px] leading-[14px] mt-1 line-clamp-3 text-justify">{data.body}</p>
                        {data.image && <img
                           alt="img"
                           src={data.image}
                           className="h-16 w-full rounded-sm mt-1 object-cover cursor-pointer"
                           onClick={() => navigate(`/postview/${data.id}`)}
                        />}
                        {data?.video &&
                           <div className="mt-1 rounded-sm relative">
                              <ReactPlayer
                                 url={data?.video}
                                 playing={playing[data.id] || false}
                                 muted={muted}
                                 onProgress={(state) => handleProgress(data.id, state)}
                                 onDuration={(duration) => handleDuration(data.id, duration)}
                                 onClick={() => navigate(`/postview/${data.id}`)}
                                 width="100%"
                                 height="100%"
                              />
                              <button
                                 onClick={() => handlePlayPause(data.id)}
                                 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                              >
                                 {playing[data.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                              </button>
                              <button
                                 onClick={handleMute}
                                 className="absolute bottom-2 right-2 text-[13px] text-white bg-[#0000008b] p-[4px] rounded-full"
                              >
                                 {muted ? <GoMute /> : <GoUnmute />}
                              </button>
                              <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                                 {formatDuration(duration[data.id] - playedSeconds[data.id] || 0)}
                              </button>
                           </div>
                        }
                     </div>
                  </div> : null)}

               </div>
            )
         }
         )}
      </div>
   )
}

export default Comments