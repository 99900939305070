import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL

export const createReport = createAsyncThunk(
    "createReport",
    async ({ postId, data }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/post/report/${postId}/`, data, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);



const repostPostSlice = createSlice({
    name: "repostPostSlice",
    initialState: {
        loading: false,
        data: [],
        error: null,
    },
    extraReducers: (builder) => {
        // create Repost
        builder.addCase(createReport.pending, (state) => {
            state.loading = true;
            state.data = null;
            state.error = null;
        });
        builder.addCase(createReport.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(createReport.rejected, (state) => {
            state.loading = false;
            state.data = null;
            state.error = true;
        });

    }
});

export default repostPostSlice.reducer;