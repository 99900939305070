import React, { Fragment } from "react";
import Header from "../components/marketplace/Header";
import Search from "../components/marketplace/Search";

const SearchPage = () => {
   const lable = "C / FE / SEARCH PAGE / V1 / MAR 21, 2024";
   return (
      <Fragment>
         <div className="text-center bg-[#d9d9d9]">
            {/* <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p> */}
            <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
         </div>
         <Header />
         <Search />
      </Fragment>
   )
}

export default SearchPage