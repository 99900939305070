import React from "react"
import AccountMain from "../components/account/AccountMain"

const AccountPage = () => {

   const lable = "C / FE / AccountPage / V5 / APR 02, 2024";

   return (
      <div className="px-0 pb-20">
         <div className="bg-[#d9d9d9] text-center">
            {/* <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p> */}
            <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
         </div>
         <AccountMain />
      </div>
   )
}

export default AccountPage