import React, { useState } from "react"
import { useNavigate } from "react-router-dom";
import { MdGroups } from "react-icons/md";
import { IoCaretBackCircle } from "react-icons/io5";

const GroupNotification = () => {
    const label = "C / FE / JOINED GROUP / NOTIFICATION / V1 / JULY 23, 2024";

    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState("");

    // Function to handle radio button selection
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <div className="bg-white h-full overflow-y-scroll pb-[6.5rem]">
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p> */}
                <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
            </div>
            <div className="flex items-center justify-start gap-5 px-2 pt-1">
                <IoCaretBackCircle
                    onClick={() => navigate(-1)}
                    className="text-[23px] cursor-pointer"
                />
                <div className="flex flex-col leading-4">
                    <p className="text-[14px]">Notification Settings</p>
                    <div className="flex items-center gap-2">
                        <MdGroups className="text-[15px] text-[#696969]" />
                        <p className="text-[11px] text-[#696969]">Water Nature</p>
                    </div>
                </div>
            </div>

            <hr className="my-1 h-[0.5px] bg-gray-300 border-0" />

            <div className="mt-2 px-2">
                <p className="text-[14px] font-bold">Which notification you want ?</p>
                <p className="text-[10px]">Group you joned will always be able to get notification.</p>

                <div className="flex flex-col gap-3 mt-3">
                    <div className="flex items-center justify-between">
                        <p className="text-[12px]">All Post</p>
                        <input
                            type="radio"
                            name="messageOption"
                            value="noOne"
                            checked={selectedOption === "noOne"}
                            onChange={handleOptionChange}
                            className="h-4 w-4 cursor-pointer accent-[#000000]"
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="text-[12px]">Mentioned</p>
                        <input
                            type="radio"
                            name="messageOption"
                            value="peopleYouFollow"
                            checked={selectedOption === "peopleYouFollow"}
                            onChange={handleOptionChange}
                            className="h-4 w-4 cursor-pointer accent-[#000000]"
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="text-[12px]">People you follow</p>
                        <input
                            type="radio"
                            name="messageOption"
                            value="everyone"
                            checked={selectedOption === "everyone"}
                            onChange={handleOptionChange}
                            className="h-4 w-4 cursor-pointer accent-[#000000]"
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="text-[12px]">None</p>
                        <input
                            type="radio"
                            name="messageOption"
                            value="none"
                            checked={selectedOption === "none"}
                            onChange={handleOptionChange}
                            className="h-4 w-4 cursor-pointer accent-[#000000]"
                        />
                    </div>
                    <button className="w-full py-[6px] bg-black text-white rounded-md hover:font-semibold">
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}

export default GroupNotification