import React from "react";
import { GiFullMotorcycleHelmet } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import C_LOGO from "../assets/logo/c_logo.png";
import FOOTER_LOGO from "../assets/logo/footer_logo.png";

const JoinNowPage = () => {
   const navigate = useNavigate();

   return (
      <div className="bg-[#D9D9D9] h-screen">
         <div className="text-center">
            {/* <p className="text-red-700 text-[10px] uppercase font-bold text-center">
               C / FE / Join Now / V3 / DEC 18, 2024
            </p> */}
            <p className="text-black text-[9px] text-center">
               Copyright © 2024  Galileo One Inc
            </p>
         </div>

         <div className="flex flex-col justify-between mx-8 mt-1 bg-[#D9D9D9] rounded-md overflow-y-scroll">
            <div className="flex flex-col">
               <img src={C_LOGO} alt="logo" className="h-[8rem] w-[8rem] mx-auto mt-7" />

               <p className="text-center text-[#11A098] pt-1 md:pt-0 px-3 text-[2.5rem] md:text-[2.5rem] font-bold">
                  Chirrpp
               </p>

               <p className="text-center font-bold px-3 pt-2 md:pt-4 text-[1.3rem] md:text-[14px] uppercase">
                  Relentlessly &nbsp;innovate &nbsp;and &nbsp;repair &nbsp;everything &nbsp;fast
               </p>

               <div className="flex flex-col mt-[4rem] md:mt-6 mx-8">
                  <button className="bg-black text-white w-10% mb-5 text-[18px] md:text-[15px] py-3 rounded-[5px]"
                     onClick={() => navigate("/signup")}
                  >
                     Join Chirrpp
                  </button>
                  <button
                     className="bg-black text-white w-10% mb-5 text-[18px] md:text-[15px] py-3 rounded-[5px]"
                     onClick={() => navigate("/login")}
                  >
                     Log In
                  </button>
               </div>
               <div className="flex items-center justify-center mt-4">
                  <p className="bg-[#dbd646] w-[6rem] md:w-[5rem] h-[6rem] md:h-[5rem] flex justify-center items-center cursor-pointer rounded-[5px]">
                     <img src={FOOTER_LOGO} alt="logo" className="w-[3.3rem]" />
                  </p>
               </div>
            </div>

            <div className="mx-8 mt-10 flex items-center justify-between text-[18px] md:text-[13px] font-semibold">
               <p className="cursor-pointer" onClick={() => navigate("/help")}>Help</p>
               <p className="cursor-pointer" onClick={() => navigate("/privacy/policy")}>Privacy</p>
            </div>

         </div>
      </div>
   )
}

export default JoinNowPage
