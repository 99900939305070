import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { LuRepeat2, LuDot } from "react-icons/lu";
import { CiBookmark } from "react-icons/ci";
import { FiBarChart2 } from "react-icons/fi";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "react-loading-skeleton/dist/skeleton.css";
import { HiDotsHorizontal } from "react-icons/hi";
import { GoUnmute, GoMute } from "react-icons/go";
import { DecryptFn } from "../../hooks/DecryptFn";
import { EncryptFn } from "../../hooks/EncryptFn";
import { AiOutlineShareAlt } from "react-icons/ai";
import { TimeFormat } from "../../hooks/TimeFormat";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useDispatch, useSelector } from "react-redux";
import { FaRegComment, FaRegHeart } from "react-icons/fa";
import { postSave } from "../../redux/apislice/postSlice";
import { createRepost } from "../../redux/apislice/repostSlice";
import { FaPlayCircle, FaPauseCircle, FaHeart } from "react-icons/fa";
import { getHomeData, postLike } from "../../redux/apislice/homeSlice";
import blockImg from "../../assets/64bffc98843d889c7a8c023102b75786.png";
import reportImg from "../../assets/bbdf875907dc352a0f4b0e790245193d.png";
// import followImg from "../../assets/d45bd5a1a3d9f230c95a9eed10fa05ef.png";
import shineImg from "../../assets/a422719878f5da5251c173ae5c5aa3cb.png";
import { fetchProfile, startFollow } from "../../redux/apislice/profileSlice";
import { IoIosBookmark } from "react-icons/io";

const MainPost = ({ setSharePost, setReportPost, setBlockUser, setPostId, setPostData, MainData, setMainData, setPostType }) => {
    const [menuPostId, setMenuPostId] = useState(null);
    const menuRef = useRef(null);
    const navigate = useNavigate();
    const [showToast, setShowToast] = useState(false)
    // REDUX
    const dispatch = useDispatch();
    const { loading, data } = useSelector(state => state.home);
    const [page, setPage] = useState(1);
    const divRef = useRef(null);

    const { user } = useSelector(state => state.profile)
    const decryptUserId = localStorage.getItem("userId") ? DecryptFn(localStorage.getItem("userId")) : null;

    const fetchData = useCallback(async (page) => {
        const scrollTop = divRef?.current?.scrollTop || 0;
        const scrollHeight = divRef?.current?.scrollHeight || 0;
        const result = await dispatch(getHomeData(page)).unwrap();

        setMainData((prevData) => {
            const newResults = (result?.results || []).filter(newItem => {
                switch (newItem.type) {
                    case "post":
                        return !prevData.some(prevItem => prevItem?.data.id === newItem?.data.id);
                    case "repost":
                        return !prevData.some(prevItem => prevItem?.data.id === newItem?.data.id)
                    default:
                        return true;
                }
            }
            );
            const newData = [...(prevData || []), ...newResults];
            return newData;
        });
        if (divRef.current) {
            divRef.current.scrollTop = scrollTop + (divRef.current.scrollHeight - scrollHeight);
        }
    }, [dispatch, setMainData]);

    useEffect(() => {
        fetchData(page)
    }, [page, fetchData]);

    useEffect(() => {
        try {
            dispatch(fetchProfile())?.then((result) => {
                if (result.error) {
                    console.log(result.error);
                } else {
                    const encryptedUserId = EncryptFn(result.payload.id);
                    localStorage.setItem("userId", encryptedUserId);
                }
            });
        } catch (error) {
            console.log(error)
        }
    }, [dispatch]);

    // MENU FUNCTIONALITY
    const toggleMenu = (commentId) => {
        if (menuPostId === commentId) {
            setMenuPostId(null); // Close menu if already open
        } else {
            setMenuPostId(commentId); // Open menu for this comment
        }
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuPostId(null); // Close menu if clicked outside
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // VIDEO PLAYER FUNCTIONALITY
    const [playing, setPlaying] = useState({});
    const [muted, setMuted] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState({});
    const [duration, setDuration] = useState({});
    const [initialRender, setInitialRender] = useState(true)

    const handlePlayPause = (id) => {
        setPlaying(prev => {
            const newState = { ...prev };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[id] = !prev[id]; // Toggle between play and pause
            return newState;
        });
    };

    // SOUND FUNCTIONALITY
    const handleMute = () => {
        setMuted(!muted);
    };

    const handleProgress = (id, state) => {
        setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
    };

    const handleDuration = (id, duration) => {
        setDuration(prev => ({ ...prev, [id]: duration }));
    };

    const formatDuration = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds);
        const timeString = date.toISOString().substr(14, 5);
        return timeString;
    };

    const handleShare = (id) => {
        setSharePost(true)
        setPostId(id)
    }

    // LIKE FUNCTIONALITY
    const handleLike = async (id, type) => {
        setShowToast(true)
        const response = await dispatch(postLike(id));
        if (response.meta.requestStatus === 'fulfilled') {
            setMainData((prevResults) =>
                prevResults.map((item) => {
                    if (item.type === type && item.data.id === id) {
                        // Toggle `userliked` and adjust `likes_count`
                        const isLiked = !item.data.userliked;
                        const likesCount = isLiked
                            ? item.data.likes_count + 1
                            : item.data.likes_count - 1;

                        return {
                            ...item,
                            data: {
                                ...item.data,
                                userliked: isLiked,
                                likes_count: likesCount,
                            },
                        };
                    } else if (
                        type === "repost" &&
                        item.type === type &&
                        item.data.original_post.id === id
                    ) {
                        // Update for repost
                        const isLiked = !item.data.original_post.userliked;
                        const likesCount = isLiked
                            ? item.data.original_post.likes_count + 1
                            : item.data.original_post.likes_count - 1;

                        return {
                            ...item,
                            data: {
                                ...item.data,
                                original_post: {
                                    ...item.data.original_post,
                                    userliked: isLiked,
                                    likes_count: likesCount,
                                },
                            },
                        };
                    }
                    return item;
                })
            );

            // Refresh mainPost data after successful like
        } else {
            console.error('Like action failed');
        }
    };

    const handleSave = async (id, type) => {
        setShowToast(true)
        await dispatch(postSave(id)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                const { detail } = result.payload
                toast.success(detail);
                setMainData((prevResults) =>
                    prevResults.map((item) => {
                        if (item.type === type && item.data.id === id) {
                            // Toggle `usersaved`
                            return {
                                ...item,
                                data: {
                                    ...item.data,
                                    usersaved: !item.data.usersaved,
                                },
                            };
                        }
                        else if (
                            type === "repost" &&
                            item.type === type &&
                            item.data.original_post.id === id
                        ) {
                            // Update for repost
                            return {
                                ...item,
                                data: {
                                    ...item.data,
                                    original_post: {
                                        ...item.data.original_post,
                                        usersaved: !item.data.original_post.usersaved,
                                    },
                                },
                            };
                        }
                        return item;
                    })
                );

            } else {
                console.error('Save action failed');
            }
        });
    }


    const handleFollow = async (id, type) => {
        setShowToast(true)
        await dispatch(startFollow(id))?.then((result) => {
            if (result.error) {
                console.error(result.error)
            } else {
                toast.success(result.payload.message)

                setMainData((prevResults) =>
                    prevResults?.map((item) => {

                        if (item.type === type && item?.data?.created_by?.id === id) {
                            // Update user_follow for post
                            return {
                                ...item,
                                data: {
                                    ...item.data,
                                    created_by: {
                                        ...item.data.created_by,
                                        user_follow: !item.data.created_by.user_follow,
                                    },
                                },
                            };
                        }
                        else if (type === "repost" && item?.data?.reposted_by?.id === id) {
                            // Update user_follow for repost
                            return {
                                ...item,
                                data: {
                                    ...item.data,
                                    reposted_by: {
                                        ...item.data.reposted_by,
                                        user_follow: !item.data.reposted_by.user_follow,
                                    },
                                },
                            };
                        }

                        return item;
                    }))

            }
        });



    }


    const handleNavigate = (user_id) => {
        if (user_id === decryptUserId) {
            navigate("/account");
        } else {
            navigate(`/otheruser/account/${user_id}`);
        }
    };

    const handleRepost = async (id, type) => {
        setShowToast(true)
        const payloadData = { original_post_id: id }
        await dispatch(createRepost(payloadData)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                if (result.payload) {

                    toast.success("Repost successful");
                } else {
                    toast.error("Removed the repost");
                }

                setMainData((prevResults) =>
                    prevResults.map((item) => {
                        if (item.type === type && item.data.id === id) {
                            // Toggle `userreposted` and adjust `reposts_count`
                            const isReposted = !item.data.userreposted;
                            const repostsCount = isReposted
                                ? item.data.reposts_count + 1
                                : item.data.reposts_count - 1;

                            return {
                                ...item,
                                data: {
                                    ...item.data,
                                    userreposted: isReposted,
                                    reposts_count: repostsCount,
                                },
                            };
                        } else if (
                            type === "repost" &&
                            item.type === type &&
                            item.data.original_post.id === id
                        ) {
                            // Update for repost
                            const isReposted = !item.data.original_post.userreposted;
                            const repostsCount = isReposted
                                ? item.data.original_post.reposts_count + 1
                                : item.data.original_post.reposts_count - 1;

                            return {
                                ...item,
                                data: {
                                    ...item.data,
                                    original_post: {
                                        ...item.data.original_post,
                                        userreposted: isReposted,
                                        reposts_count: repostsCount,
                                    },
                                },
                            };
                        }
                        return item;
                    })
                );
            } else {
                console.error('Repost action failed');
            }
        });
    }

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = divRef.current;
        if (!loading) {
            try {
                if (data.next && scrollTop + clientHeight >= scrollHeight - 5) {
                    // User scrolled to the bottom
                    const params = new URL(data.next).searchParams;
                    const pageNumber = params.get('page');
                    setPage(pageNumber);
                    dispatch(getHomeData(pageNumber));
                }
            } catch (error) {
                console.error("Invalid URL:", data.next || data.previous);
            }
        }
    }

    const handleReport = (postId, postData) => {
        setReportPost(true);
        setPostId(postId)
        setPostData(postData)

    }

    const handleBlock = (postData, type) => {
        console.log(type, "mentiontype")

        setPostData(postData)
        setPostType(type)
        setBlockUser(true)
    }

    const handleLoader = () => {
        setTimeout(() => {
            setInitialRender(false)
        }, 2000);
    }

    useEffect(() => {
        handleLoader()
    }, []);

    return (
        <div className="mt-2 mb-24  h-[-webkit-fill-available] z-50 overflow-y-scroll " ref={divRef}
            onScroll={handleScroll}>
            {showToast && <Toaster position="top-right" />}

            {MainData?.map((item) => {
                if (item?.type === "post") {
                    const date = TimeFormat(item.data?.created_at);
                    const text = item.data?.body
                    let parts = text?.split(/(?=#)/)
                    let description = parts[0]?.trim();
                    let hashtags = [];

                    if (description.startsWith("#")) {
                        hashtags.push(description);
                        description = "";
                    } else {
                        hashtags = parts.slice(1).map(tag => tag.trim());
                    }
                    return (
                        <div className="mb-2 p-2 bg-[#ece8e8] rounded-md" key={item?.data?.id}>
                            <div className="grid grid-cols-12">
                                <div className="col-span-2">
                                    <img
                                        src={item?.data?.created_by?.avatar}
                                        alt={item?.data?.created_by?.first_name}
                                        onClick={() => handleNavigate(`${item?.data.created_by?.id}`)}
                                        className="h-10 w-10 bg-black border border-gray-300 object-cover cursor-pointer rounded-sm"
                                    />
                                </div>
                                <div className="col-span-10">
                                    <div className="relative">
                                        <div className="mt-[-2px]">
                                            <div className="grid grid-cols-12 gap-x-2">
                                                <div className="col-span-8 flex flex-col items-start">
                                                    <p
                                                        className="text-[12px] font-[600] line-clamp-1"
                                                        onClick={() => handleNavigate(`${item?.data.created_by?.id}`)}
                                                    >
                                                        {item?.data?.created_by.first_name} {item.data.created_by.last_name}
                                                    </p>
                                                    <div className="flex items-center mt-[-2px] overflow-hidden">
                                                        <p className="text-[10px] text-gray-600 font-bold tracking-[0.5px]">
                                                            {item.data.created_by.username}
                                                        </p>
                                                        {item.data.created_by.username &&
                                                            <LuDot className="text-black" />
                                                        }
                                                        <p className="text-[10px] text-gray-600 font-light whitespace-nowrap">
                                                            {date}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-span-4 flex items-start justify-start">
                                                    {user?.id !== item.data.created_by?.id &&
                                                        <button
                                                            onClick={() => handleFollow(item?.data?.created_by?.id, "post")}
                                                            className={`text-[8px] px-2 rounded-full border border-blue-500  
                                                                ${item?.data.created_by?.user_follow ? "bg-white text-blue-500" : "bg-blue-500 text-white"} 
                                                            `}
                                                        >
                                                            {item?.data.created_by?.user_follow ? "Unfollow" : "Follow"}
                                                        </button>
                                                    }
                                                </div>
                                            </div>

                                            <p
                                                className="text-black text-[12px] leading-[1rem] mt-[4px]"
                                                onClick={() => navigate(`/postview/${item.data?.id}`)}
                                            >
                                                {description}
                                            </p>
                                            <div className="flex flex-wrap justify-start items-center gap-x-2">
                                                {hashtags?.map((item) => (
                                                    <p
                                                        onClick={() => navigate(`/hashtag/${item.slice(1)}`)}
                                                        className="text-[11px] leading-[1rem] text-blue-500 underline cursor-pointer"
                                                    >
                                                        {item}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => toggleMenu(item.data?.id)}
                                            className="absolute top-0 right-[-2px] cursor-pointer mt-[-2.5px]"
                                        >
                                            <HiDotsHorizontal className="text-[18px]" />
                                        </div>
                                        {/* POPUP MENU */}
                                        {menuPostId === item.data?.id && (
                                            <div ref={menuRef} className="absolute right-0 top-[16px] z-50">
                                                <div className="bg-[#ffffff] border border-gray-500 flex flex-col rounded-[5px]">
                                                    {user?.id !== item.data.created_by?.id && <>
                                                        <div className="flex gap-2 justify-between items-center p-1 cursor-pointer" onClick={() => { handleReport(item.data?.id, item.data) }}>
                                                            <p className="text-[10px] hover:text-red-800 font-bold">Report</p>
                                                            <img
                                                                alt="Report"
                                                                src={reportImg}
                                                                className="h-4 w-4 cursor-pointer"
                                                            />
                                                        </div>
                                                        <hr className="h-[0.8px] bg-gray-500 border-0" />
                                                        <div className="flex gap-2 justify-between items-center p-1 cursor-pointer" onClick={() => handleBlock(item?.data, "post")} >
                                                            <p className="text-[10px] font-bold">Block</p>
                                                            <img
                                                                alt="Block"
                                                                src={blockImg}
                                                                className="h-4 w-4 cursor-pointer"
                                                            />
                                                        </div>
                                                    </>}
                                                    <hr className="h-[0.8px] bg-gray-500 border-0" />
                                                    {/* {user?.id !== item.data.created_by?.id && <>
                                                        <div className="flex gap-2 justify-between items-center p-1 cursor-pointer">
                                                            <p className="text-[10px] font-bold" onClick={() => handleFollow(data?.created_by?.id)}>Follow</p>
                                                            <img
                                                                alt="Follow"
                                                                src={followImg}
                                                                className="h-4 w-4 cursor-pointer"
                                                            />
                                                        </div>
                                                        <hr className="h-[0.8px] bg-gray-500 border-0" /></>
                                                    } */}
                                                    <div className="flex gap-2 justify-between items-center p-1 cursor-pointer">
                                                        <p className="text-[10px] font-bold">Secret Sauce</p>
                                                        <img
                                                            alt="Screte"
                                                            src={shineImg}
                                                            className="h-4 w-4 cursor-pointer"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/* IMAGE */}
                                    {item.data?.image &&
                                        <img
                                            onClick={() => navigate(`/postview/${item.data?.id}`)}
                                            alt="post"
                                            src={item.data.image}
                                            className="mt-1 object-cover rounded-sm"
                                        />
                                    }

                                    {/* VIDEO */}
                                    {item.data?.video &&
                                        <div className="mt-1 rounded-sm relative" >
                                            <ReactPlayer
                                                onClick={() => navigate(`/postview/${item?.data?.id}`)}
                                                url={item.data.video}
                                                playing={playing[item.data?.id] || false}
                                                muted={muted}
                                                onProgress={(state) => handleProgress(item.data?.id, state)}
                                                onDuration={(duration) => handleDuration(item.data?.id, duration)}
                                                width="100%"
                                                height="100%"
                                                className="cursor-pointer"
                                            />
                                            <button
                                                onClick={() => handlePlayPause(item.data?.id)}
                                                className="absolute z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                                            >
                                                {playing[item.data?.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                                            </button>
                                            <button
                                                onClick={handleMute}
                                                className="absolute z-30 bottom-2 right-2 text-[13px] text-white bg-[#0000008b] p-[4px] rounded-full"
                                            >
                                                {muted ? <GoMute /> : <GoUnmute />}
                                            </button>
                                            <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                                                {formatDuration(duration[item.data?.id] - playedSeconds[item.data?.id] || 0)}
                                            </button>
                                        </div>
                                    }

                                    <div className="flex items-center justify-between mt-2 text-[#3a3a3a]">
                                        <div
                                            onClick={() => navigate(`/post/comment/${item.data?.id}`)}
                                            className="flex items-center cursor-pointer gap-1"
                                        >
                                            <FaRegComment className="text-[14px]" />
                                            <p className="text-[12px]">{item.data?.comments_count}</p>
                                        </div>
                                        <div
                                            onClick={() => handleLike(item?.data?.id, "post")}
                                            className="flex items-center cursor-pointer gap-1"
                                        >
                                            {item?.data?.userliked ? <FaHeart className="text-[13px]" color="red" /> : <FaRegHeart className="text-[14px]" />}
                                            <p className="text-[12px]">{item.data.likes_count}</p>
                                        </div>
                                        <div className="flex items-center cursor-pointer gap-1" onClick={() => handleRepost(item.data?.id, "post")}>
                                            <LuRepeat2 className="text-[16px]" color={item?.data?.userreposted ? "blue" : ""} />
                                            <p className="text-[12px]">{item?.data?.reposts_count}</p>
                                        </div>
                                        <div className="flex items-center cursor-pointer gap-1">
                                            <FiBarChart2 className="text-[14px]" />
                                            <p className="text-[12px]">{item.data?.view_count}</p>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            {item?.data?.usersaved ?
                                                <IoIosBookmark
                                                    className="text-[14px] text-blue-600 cursor-pointer"
                                                />
                                                :
                                                <CiBookmark
                                                    className="text-[14px] cursor-pointer"
                                                    onClick={() => handleSave(item.data?.id, "post")}
                                                />
                                            }
                                            <AiOutlineShareAlt
                                                onClick={() => handleShare(item.data?.id)}
                                                className="text-[14px] cursor-pointer"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                if (item?.type === "repost") {
                    const repostDate = TimeFormat(item?.data?.reposted_at);
                    const date = TimeFormat(item?.data?.original_post?.created_at);
                    const text = item?.data?.original_post.body;
                    let parts = text?.split(/(?=#)/)
                    let description = parts[0]?.trim();
                    let hashtags = [];

                    if (description.startsWith("#")) {
                        hashtags.push(description);
                        description = "";
                    } else {
                        hashtags = parts.slice(1).map(tag => tag.trim());
                    }

                    return (
                        <div className="mb-2 p-2 bg-[#ece8e8] rounded-md" key={item?.data?.id}>
                            <div className="grid grid-cols-12">
                                <div className="col-span-2">
                                    <img
                                        src={item?.data?.reposted_by?.avatar}
                                        alt={item?.data?.reposted_by?.first_name}
                                        onClick={() => handleNavigate(`${item?.data.reposted_by?.id}`)}
                                        className="h-10 w-10 bg-black object-cover cursor-pointer rounded-sm"
                                    />
                                </div>
                                <div className="col-span-10">
                                    <div className="relative">
                                        <div className="grid grid-cols-12 mt-[-2px] gap-x-2">
                                            <div className="col-span-8 flex items-start flex-col">
                                                <p className="text-[12px] font-[600] line-clamp-1">
                                                    {item?.data.reposted_by.first_name} {item?.data.reposted_by.last_name_name}
                                                </p>
                                                <div className="flex items-center mt-[-2px] w-full">
                                                    <p className="text-[10px] text-gray-600 font-bold tracking-[0.5px]">
                                                        {item?.data?.reposted_by?.username}
                                                    </p>
                                                    {item?.data?.reposted_by?.username &&
                                                        <LuDot className="text-black" />
                                                    }
                                                    <p className="text-[10px] text-gray-600 font-light whitespace-nowrap">{repostDate}</p>
                                                </div>
                                                <p className="text-[10px] text-[#222323] font-bold mt-1">Reposted</p>
                                            </div>
                                            <div className="col-span-4 flex items-start justify-start">
                                                {user?.id !== item.data.reposted_by?.id &&
                                                    <button
                                                        className={`text-[8px] px-2 rounded-full border border-blue-500 
                                                            ${item?.data?.reposted_by?.user_follow ? "bg-white text-blue-500" : "bg-blue-500 text-white"} 
                                                        `} onClick={() => handleFollow(item.data.reposted_by?.id, "repost")}
                                                    >
                                                        {item?.data?.reposted_by?.user_follow ? "Unfollow" : "Follow"}
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => toggleMenu(item?.data?.id)}
                                            className="flex absolute top-0 right-[-2px] cursor-pointer mt-[-2.5px] z-10"
                                        >
                                            <HiDotsHorizontal className="text-[18px]" />
                                        </div>
                                        {menuPostId === item?.data?.id && (
                                            <div ref={menuRef} className="absolute right-0 top-2 mt-4 z-50">
                                                <div className="bg-[#ffffff] border border-gray-500 flex flex-col rounded-[5px]">
                                                    {user?.id !== item?.data?.reposted_by?.id &&
                                                        <div className="flex gap-2 justify-between items-center p-1 cursor-pointer" onClick={() => setReportPost(true)}>
                                                            <p className="text-[10px] font-bold" >Report</p>
                                                            <img
                                                                alt="Report"
                                                                src={reportImg}
                                                                className="h-4 w-4 cursor-pointer"
                                                            />
                                                        </div>
                                                    }
                                                    <hr className="h-[0.8px] bg-gray-500 border-0" />
                                                    <div className="flex gap-2 justify-between items-center p-1 cursor-pointer">
                                                        <p className="text-[10px] font-bold">Secret Sauce</p>
                                                        <img
                                                            alt="Screte"
                                                            src={shineImg}
                                                            className="h-4 w-4 cursor-pointer"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        key={data.id}
                                        className="px-2 py-1 grid grid-cols-12 relative border-l-[0.5px] border-gray-400"
                                    >
                                        <div className="col-span-2">
                                            <img
                                                src={item?.data?.original_post.created_by.avatar}
                                                alt={item?.data?.original_post.created_by.first_name}
                                                className="h-6 w-6 rounded-full cursor-pointer object-cover"
                                                onClick={() => handleNavigate(`${item?.data?.original_post.created_by.id}`)} />
                                        </div>
                                        <div className="col-span-10 -ml-2">
                                            <div className="flex flex-col">
                                                <div className="flex items-center justify-between">
                                                    <div className="flex flex-col items-start">
                                                        <div className="flex items-center">
                                                            <p className="text-black text-[12px] font-bold cursor-pointer" onClick={() => handleNavigate(`${item?.data?.original_post.created_by.id}`)}>
                                                                {item?.data?.original_post.created_by.first_name} {item?.data?.original_post.created_by.last_name_name}
                                                            </p>

                                                        </div>
                                                        <div className="flex items-center mt-[-2px]">
                                                            <p className="text-[10px] text-gray-600 font-bold tracking-[0.5px]">
                                                                {item?.data?.original_post.created_by?.username}
                                                            </p>
                                                            {item?.data?.original_post.created_by?.username &&
                                                                <LuDot className="text-black" />
                                                            }
                                                            <p className="text-[10px] text-gray-600 font-light">{date}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-black text-[12px] leading-[1rem] mt-[1px]">
                                                    {description}
                                                </p>
                                                <div className="flex justify-start gap-2">
                                                    {hashtags?.map((item) => (
                                                        <p className="text-[10px] leading-[1rem] text-blue-500 underline cursor-pointer" onClick={() => navigate(`/hashtag/${item.slice(1)}`)}>{item}</p>
                                                    ))}
                                                </div>
                                            </div>
                                            {item?.data?.original_post.image && (
                                                <img
                                                    alt={item?.data?.original_post.image}
                                                    src={item?.data?.original_post.image}
                                                    onClick={() => navigate(`/postview/${item?.data?.original_post.id}`)}
                                                    className="w-full h-auto object-cover rounded-[6px]"
                                                />
                                            )}

                                            {item?.data?.original_post.video &&
                                                <div className="mt-1 rounded-sm relative z-40" >
                                                    <ReactPlayer
                                                        onClick={() => navigate(`/postview/${item.data.original_post.id}`)}
                                                        url={item?.data?.original_post.video}
                                                        playing={playing[item.data.id] || false}
                                                        muted={muted}
                                                        onProgress={(state) => handleProgress(item?.data?.id, state)}
                                                        onDuration={(duration) => handleDuration(item?.data?.id, duration)}
                                                        width="100%"
                                                        height="100%"
                                                        className="cursor-pointer"
                                                    />
                                                    <button
                                                        onClick={() => handlePlayPause(item?.data?.id)}
                                                        className="absolute z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                                                    >
                                                        {playing[item.data.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                                                    </button>
                                                    <button
                                                        onClick={handleMute}
                                                        className="absolute z-30 bottom-2 right-2 text-[13px] text-white bg-[#0000008b] p-[4px] rounded-full"
                                                    >
                                                        {muted ? <GoMute /> : <GoUnmute />}
                                                    </button>
                                                    <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                                                        {formatDuration(duration[item?.data?.id] - playedSeconds[item?.data?.id] || 0)}
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between mt-2 text-[#3a3a3a]">
                                        <div
                                            onClick={() => navigate(`/post/comment/${item?.data?.original_post.id}`)}
                                            className="flex items-center cursor-pointer gap-1"
                                        >
                                            <FaRegComment className="text-[14px]" />
                                            <p className="text-[12px]">{item.data?.comments_count}</p>
                                        </div>
                                        <div
                                            onClick={() => handleLike(item?.data?.original_post?.id, "repost")}
                                            className="flex items-center cursor-pointer gap-1"
                                        >
                                            {item?.data?.original_post?.userliked ? <FaHeart className="text-[13px]" color="red" /> : <FaRegHeart className="text-[14px]" />}
                                            <p className="text-[12px]">{item?.data?.original_post?.likes_count}</p>
                                        </div>
                                        <div className="flex items-center cursor-pointer gap-1" onClick={() => handleRepost(item?.data?.original_post.id, "repost")}>
                                            <LuRepeat2 className="text-[16px]" color={item?.data?.original_post?.userreposted ? "blue" : ""} />
                                            <p className="text-[12px]">{item?.data?.original_post?.reposts_count}</p>
                                        </div>
                                        <div className="flex items-center cursor-pointer gap-1">
                                            <FiBarChart2 className="text-[14px]" />
                                            <p className="text-[12px]">{item?.data?.original_post?.view_count}</p>
                                        </div>
                                        <div className="flex items-center gap-2">

                                            {item?.data?.original_post?.usersaved ?
                                                <IoIosBookmark className="text-[14px] text-blue-600 cursor-pointer" /> :
                                                <CiBookmark className="text-[14px] cursor-pointer" onClick={() => handleSave(item?.data?.original_post.id, "repost")} />
                                            }
                                            <AiOutlineShareAlt className="text-[14px] cursor-pointer" onClick={() => handleShare(item?.data?.original_post.id)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                return null
            })}

            {loading &&
                (initialRender ? [1, 2, 3].map((index) => (
                    <div className="mb-2 border border-gray-300 p-1" key={index}>
                        <div className="grid grid-cols-12">
                            <div className="col-span-2">
                                <Skeleton height={43} width={43} />
                            </div>
                            <div className="col-span-10">
                                <div className="flex flex-col">
                                    <div className="flex justify-between items-center">
                                        <div className="flex gap-1">
                                            <Skeleton width={100} />
                                            <Skeleton width={45} />
                                        </div>
                                        <Skeleton width={28} />
                                    </div>
                                    <Skeleton width={100} />
                                    <Skeleton count={1} />
                                </div>
                                <Skeleton height={125} />
                                <div className="flex justify-between items-center">
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                </div>
                            </div>
                        </div>
                    </div>
                )) :
                    <div className="flex justify-center items-center h-8 w-full">
                        <ScaleLoader
                            color={"#000"}
                            loading={loading}
                            height={10}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                )
            }
        </div>
    )
}

export default MainPost