
import React, { Fragment, useState } from "react"
import { LuDot } from "react-icons/lu";
import { IoMdSend } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { IoCaretBackCircle } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Textarea } from "@material-tailwind/react";
import { FaCommentAlt, FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { GoMute, GoUnmute } from "react-icons/go";
import toast, { Toaster } from "react-hot-toast";
import { createComment } from "../../../redux/apislice/commentSlice";


const AccountOwnerGroupComment = () => {
    const label = "C / FE / GROUP OWNER / POST COMMENT / V1 / AUG 20, 2024";
    const params = useParams();
    const dispatch = useDispatch()
    const { postId } = params;
    const postDetails = useSelector((state) => state.post);
    const { user } = postDetails
    const [comment, setComment] = useState("");

    const handleChange = (e) => {
        setComment(() => e.target.value);
    }

    const handleSubmitComment = async () => {
        if (comment) {
            try {
                const response = await dispatch(createComment({ post_id: postId, body: comment }));
                if (response.meta.requestStatus === 'fulfilled') {
                    setComment(() => "");
                    toast.success('Comment added successfully');
                    setTimeout(() => {
                        navigate(`/postview/${postId}`);
                    }, 1000);

                } else {
                    console.error('getComment action failed');
                }

            } catch (error) {
                console.error('getComment action failed');

            }

        } else {
            console.error('create the comment');
        }
    }

    const navigate = useNavigate();

    // VIDEO PLAYER FUNCTIONALITY
    const [playing, setPlaying] = useState({});
    const [muted, setMuted] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState({});
    const [duration, setDuration] = useState({});

    const handlePlayPause = (id) => {
        setPlaying(prev => {
            const newState = { ...prev };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[id] = !prev[id]; // Toggle between play and pause
            return newState;
        });
    };

    // SOUND FUNCTIONALITY
    const handleMute = () => {
        setMuted(!muted);
    };

    const handleProgress = (id, state) => {
        setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
    };

    const handleDuration = (id, duration) => {
        setDuration(prev => ({ ...prev, [id]: duration }));
    };

    const formatDuration = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds);
        const timeString = date.toISOString().substr(14, 5);
        return timeString;
    };


    return (
        <Fragment>
            <Toaster position="top-right"/>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p> */}
                <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
            </div>
            <div className="bg-white sticky top-0">
                <div className="px-2 flex items-center justify-between pt-2">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-black text-[25px] cursor-pointer"
                    />
                    <div className="cursor-pointer bg-black hover:bg-[#1a1a1a] px-3 py-[3px] rounded-full" onClick={handleSubmitComment}>
                        <IoMdSend
                            className="text-white text-[20px]"
                        />
                    </div>
                </div>

                <deiv className="h-[0.4px] bg-[#b2b2b2] w-full my-[6px]" />
            </div>

            <div className="px-2 py-1 grid grid-cols-12 border-b-[0.4px] border-[#b2b2b2]">
                <div className="col-span-2">
                    {user?.created_by?.avatar &&
                        <img
                            alt="user"
                            className="h-8 w-8 rounded-full object-cover"
                            src={user?.created_by?.avatar}
                        />}

                </div>
                <div className="col-span-10 -ml-2">
                    <div className="flex flex-col">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <p className="text-black text-[12px] font-bold">{user?.created_by?.first_name} {user?.created_by?.last_name}</p>
                                <div className="flex items-center">
                                    <p className="text-[#5c5c5c] text-[12px] font-thin">{user?.created_by?.username}</p>
                                    <LuDot className="text-[#5c5c5c] text-[12px]" />
                                    <p className="text-[#5c5c5c] text-[12px] font-thin">4h</p>
                                </div>
                            </div>
                            <BsThreeDotsVertical
                                className="text-[#5c5c5c] cursor-pointer"
                            />
                        </div>
                        <p className="text-black text-[12px]">
                            {user?.body}
                        </p>
                    </div>
                    {user?.image &&
                        <img
                            alt="post"
                            src={user?.image}
                            className="w-full h-auto object-cover rounded-[6px]"
                        />}
                    {user?.video &&
                        <div className="mt-1 rounded-sm relative">
                            <ReactPlayer
                                url={user?.video}
                                playing={playing[user.id] || false}
                                muted={muted}
                                onProgress={(state) => handleProgress(user.id, state)}
                                onDuration={(duration) => handleDuration(user.id, duration)}
                                width="100%"
                                height="100%"
                                disblepictureinpicture
                            />
                            <button
                                onClick={() => handlePlayPause(user.id)}
                                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                            >
                                {playing[user.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                            </button>
                            <button
                                onClick={handleMute}
                                className="absolute bottom-2 right-2 text-[13px] text-white bg-[#0000008b] p-[4px] rounded-full"
                            >
                                {muted ? <GoMute /> : <GoUnmute />}
                            </button>
                            <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                                {formatDuration(duration[user.id] - playedSeconds[user.id] || 0)}
                            </button>
                        </div>
                    }
                </div>
            </div>

            <div className="px-2 mt-2 pb-24">
                <div className="flex items-center gap-2 mb-3 text-gray-700">
                    <FaCommentAlt className="h-[10px] w-[10px]" />
                    <p className="text-[12px] font-bold">Add your comment</p>
                </div>

                <Textarea
                    required
                    label="Type something here..."
                    className="w-full"
                    value={comment}
                    onChange={handleChange}
                />
            </div>
        </Fragment>
    )
}

export default AccountOwnerGroupComment