import React, { useState, Fragment } from 'react';
import { IoCaretBackCircle, IoEyeOffSharp, IoEyeSharp } from 'react-icons/io5';
import { MdVerified } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {
    const navigate = useNavigate();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const numberOfDigits = 6;
    const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));

    const handleOtpChange = (value, index) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        if (newPassword !== confirmNewPassword) {
            setError('New passwords do not match.');
            setLoading(false);
            return;
        }

        if (!otpSent) {
            try {
                // await api.sendOtp(currentPassword, email);
                setOtpSent(true);
                setLoading(false);
                // alert('OTP sent to your registered email.');
                return;
            } catch (err) {
                setError('Failed to send OTP. Please try again.');
                setLoading(false);
                return;
            }
        }
        // Handle OTP verification and password change logic here
        setLoading(false);
    };

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-700 text-[10px] uppercase font-bold">
                    C / FE / CHANGE PASSWORD PAGE / V2 / SEP 13, 2024
                </p> */}
                <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
            </div>

            <div className="sticky top-0 w-full bg-black z-50">
                <div className="px-2 grid grid-cols-12 items-center gap-3 py-1 border-b-[0.4px] border-[#242424]">
                    <IoCaretBackCircle className="h-5 w-5 cursor-pointer col-span-2 text-white" onClick={() => navigate(-1)} />
                    <div className="relative col-span-8 w-full">
                        <h2 className='text-white text-center text-[16px] font-semibold'>Change Password</h2>
                    </div>
                    <div className='col-span-2'></div>
                </div>
            </div>

            <div className="pt-2 pb-4 mb-24">
                <p className='text-[12px] text-justify px-2 leading-4'>
                    Please fill out the form below to change your password. A one-time password (OTP) will be sent to your registered email for verification.
                </p>
                <form onSubmit={handleSubmit} className='bg-white p-2 rounded-md'>
                    <div className='relative my-1'>
                        <label className='text-[13px] font-semibold'>Email (OTP will be sent here)</label>
                        <input
                            type="email"
                            value={"syedb10005@tutanota.com"}
                            readOnly
                            className='bg-black outline-none text-white rounded-md w-full my-1 p-2 text-[12px]'
                        />
                        <p className='absolute top-9 right-2 flex gap-1 items-center text-[13px] font-bold text-gray-400'>
                            <MdVerified color="green" />verified
                        </p>
                    </div>
                    <div className='my-1'>
                        <label className='text-[13px] font-semibold'>Current Password</label>
                        <div className='w-full relative'>
                            <input
                                type={showCurrentPassword ? 'text' : 'password'}
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                required
                                readOnly
                                className='bg-gray-300 outline-none text-black rounded-md w-full my-1 p-2 placeholder:text-[14px]'
                            />
                            <p className='absolute top-4 right-2 cursor-pointer'>
                                {showCurrentPassword ? (
                                    <IoEyeSharp onClick={() => setShowCurrentPassword(!showCurrentPassword)} className='text-gray-600' />
                                ) : (
                                    <IoEyeOffSharp onClick={() => setShowCurrentPassword(!showCurrentPassword)} className='text-gray-600' />
                                )}
                            </p>
                        </div>
                    </div>
                    <div className='my-1'>
                        <label className='text-[13px] font-semibold'>New Password</label>
                        <input
                            type="password"
                            value={newPassword}
                            placeholder='Enter new password'
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            disabled={!otpSent}
                            className='bg-gray-300 outline-none text-black rounded-md w-full my-1 p-2 disabled:opacity-50 placeholder:text-gray-700 placeholder:text-[14px]'
                        />
                    </div>
                    <div className='my-1'>
                        <label className='text-[13px] font-semibold'>Confirm New Password</label>
                        <input
                            type="password"
                            disabled={!otpSent}
                            placeholder='Confirm new password'
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            required
                            className='bg-gray-300 outline-none text-black rounded-md w-full my-1 p-2 disabled:opacity-50 placeholder:text-gray-700 placeholder:text-[14px]'
                        />
                    </div>
                    {error && <div className="error text-red-700 text-xs">{error}</div>}
                    {otpSent && (
                        <div className='my-1'>
                            <p className='text-[13px] font-semibold text-center'>Please enter the OTP sent to your email.</p>
                            <div className="w-full flex flex-col items-center my-1">
                                <div className="flex justify-center items-center gap-2 w-full">
                                    {otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            value={digit}
                                            maxLength={1}
                                            onChange={(e) => handleOtpChange(e.target.value, index)}
                                            className='border w-9 h-auto text-white p-1 text-center rounded-md block bg-black focus:border-2 focus:outline-none appearance-none'
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='flex justify-center items-center mt-6'>
                        <button type="submit" disabled={loading} className='bg-black text-white p-2 rounded-lg w-1/2 items-center text-[14px] font-bold'>
                            {loading ? 'Processing...' : otpSent ? 'Change Password' : 'Send OTP'}
                        </button>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default ChangePassword;