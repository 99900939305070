import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL;


export const getLikedPosts = createAsyncThunk(
    "getLikedPosts",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/post/my_likes/`,config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const likedPostSlice = createSlice({
    name: "LikedPosts",
    initialState: {
        loading: false,
        posts:{},
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(getLikedPosts.pending, (state) => {
            state.loading = true;
            state.posts = null;
            state.error = null;
        });
        builder.addCase(getLikedPosts.fulfilled, (state, action) => {
            state.loading = false;
            state.posts = action.payload;
            state.error = null;
        });
        builder.addCase(getLikedPosts.rejected, (state) => {
            state.loading = false;
            state.posts = null;
            state.error = true;
        });
  
    }
});

export default likedPostSlice.reducer;