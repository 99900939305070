import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;
/* this api had completed but currently ui is not available in future will make  */
export const getGroupReportList = createAsyncThunk(
    "getGroupReportList",
    async ({ groupId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/group/${groupId}/reports/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const postGroupReport = createAsyncThunk(
    "postGroupReport",
    async ({ groupId, data }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/group/${groupId}/report/`, data, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)



const groupReportSlice = createSlice({
    name: "groupReportSlice",
    initialState: {
        loading: false,
        groupReportList: [],
        error: null,
    },
    extraReducers: (builder) => {
        // getGroupReportList
        builder.addCase(getGroupReportList.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getGroupReportList.fulfilled, (state, action) => {
            state.loading = false;
            state.groupList = action.payload
            state.error = null;
        });
        builder.addCase(getGroupReportList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // postGroupReport  
        builder.addCase(postGroupReport.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(postGroupReport.fulfilled, (state, action) => {
            state.loading = false;
            state.groupList = action.payload
            state.error = null;
        });
        builder.addCase(postGroupReport.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
})
export default groupReportSlice.reducer;
