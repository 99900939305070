import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;

export const createGroup = createAsyncThunk(
    "createGroup",
    async ({ formData }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/group/`, formData, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getCreatedGroups = createAsyncThunk(
    "getCreatedGroups",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/group/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const getGroupDetails = createAsyncThunk(
    "getGroupDetails",
    async ({ groupId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/group/${groupId}/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const updateGroup = createAsyncThunk(
    "updateGroup",
    async ({ groupId, formData }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.patch(`${mainUrl}/api/group/${groupId}/`, formData, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)
export const deleteGroup = createAsyncThunk(
    "deleteGroup",
    async ({ groupId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.delete(`${mainUrl}/api/group/${groupId}/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const inviteMember = createAsyncThunk(
    "inviteMember",
    async ({ groupId, data }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/group/${groupId}/add-multiple-users/`, data, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)
export const removeMember = createAsyncThunk(
    "removeMember",
    async ({ groupId, memberId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.delete(`${mainUrl}/api/group/${groupId}/memberships/${memberId}/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const getInvitedMembers = createAsyncThunk(
    "getInvitedMembers",
    async ({ groupId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/group/${groupId}/users/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const joinGroupRequest = createAsyncThunk(
    "joinRequest",
    async ({ groupId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/group/${groupId}/memberships/`, {}, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const groupSearch = createAsyncThunk(
    "groupSearch",
    async ({ data }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();

            const response = await axios.post(`${mainUrl}/api/group/search/`, data, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const groupPostSearch = createAsyncThunk(
    "groupPostSearch",
    async ({ data, groupId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();

            const response = await axios.post(`${mainUrl}/api/group/${groupId}/search-posts/`, data, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)



const userGroupSlice = createSlice({
    name: "userGroup",
    initialState: {
        loading: false,
        data: [],
        inviteMembers: [],
        groupDetails: {},
        error: null,
    },
    extraReducers: (builder) => {

        // Fetch hashtag Data
        builder.addCase(createGroup.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createGroup.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload
            state.error = null;
        });
        builder.addCase(createGroup.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // Fetch groups list Data
        builder.addCase(getCreatedGroups.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getCreatedGroups.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload
            state.error = null;
        });
        builder.addCase(getCreatedGroups.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // Fetch groups details Data
        builder.addCase(getGroupDetails.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getGroupDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.groupDetails = action.payload
            state.error = null;
        });
        builder.addCase(getGroupDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // Fetch groups details Data
        builder.addCase(getInvitedMembers.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getInvitedMembers.fulfilled, (state, action) => {
            state.loading = false;
            state.inviteMembers = action.payload
            state.error = null;
        });
        builder.addCase(getInvitedMembers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // search groups Data
        builder.addCase(groupSearch.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(groupSearch.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.groups
            state.error = null;
        });
        builder.addCase(groupSearch.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });
        // search groups Data
        builder.addCase(groupPostSearch.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(groupPostSearch.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.posts
            state.error = null;
        });
        builder.addCase(groupPostSearch.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

    },
});

export default userGroupSlice.reducer;
