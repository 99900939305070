import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import { FaUserPlus } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { DecryptFn } from "../../hooks/DecryptFn";
import { fetchProfile, startFollow } from "../../redux/apislice/profileSlice";
import { fetchTotalUser } from "../../redux/apislice/totalUserSlice";

const OtherUserRecommended = () => {
   const lable = "C / FE /OTHER USER  Recommened PAGE / V1 / SEP 03, 2024";
   const { loading, user } = useSelector(state => state.profile);
   const pramas = useParams();
   const { user_id } = pramas;
   const userList = useSelector(state => state.totalUsers);
   const navigate = useNavigate();
   const dispatch = useDispatch()
   const decryptUserId = localStorage.getItem("userId") ? DecryptFn(localStorage.getItem("userId")) : null;
   const [follow, setFollow] = useState([]);
   const { otherUser } = useSelector(state => state.otherUserProfile);



   const handleFollow = (userId) => {
      if (follow.includes(userId)) {
         return setFollow(follow.filter((id) => id !== userId))
      } else {
         setFollow([...follow, userId])
      }

      try {
         dispatch(startFollow(userId))?.then((result) => {
            if (result.error) {
               toast.success(result.error)
            } else {
               toast.success(result.payload.message)
            }
         });
      } catch (error) {
         console.error("error", error);
      }
   }


   const handleNavigate = (user_id) => {
      if (user_id === decryptUserId) {
         navigate("/account");
      } else {
         navigate(`/otheruser/account/${user_id}`);
      }
   };

   useEffect(() => {
      dispatch(fetchProfile())
      dispatch(fetchTotalUser())
   }, [dispatch])

   return (
      <Fragment>
         <Toaster position="top-right" />
         <div className="bg-[#d9d9d9] text-center">
            {/* <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p> */}
            <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
         </div>
         <div className="sticky top-0 bg-white z-50 px-2 pt-2">
            <IoArrowBackCircle
               onClick={() => navigate(-1)}
               className="text-[1.5rem] mt-1 cursor-pointer"
            />
            <p className="text-center text-[15px] mb-2 mt-[-15px] font-bold">{otherUser?.username}</p>
            <div className="grid grid-cols-12 border-gray-400 border text-center">
               <p className="col-span-4 text-[12px] p-1 font-bold border-r border-gray-400 cursor-pointer" onClick={() => navigate(`/otheruser/account/followers/${user_id}`)}>Followers</p>
               <p className="col-span-4 text-[12px] p-1 font-bold border-r border-gray-400 cursor-pointer  " onClick={() => navigate(`/otheruser/account/following/${user_id}`)}>Following</p>
               <p className="col-span-4 text-[12px] p-1 font-bold cursor-pointer text-purple-600" onClick={() => navigate(`/otheruser/account/Recommended/${user_id}`)}>Recommended</p>
            </div>
         </div>
         <div className="border-gray-400 border-r border-l mb-32 mx-2">
            {loading &&
               [1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                  <div className="mb-2 border border-gray-300 p-1" key={index}>
                     <div className="grid grid-cols-12">
                        <div className="col-span-2 flex justify-center items-center">
                           <Skeleton height={38} width={38} style={{ borderRadius: "50%" }} />
                        </div>
                        <div className="col-span-10">
                           <div className="flex flex-col">
                              <div className="flex justify-between items-center pr-2">
                                 <div className="flex justify-between gap-x-20 ">
                                    <Skeleton width={100} />
                                 </div>
                                 <Skeleton width={60} height={20} />
                              </div>
                              <Skeleton width={130} />
                           </div>
                        </div>
                     </div>
                  </div>
               ))
            }
            {!loading && userList?.totalUser?.map((data) => (
               <div className="flex items-center justify-between px-2 py-[6px] border-b border-gray-400" key={data.id}>
                  <div className="flex items-center gap-2" onClick={() => handleNavigate(data.id)}>
                     <img
                        alt="profile"
                        src={data?.avatar_url}
                        className="h-9 w-9 rounded-full object-cover"

                     />
                     <div className="flex flex-col items-start">
                        <p className="text-[11px]">{data?.first_name} {data?.last_name}</p>
                        <p className="text-[11px] mt-[-3px]">{data?.username}</p>
                     </div>
                  </div>
                  <div className="flex items-center">
                     {data.id !== decryptUserId && <button onClick={() => handleFollow(data.id)} className="bg-black text-white px-3 py-[3px] rounded-full text-[12px] flex items-center justify-center gap-1">
                        <FaUserPlus />
                        {follow.includes(data.id) ? "Following" : "Follow"}
                     </button>}
                  </div>

               </div>
            ))}
            {userList?.totalUser?.length === 0 && <div className="text-center mt-2">No recommended user found</div>}
         </div>
      </Fragment>
   )
}

export default OtherUserRecommended