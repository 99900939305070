import React from "react"
import Signup from "../components/auth/Signup";

const SignupPage = () => {
   const lable = "C / FE / SIGNUP PAGE / v2 / MAR 25, 2024";
   const copyright = "Copyright © 2024  Galileo One Inc";

   return (
      <div className="bg-[#ffffff] h-screen md:h-[87vh]">
         <div className="text-center">
            {/* <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p> */}
            <p className="text-black text-[9px]">{copyright}</p>
         </div>
         <Signup />
      </div>
   )
}

export default SignupPage