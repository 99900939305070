import React, { useState } from "react"
import { IoIosCloseCircle } from "react-icons/io";

const Search = () => {
   const [recentSearchLimit, setRecentSearchLimit] = useState(3);

   const recentSearch = [
      { id: 1, name: "Wooden table" },
      { id: 2, name: "Iphone case" },
      { id: 3, name: "Computer keyboard" },
      { id: 4, name: "Laptop bag" },
      { id: 5, name: "Samsung phone" },
      { id: 6, name: "Apple watch" },
      { id: 7, name: "Dell laptop" },
      { id: 8, name: "HP laptop" },
      { id: 9, name: "Macbook pro" },
      { id: 10, name: "Lenovo laptop" }
   ];

   const trendingSearch = [
      { id: 1, name: "Cutlery Set" },
      { id: 2, name: "Kitchen Pans" },
      { id: 3, name: "Nike Shoes" },
      { id: 4, name: "Lamps" }
   ];

   const toggleRecentSearch = () => {
      if (recentSearchLimit === recentSearch.length) {
         setRecentSearchLimit(3);
      } else {
         setRecentSearchLimit(recentSearch.length);
      }
   };

   return (
      <div className="mx-6 h-full">
         {recentSearch.slice(0, recentSearchLimit).map((data) => (
            <div className="flex items-center justify-between mb-[2px]" key={data.id}>
               <p>{data.name}</p>
               <IoIosCloseCircle className="text-[20px] hover:text-[#d00000] cursor-pointer" />
            </div>
         ))}
         <button className="text-[12px] text-[#00b4d8] font-bold uppercase" onClick={toggleRecentSearch}>
            {recentSearchLimit === recentSearch.length ? 'View Less' : 'View More'}
         </button>
         <p className="font-bold text-[15px] mt-2">Trending</p>
         {trendingSearch.map((data) => (
            <div className="flex items-center justify-start mb-[2px]" key={data.id}>
               <p>{data.name}</p>
            </div>
         ))}
      </div>
   )
}

export default Search