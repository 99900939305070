import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL

export const getSavedPost = createAsyncThunk(
    "getSavedPost",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/post/saved_posts/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const savedSlice = createSlice({
    name: "savedSlice",
    initialState: {
        loading: false,
        saved: [],
        error: null,
    },
    extraReducers: (builder) => {
    
        // fetch Repost
        builder.addCase(getSavedPost.pending, (state) => {
            state.loading = true;
            state.data = null;
            state.error = null;
        });
        builder.addCase(getSavedPost.fulfilled, (state, action) => {
            state.loading = false;
            state.saved = action.payload;
            state.error = null;
        });
        builder.addCase(getSavedPost.rejected, (state) => {
            state.loading = false;
            state.data = null;
            state.error = true;
        });
    }
});

export default savedSlice.reducer;