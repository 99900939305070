import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircle } from "react-icons/io5";
import { whatsHappening } from "../data";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

const WhatsHappening = () => {
    const navigate = useNavigate();
    const [visibleMenuId, setVisibleMenuId] = useState(null);
    const menuRef = useRef(null);
    const iconRef = useRef(null);

    const toggleMenu = (id) => {
        setVisibleMenuId((prevId) => (prevId === id ? null : id));
    };

    const handleClickOutside = (event) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            iconRef.current &&
            !iconRef.current.contains(event.target)
        ) {
            setVisibleMenuId(null);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="mb-24">
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-700 text-[10px] uppercase font-bold">
                    C / FE / Whats Happening / V1 / AUG 14, 2024
                </p> */}
                <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
            </div>

            <div className="sticky top-0 bg-[#FF9900] border-b-[0.4px] border-[#b2b2b2] z-50">
                <div className="grid grid-cols-12 px-2 py-[6px]">
                    <div className="col-span-2">
                        <IoCaretBackCircle
                            onClick={() => navigate(-1)}
                            className="h-6 w-6 cursor-pointer text-black"
                        />
                    </div>
                    <div className="col-span-8 flex items-center justify-center">
                        <p className="text-[16px] font-semibold text-[#000000]">
                            What's Happening
                        </p>
                    </div>
                </div>
            </div>

            {whatsHappening.map((data) => (
                <div className="flex justify-between py-2 px-2 border-b-[0.4px] border-[#dbdbdb]" key={data.id}>
                    <div className="flex flex-col">
                        <p className="text-[11px] text-gray-600">{data.topic}</p>
                        <p className="text-[12px] font-bold text-black cursor-pointer">{data.hashTag}</p>
                        <p className="text-[11px] text-gray-600">{data.posts} posts</p>
                    </div>
                    <div className="relative" ref={iconRef}>
                        <HiOutlineDotsHorizontal
                            className="text-gray-700 text-[20px] mt-[-3px] cursor-pointer"
                            onClick={() => toggleMenu(data.id)}
                        />
                        {visibleMenuId === data.id && (
                            <div className="absolute top-[0] bg-[#ebebeb] rounded-md right-[-2px]" ref={menuRef}>
                                <p className="px-2 py-[4px] hover:text-red-600 text-[10px] border-b-[0.4px] border-[#b2b2b2] whitespace-nowrap cursor-pointer">
                                    Not Interested
                                </p>
                                <p className="px-2 py-[4px] hover:text-red-600 text-[10px] whitespace-nowrap cursor-pointer">
                                    Spam or Harmfull
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default WhatsHappening;
