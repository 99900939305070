import React, { useEffect } from "react"
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllHashTags } from "../../redux/apislice/hashtagSlice";
import Skeleton from "react-loading-skeleton";

export default function Tags() {

    const dispatch = useDispatch();
    const hashtag = useSelector(state => state.hashtag)
    const { loading, allData } = hashtag;

    useEffect(() => {
        dispatch(getAllHashTags())
    }, [dispatch])
    return (
        <div className="grid grid-cols-12">
            {loading ? [1, 2, 3, 4, 5, 6, 7].map((data) => (
                <div key={data} className="col-span-12 mt-2">
                    <Skeleton height={20} width={200} />
                </div>
            )) :
                allData.map((tag, index) => (
                    <>
                        <div key={index} className="col-span-12 grid grid-cols-12 mt-2 items-center">
                            <div className="col-span-1">
                                <div className="h-7 w-7 rounded-full bg-gray-300 flex justify-center items-center">
                                    <img 
                                        alt="tags" 
                                        className="h-5 w-5 rounded-full object-cover" 
                                        src="https://th.bing.com/th/id/OIP.wobOBwqSLFTzRoBZw9sY8gHaFw?w=208&h=180&c=7&r=0&o=5&pid=1.7" 
                                    />
                                </div>
                            </div>
                            <div className="col-span-11 flex items-center gap-1 px-2">
                                <p className="text-black text-[14px] font-[500]">{tag.hashtag}</p>
                                <p className="text-gray-500 text-[9px] ml-2">{tag.occurences}k posts</p>
                            </div>
                        </div>
                    </>
                ))}
        </div>
    )
}
