import React, { Fragment, useState } from "react";
import SliderMenu from "./SliderMenu";
import { HiMiniBars3 } from "react-icons/hi2";
import { IoCaretBackCircle, IoLocationOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { neighborhoodNewsData } from "../../data";
import { MdOutlinePersonAddAlt1 } from "react-icons/md";

const NeighborInvite = () => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <Fragment>
            <div className="sticky top-0 bg-white pb-[2px] z-10">
                <div className="flex items-center justify-between border-b border-gray-400 p-2">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[24px] cursor-pointer"
                    />
                    <div className="flex flex-col items-center">
                        <p className="font-bold text-[18px]">
                            Your Neighborhood
                        </p>
                        <p className="flex items-center text-[10px] text-gray-500">
                            <IoLocationOutline className="text-[14px] ml-[-3px]" />
                            Downtown, Springfield
                        </p>
                    </div>
                    <HiMiniBars3
                        onClick={toggleMenu}
                        className="text-[24px] cursor-pointer"
                    />
                </div>
            </div>

            <div className="p-2 flex items-center justify-between border-b border-gray-400">
                <input
                    type="text"
                    placeholder="Invite your neighbors"
                    className="w-full px-2 py-1 text-[14px] border border-gray-400 rounded-lg focus:outline-none focus:ring-1 ring-light-blue-500"
                />
            </div>

            <div className="px-2 mt-2">
                <p className="text-[12px] font-bold text-start text-gray-500">
                    Suggested
                </p>
                {neighborhoodNewsData.map((data, index) => (
                    <div className="flex items-start justify-between mt-2" key={index}>
                        <div className="flex items-center">
                            <div className="w-12 h-10">
                                <img
                                    alt={data.firstName}
                                    src={data.profileImage}
                                    className="w-10 h-10 object-cover rounded-full"
                                />
                            </div>
                            <div className="flex flex-col mt-[2px]">
                                <p className="text-[13px] font-bold">{data.firstName} {data.lastName}</p>
                                <p className="flex items-center text-[10px] text-gray-500">
                                    <IoLocationOutline className="text-[14px] ml-[-2px] mt-[-1px]" />
                                    Downtown, Springfield
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <button className="flex items-center gap-x-1 text-white bg-[#d0caca] hover:bg-[#787474] p-[5px] rounded-full transition-colors duration-300">
                                <MdOutlinePersonAddAlt1 />
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {menuOpen &&
                <SliderMenu
                    toggleMenu={toggleMenu}
                    onClose={() => setMenuOpen(false)}
                />
            }
        </Fragment>
    )
}

export default NeighborInvite