import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountPost, postDelete } from "../../redux/apislice/postSlice";
import { fetchProfile } from "../../redux/apislice/profileSlice";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { LuDot } from "react-icons/lu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { TimeFormat } from "../../hooks/TimeFormat";

const Post = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate()

   // Fetching user profile
   const [userDetails, setUserDetails] = useState(null);
   const [menuPostId, setMenuPostId] = useState(null);
   const menuRef = useRef();

   const profile = useSelector(state => state.profile);

   useEffect(() => {
      dispatch(fetchProfile());
   }, [dispatch]);

   useEffect(() => {
      if (profile.user) {
         setUserDetails(profile.user);
      }
   }, [profile]);

   // Get user pofile
   const postState = useSelector(state => state.post);

   // MENU FUNCTIONALITY
   const toggleMenu = (commentId) => {
      if (menuPostId === commentId) {
         setMenuPostId(null); // Close menu if already open
      } else {
         setMenuPostId(commentId); // Open menu for this comment
      }
   };

   const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
         setMenuPostId(null); // Close menu if clicked outside
      }
   };


   const handleDeletePost = async (postId) => {
      if (postId) {
         try {
            const response = await dispatch(postDelete(postId));
            if (response.meta.requestStatus === 'fulfilled') {
               dispatch(fetchProfile());

            } else {
               console.error('Failed to delete the comment. Response meta or requestStatus is not fulfilled.');
            }
         } catch (error) {
            console.error('An error occurred while deleting the reply:', error);
         }
      } else {
         console.error('postId is undefined or null.');
      }
   };



   useEffect(() => {
      if (userDetails) {
         dispatch(getAccountPost(userDetails.id));
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [dispatch, userDetails]);

   return (
      <div className="mb-8">
         {postState.loading ?
            [1, 2, 3].map((index) => (
               <div className="mb-2 border border-gray-300 p-1" key={index}>
                  <div className="grid grid-cols-12">
                     <div className="col-span-2">
                        <Skeleton height={43} width={43} />
                     </div>
                     <div className="col-span-10">
                        <div className="flex flex-col">
                           <div className="flex justify-between items-center">
                              <div className="flex gap-1">
                                 <Skeleton width={100} />
                                 <Skeleton width={45} />
                              </div>
                              <Skeleton width={28} />
                           </div>
                           <Skeleton width={100} />
                           <Skeleton count={1} />
                        </div>
                        <Skeleton height={125} />
                        <div className="flex justify-between items-center">
                           <Skeleton height={25} width={25} />
                           <Skeleton height={25} width={25} />
                           <Skeleton height={25} width={25} />
                           <Skeleton height={25} width={25} />
                           <Skeleton height={25} width={25} />
                        </div>
                     </div>
                  </div>
               </div>
            ))
            :
            postState.user?.results?.posts && postState.user?.results?.posts.length > 0 ? postState.user.results.posts?.map((data) => (
               <div className="mb-2 border-b-[0.4px] border-[#b2b2b2] px-2 py-1" key={data.id}>
                  <div className="flex justify-between">
                     <div className="flex justify-start gap-2">
                        <img
                           alt="profile"
                           src={data?.created_by?.avatar}
                           className="h-8 w-8 rounded-full object-cover border border-gray-300"
                        />

                        <div className="flex flex-col items-start leading-4 truncate mr-3">
                           <p className="text-black text-[12px] font-bold">{data?.created_by?.first_name} {data?.created_by?.last_name}</p>
                           <div className="flex items-center">
                              <p className="text-[#5c5c5c] text-[12px] font-thin">{data?.created_by?.username}</p>
                              <LuDot className="text-[#5c5c5c] text-[12px]" />
                              <p className="text-[#5c5c5c] text-[12px] font-thin">{TimeFormat(data?.created_at)}</p>
                           </div>
                        </div>
                     </div>
                     <div className="relative">
                        <BsThreeDotsVertical className="text-[20px] md:text-[15px] cursor-pointer" onClick={() => toggleMenu(data.id)} />
                        {menuPostId === data.id && (
                           <div ref={menuRef} className="absolute right-0 mt-4 z-50">
                              <div className="bg-black flex flex-col rounded-[5px] w-14">
                                 <div className="flex gap-2 justify-between items-center p-1 cursor-pointer" onClick={() => handleDeletePost(data.id)}>
                                    <p className="text-[10px] text-white font-bold">Delete</p>
                                    <RiDeleteBin6Fill className="text-[0.7rem] text-white" />
                                 </div>
                                 <hr className="h-[0.8px] bg-gray-500 border-0" />
                                 <div className="flex gap-2 justify-between items-center p-1 cursor-pointer" onClick={() => navigate(`/post/edit/${data.id}`)}>
                                    <p className="text-[10px] text-white font-bold">Edit</p>
                                    <FaRegEdit className="text-[0.7rem] text-white" />
                                 </div>
                              </div>
                           </div>
                        )}
                     </div>
                  </div>
                  <p className="text-[16px] md:text-[12px] leading-[13px] mt-3 md:mt-2 py-1">
                     {data.body}
                  </p>
                  <div className="flex justify-between items-end mt-2 md:mt-0">
                     <div onClick={() => navigate(`/postview/${data.id}`)}>
                        {data.image &&
                           <img
                              alt="postImg"
                              src={data.image}
                              className="w-full h-auto object-cover rounded-sm"
                           />
                        }
                        {data.video &&
                           <video
                              src={data.video}
                              className="w-full h-auto object-cover rounded-sm"
                           />
                        }
                     </div>
                     {/* <button className="text-[15px] md:text-[10px] bg-black text-white rounded-md px-2 py-1" onClick={() => navigate(`/postview/${data.id}`)}>
                        View Details
                     </button> */}
                  </div>
               </div>
            ))
               :
               <p className="flex justify-center items-center mt-10 font-bold text-[12px]">
                  No Post Yet
               </p>
         }
      </div>
   )
}

export default Post;