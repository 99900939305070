import React from "react";
import MessageList from "../components/message/MessageList";

const MessagePage = () => {
   return (
      <div className="px-0 pb-20">
         <MessageList />
      </div>
   )
}

export default MessagePage