import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../SideMenu";
import { MdOutlineGroupAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getCreatedGroups } from "../../../redux/apislice/group/userGroupSlice";
import Skeleton from "react-loading-skeleton";


const GroupYourList = () => {
   const lable = "C / FE / ACCOUNT OWNER GROUP LIST / V1 / JULY 16, 2024";
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const userGroup = useSelector((state) => state.userGroup);
   const [groupList, setGroupList] = useState([]);
   const { loading } = userGroup || {};

   const fetchGroupList = useCallback(async () => {
       try {
           const response = await dispatch(getCreatedGroups()).unwrap();
           setGroupList(response || []);
       } catch (error) {
           console.log(error);
       }
   }, [dispatch]);

   useEffect(() => {
       fetchGroupList();
   }, [fetchGroupList]);

   return (
      <Fragment>
         <div className="sticky top-0 bg-white z-50">
            <div className="bg-[#d9d9d9] text-center">
               {/* <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p> */}
               <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
            </div>

            <div className="flex items-center justify-between gap-2 px-2 mt-1">
               <div className="flex items-center gap-4">
                  <SideMenu />
                  <p>Your Groups</p>
               </div>
               <MdOutlineGroupAdd className="text-[23px] cursor-pointer" onClick={() => navigate("/group/create")} />
            </div>

            <hr className="my-1 h-[0.5px] bg-gray-300 border-0" />
         </div>

         <div className="overflow-y-scroll mb-24">
            {loading ? [1, 2, 3, 4, 5, 6].map((_, index) => (<div key={index} className="flex gap-2 px-2 mb-2">
               <Skeleton height={92} width={92} className=" img-shadow rounded-lg object-cover cursor-pointer" />
               <div className="flex flex-col justify-between">
                  <div className="flex flex-col">
                     <div className="flex flex-col leading-[16px]">
                        <p className="text-[14px] line-clamp-1">

                           <Skeleton height={10} width={100} className="img-shadow rounded-lg object-cover cursor-pointer" />

                        </p>
                        <p className="text-[12px] text-gray-500"> <Skeleton height={10} width={60} className="img-shadow rounded-lg object-cover cursor-pointer" /> </p>
                     </div>
                     <Skeleton height={20} width={40} className="img-shadow rounded-lg object-cover cursor-pointer" />
                  </div>
               </div>
            </div>

            )) : groupList?.map((data) => (
               <div className="flex gap-2 px-2 mb-2" key={data.id}>
                  <img
                     alt={data.name}
                     src={data.image}
                     className="w-[6rem] h-[6rem] img-shadow rounded-lg object-cover cursor-pointer"
                  />
                  <div className="flex flex-col justify-between">
                     <div className="flex flex-col">
                        <div className="flex flex-col leading-[16px]">
                           <p className="text-[14px] line-clamp-1">{data.name}</p>
                           <p className="text-[12px] text-gray-500">{data.members_count} Members</p>
                        </div>
                        <button className="text-[10px] px-2 py-[2px] font-bold rounded-md text-white bg-black hover:opacity-90 w-[50px] mt-1" onClick={() => navigate(`/account/owner/group/${data.id}`)}>
                           View
                        </button>
                     </div>

                     <div className="flex items-center -space-x-4">
                        {/* {data.membersDeatils.map((member) => (
                           <Avatar
                              key={member.id}
                              variant="circular"
                              alt={member.name}
                              className="border-2 border-white h-7 w-7 cursor-pointer hover:z-10 focus:z-10"
                              src={member.profileImage}
                           />
                        ))} */}
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </Fragment>
   )
}

export default GroupYourList