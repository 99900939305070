import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import { securityLogin } from "../redux/apislice/securitySlice";
import { EyeSlashIcon, EyeIcon } from "@heroicons/react/24/solid";
import { Typography, Input, Button } from "@material-tailwind/react";
import PulseLoader from "react-spinners/PulseLoader";
import logo from "../assets/logo/c_logo.png"

const SecurityPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => setPasswordShown((cur) => !cur);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.security);

    const handleEvent = (e) => {
        e.preventDefault();
        let loginDetails = { email, password };

        dispatch(securityLogin(loginDetails)).then((result) => {
            if (result.payload) {
                toast.success("Login Successful");
                setTimeout(() => navigate("/joinnow"), 1000);
            } else {
                toast.error("Invalid Credentials");
            }
        });
    }

    return (
        <Fragment>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />

            <div className="bg-gradient-to-br from-gray-700 via-gray-400 to-cyan-800 h-full px-5 pt-[4rem] relative">

                <div className="absolute left-0 bottom-2 w-full">
                    {/* <p className="text-red-700 text-[10px] uppercase font-bold text-center">
                        C / FE / Restricted page / V2 / JUNE 05, 2024
                    </p> */}
                    <p className="text-black text-[9px] text-center">
                        Copyright © 2024  Galileo One Inc
                    </p>
                </div>

                <img
                    src={logo}
                    alt="logo"
                    className="h-28 w-28 mx-auto"
                />

                <section className="text-center p-6 border border-gray-500 rounded-lg bg-white">
                    <div>
                        <Typography variant="h5" color="blue-gray" className="mb-2 uppercase">
                            Security Login
                        </Typography>
                        <Typography className="mb-4 text-gray-900 font-normal text-[12px]">
                            Enter your email and password to login
                        </Typography>

                        <form onSubmit={handleEvent}>
                            <div className="mb-5">
                                <Input
                                    type="email"
                                    name="email"
                                    size="lg"
                                    label="Enter your email"
                                    required
                                    autoComplete="nope"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    type={passwordShown ? "text" : "password"}
                                    name="password"
                                    size="lg"
                                    label="Enter your password"
                                    required
                                    autoComplete="off"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    icon={
                                        <i onClick={togglePasswordVisiblity}>
                                            {passwordShown ? (
                                                <EyeIcon className="h-5 w-5" />
                                            ) : (
                                                <EyeSlashIcon className="h-5 w-5" />
                                            )}
                                        </i>
                                    }
                                />
                            </div>
                            <Button color="gray" size="lg" className="mt-6" type="submit" fullWidth>
                                {loading ?
                                    <PulseLoader
                                        color={"#fff"}
                                        loading={loading}
                                        size={10}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                    : "sign in"}
                            </Button>
                        </form>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default SecurityPage;
