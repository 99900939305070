import { Avatar } from "@material-tailwind/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { PiDotsThreeOutlineFill } from "react-icons/pi";
import { MdReplyAll } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin4Line, RiSendPlaneFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { createReply, getReply, updateComment, daleteComment, getComments, deleteReply, updateReply } from "../../redux/apislice/commentSlice";
import Skeleton from 'react-loading-skeleton';
import { IoCloseCircle } from "react-icons/io5";
import toast, { Toaster } from "react-hot-toast";
import { MdOutlineReportGmailerrorred } from "react-icons/md";

export default function PostComment({ replyData, id, setComment, comment, handleCommentChange, handleSubmit, commentlist, loading }) {
   const menuRef = useRef();
   const dispatch = useDispatch()
   const profile_details = useSelector((state) => state.profile)
   const [menuId, setMenuId] = useState(null);
   const [replyMenuId, setReplyMenuId] = useState(null);
   const [replyId, setReplyId] = useState(null);
   const [showReply, setShowReply] = useState(false);
   const [reply, setReply] = useState({ body: "" })
   const [replyShowInput, setReplyShowInput] = useState(null)
   const [updateReply_Id, setUpdateReply_Id] = useState(null)
   const inputRef = useRef(null);
   const commentinputRef = useRef(null);
   const [updateComment_Id, setUpdateComment_Id] = useState(null);
   const [commentData, setCommentData] = useState([])

   useEffect(() => {
      setCommentData(commentlist)
   }, [commentlist])

   const replyToCemment = commentData.filter((comment) => comment.id === replyShowInput);
   /* functions */
   const handleupdateComment = (commentId) => {
      setUpdateComment_Id(commentId);
      setMenuId(null);
      setComment({ ...comment, body: commentData.filter((comment) => comment.id === commentId)[0]?.body });
   };

   const handleShowReply = async (commentId) => {
      setReplyId(commentId);
      setShowReply(!showReply);
   };

   const handleGetReply = useCallback(async () => {
      const response = await dispatch(getReply(replyId));
      if (response.meta.requestStatus !== 'fulfilled') {
         console.error('getCommentreply action failed');
      }
   }, [replyId, dispatch]);

   const handleReply = (comment_Id) => {
      setShowReply(false);
      setReplyShowInput(comment_Id);
      setMenuId(null);
   };

   const handleSubmitReply = async () => {
      if (reply && reply.body) {
         const response = await dispatch(createReply({ comment_Id: replyShowInput, body: reply.body }));
         if (response.meta.requestStatus === 'fulfilled') {
            setReply({ body: "" });
            setReplyShowInput(null);
            dispatch(getComments(id));
         } else {
            toast.error('Failed to create the comment');
         }
      } else {
         toast.error('Failed to create the comment reply');
      }
   };

   const handleChangereply = (e) => {
      setReply({ ...reply, [e.target.name]: e.target.value });
   };

   const handleUpdateCommentReply = (reply_Id) => {
      setUpdateReply_Id(reply_Id);
      setReplyMenuId(null);
      setComment({ ...comment, body: replyData.filter((reply) => reply.id === reply_Id)[0]?.body });
   };

   const handleUpdateCommentsReply = async () => {
      if (updateReply_Id) {
         try {
            const response = await dispatch(updateReply({ reply_Id: updateReply_Id, body: comment.body }));
            if (response.meta.requestStatus === 'fulfilled') {
               setComment({ body: "" });
               dispatch(getComments(id));
               setUpdateReply_Id(null);
               dispatch(handleGetReply(replyId));
            } else {
               toast.error('Kindly update your comment');
            }
         } catch (error) {
            console.error('An error occurred while updating the reply:', error);
         }
      } else {
         toast.error('Failed to update the comment');
      }
   };

   const handleShowMenu = (id) => {
      setMenuId(id);
      setReplyShowInput(null);
   };

   const handleShowMenuReply = (id) => {
      setReplyMenuId(id);
   };

   const handleDeleteReply = async (reply_Id) => {
      if (reply_Id) {
         try {
            const response = await dispatch(deleteReply(reply_Id));
            if (response.meta.requestStatus === 'fulfilled') {
               // dispatch(getComments(id));
               dispatch(handleGetReply(replyId));
            } else {
               console.error('Failed to delete the comment. Response meta or requestStatus is not fulfilled.');
            }
         } catch (error) {
            console.error('An error occurred while deleting the reply:', error);
         }
      } else {
         console.error('reply_Id is undefined or null.');
      }
   };

   const handleUpdateComments = async () => {
      if (updateComment_Id) {
         try {
            const response = await dispatch(updateComment({ comment_Id: updateComment_Id, body: comment.body }));
            if (response.meta.requestStatus === 'fulfilled') {
               setComment({ body: "" });
               dispatch(getComments(id));
               setUpdateComment_Id(null);
            } else {
               console.error('comment updated action failed');
            }
         } catch (response) {
            toast.error('An error occurred while updating the comment:', response.error);
         }
      }
   };

   const handleDeleteComment = async (comment_Id) => {
      if (comment_Id) {
         const response = await dispatch(daleteComment(comment_Id));
         if (response.meta.requestStatus === 'fulfilled') {
            // dispatch(getComments(id));
            setReplyShowInput(null);
            setCommentData((prevData) => prevData.filter((comment) => comment.id !== comment_Id))
            console.log(commentData)
         } else {
            console.error('Failed to delete the comment');
         }
      } else {
         console.error('Failed to delete the comment');
      }
   };

   const submitComment = async () => {
      if (updateComment_Id) {
         handleUpdateComments();
      } else if (updateReply_Id) {
         handleUpdateCommentsReply();
      } else {
         handleSubmit();
      }
   };

   const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
         setMenuId(null);
         setReplyMenuId(null);
      }
   };

   const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
         e.preventDefault();
         submitComment();
      }
   };
   const handleReplyKeyDown = (e) => {
      if (e.key === 'Enter') {
         e.preventDefault();
         handleSubmitReply();
      }
   }

   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   useEffect(() => {
      if (replyShowInput && inputRef.current) {
         inputRef.current.focus();
      }
   }, [replyShowInput]);

   useEffect(() => {
      if (updateComment_Id && commentinputRef.current) {
         commentinputRef.current.focus();
      }
   }, [updateComment_Id, updateReply_Id]);

   useEffect(() => {
      if (showReply) {
         handleGetReply();
      }
   }, [showReply, handleGetReply]);

   return (
      <>
         <Toaster
            position="top-right"
            reverseOrder={false}
         />
         {loading ?
            <div className=" h-[-webkit-fill-available] overflow-y-scroll mt-[0.5rem]">
               {[1, 2, 3, 4, 5, 6, 7, 8].map(() => (
                  <div className="mb-2 p-1">
                     <div className="w-full flex items-center justify-start gap-2">
                        <Skeleton height={40} width={40} style={{ borderRadius: "50%" }} />
                        <div className="flex flex-col items-center justify-start">
                           <Skeleton width={250} />
                           <Skeleton width={250} />
                        </div>
                     </div>
                     <hr className="bg-gray-300 h-[0.5px] w-full" />
                  </div>
               ))}
            </div>
            :
            <div className="h-[-webkit-fill-available] overflow-y-scroll mt-[0.5rem] relative">
               {commentData?.map((comment) => (
                  <div key={comment.id}>
                     <div className="grid grid-cols-12 px-2 " key={comment.id}>
                        <div className="col-span-2 py-1">
                           <Avatar
                              className="h-8 w-8 "
                              src={comment.created_by.avatar}
                              alt="commentuserProfile"
                           />
                        </div>
                        <div className="col-span-9 grid grid-cols-12">
                           <div className="col-span-12">
                              <p className="text-[16px] md:text-[13px] font-bold">{comment.created_by.first_name}{comment.created_by.last_name}</p>
                           </div>
                           <div className="col-span-12">
                              <p className="text-[14px] md:text-[12px] font-medium leading-[16px] line-clamp-2">
                                 {comment.body}
                              </p>
                           </div>
                           <div className="col-span-12 grid grid-cols-12 items-center ">
                              <p className="col-span-2 text-[13px] md:text-[11px] text-blue-600 font-semibold cursor-pointer" onClick={() => handleReply(comment.id)}>
                                 Reply
                              </p>
                           </div>

                        </div>
                        <div className="col-span-1 relative flex flex-col">
                           {profile_details.user.id === comment.created_by.id && <PiDotsThreeOutlineFill
                              className="text-[1.2rem] text-gray-700 cursor-pointer"
                              onClick={() => handleShowMenu(comment.id)}
                           />}
                           {/* <div className=" flex flex-col items-center gap-1">
                              <IoMdHeart className="text-[0.8rem] text-[red]" />
                              <p className=" text-gray-700 text-[10px]">36k</p>
                           </div> */}
                           {comment.id === menuId && (
                              <div
                                 ref={menuRef}
                                 className="absolute top-4 right-2 h-auto w-20 bg-black z-50 rounded-md"
                              >
                                 <div className="grid grid-cols-12 items-center text-white hover:text-red-900 text-[14px] md:text-[12px] p-1 cursor-pointer" onClick={() => handleupdateComment(comment.id)}>
                                    <div className="col-span-3">
                                       <BiEdit className="" />
                                    </div>
                                    <div className="col-span-9" >Edit</div>
                                 </div>
                                 <div className="grid grid-cols-12 items-center text-white hover:text-red-900 text-[14px] md:text-[12px] p-1 cursor-pointer" onClick={() => handleDeleteComment(comment.id)}>
                                    <div className="col-span-3">
                                       <RiDeleteBin4Line className=" " />
                                    </div>
                                    <div className="col-span-9">Delete</div>
                                 </div>
                                 <div className="grid grid-cols-12 items-center text-white hover:text-red-900 text-[14px] md:text-[12px] p-1 cursor-pointer">
                                    <div className="col-span-3">
                                       <MdReplyAll className="" />
                                    </div>
                                    <div className="col-span-9" onClick={() => handleReply(comment.id)}>Reply</div>
                                 </div>
                              </div>
                           )}
                        </div>
                        {comment.reply_count !== 0 && <>

                           <div className="col-span-12 flex justify-center items-center">
                              <p
                                 className={`text-[10px]  font-semibold text-center cursor-pointer ${showReply && replyId === comment.id ? "text-red-900" : "text-gray-600"
                                    }`}
                                 onClick={() => handleShowReply(comment.id)}
                              >
                                 {showReply && replyId === comment.id ? "close" : `View (${comment?.reply_count}) more replies`}
                              </p>
                           </div></>}
                        {showReply && replyId === comment.id &&
                           replyData?.map((reply) => (
                              <>
                                 <div className="col-span-2 my-1"></div>
                                 <div className="col-span-10 my-1 bg-gray-200 rounded-md p-1">
                                    <div className="grid grid-cols-12">
                                       <div className="col-span-2 flex justify-center items-start">
                                          <Avatar
                                             className="h-7 w-7"
                                             src={reply.created_by.avatar}
                                             alt="commentuserProfile "
                                          />
                                       </div>
                                       <div className="col-span-9 grid grid-cols-12">
                                          <div className="col-span-12">
                                             <p className="text-[16px] md:text-[13px] font-bold">
                                                {reply.created_by.first_name}{reply.created_by.last_name}
                                             </p>
                                          </div>
                                          <div className="col-span-12">
                                             <p className="text-[14px] md:text-[12px] font-medium line-clamp-2">
                                                {reply.body}
                                             </p>
                                          </div>
                                       </div>
                                       <div className="col-span-1 relative">
                                          {profile_details.user.id === reply.created_by.id ? <PiDotsThreeOutlineFill
                                             className="text-[1.2rem] text-gray-700 cursor-pointer"
                                             onClick={() => handleShowMenuReply(reply.id)}
                                          /> : <MdOutlineReportGmailerrorred className="text-[1.2rem] text-gray-700 cursor-pointer" />}
                                          {reply.id === replyMenuId && (
                                             <div
                                                ref={menuRef}
                                                className="absolute top-4 right-2 h-auto w-20 bg-black z-50 rounded-md"
                                             >
                                                <div className="grid grid-cols-12 items-center text-white hover:text-red-900 text-[14px] md:text-[12px] p-1 py-[1px] cursor-pointer" onClick={() => handleUpdateCommentReply(reply.id)}>
                                                   <div className="col-span-3">
                                                      <BiEdit className="" />
                                                   </div>
                                                   <div className="col-span-9">Edit</div>
                                                </div>
                                                <div className="grid grid-cols-12 items-center text-white hover:text-red-900 text-[14px] md:text-[12px] p-1  py-[1px] cursor-pointer" onClick={() => handleDeleteReply(reply.id)}>
                                                   <div className="col-span-3">
                                                      <RiDeleteBin4Line className=" " />
                                                   </div>
                                                   <div className="col-span-9">Delete</div>
                                                </div>
                                             </div>
                                          )}
                                       </div>
                                    </div>
                                 </div>
                              </>
                           ))}
                     </div>
                     <hr className="my-1 h-[0.5px] bg-gray-400 border-0" />
                  </div>
               ))}
               {commentData?.length === 0 &&
                  <div className='flex justify-center items-center md:h-[60.5vh] h-[72.7vh]'><p className='text-gray-500'>No comments</p></div>}

            </div>
         }
         {replyShowInput ?
            <div className='bg-blue-gray-100 h-[15vh] px-2 py-1 sticky bottom-[6.7rem] flex-col'>
               <div className="grid grid-cols-12">

                  <div className="col-span-11 bg-white p-1 rounded-md grid grid-cols-12 items-center">
                     <div className="col-span-1">
                        <Avatar
                           className="h-6 w-6 "
                           src={replyToCemment[0]?.created_by.avatar}
                           alt="commentuserProfile"
                        />
                     </div>

                     <div className="col-span-10 px-2">
                        <p className="font-bold text-[13px]">{replyToCemment[0]?.created_by.first_name} </p>
                        <p className="text-[14px] font-medium  line-clamp-1">
                           {replyToCemment[0]?.body}
                        </p>
                     </div>

                  </div>
                  <div className="col-span-1">
                     <IoCloseCircle color="red" className="text-[1.3rem] cursor-pointer" onClick={() => setReplyShowInput(null)} />
                  </div>
                  <div className="col-span-2">

                  </div>

                  <div className='col-span-12 mt-4 relative w-full flex justify-center items-center'>
                     <input ref={inputRef} onKeyDown={handleReplyKeyDown} className='w-full h-10 pl-2 pr-8 placeholder:text-[16px] rounded-md outline-none' name="body" placeholder='reply to commnets....' value={reply.body} onChange={handleChangereply} />
                     <RiSendPlaneFill className='text-[1.5rem] absolute z-50 top-2.5 right-2 text-black cursor-pointer' onClick={handleSubmitReply} />
                  </div>
               </div>
            </div>
            :
            <div className='bg-blue-gray-100 h-[5vh] px-2 py-1 sticky bottom-[7.5rem] md:bottom-[6.7rem] '>
               <div className='relative h-full w-full flex justify-center items-center' >
                  <input ref={commentinputRef} onKeyDown={handleKeyDown} className='w-full h-full pl-2 pr-8 placeholder:text-[16px] rounded-md outline-none' name="body" placeholder='share your commnets....' value={comment.body} onChange={handleCommentChange} />
                  <RiSendPlaneFill className='text-[1.5rem] absolute z-50 top-[5px] right-2 text-black cursor-pointer' onClick={submitComment} />
               </div>
            </div>
         }
      </>
   );
}

