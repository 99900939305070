import { Avatar } from "@material-tailwind/react";
import React from "react";

export default function PostBlockModal({ setBlockUser, unblockUser, postData, handleSubmit }) {
    const lable = `C / FE / POST ${unblockUser ? "UNBLOCK" : "BLOCK"} PAGE / V2 / DEC 2, 2024`;
    const copyright = "Copyright © 2024  Galileo One Inc";

    return (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-start pt-10 justify-center z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg w-11/12 max-w-lg relative">
                <div div className="flex justify-start">
                    <p className="text-[16px] text-[black] font-bold">{unblockUser ? "Unblock" : "Block?"}</p>
                </div>
                {!postData.reposted_at && <div className="flex items-center w-full">
                    <div className="grid grid-cols-12 w-full">
                        <p className="col-span-12 flex justify-start text-start text-[12px] py-1 leading-4 line-clamp-2">
                            {postData?.body}
                        </p>
                        {postData.image && <div className="col-span-12 flex justify-center text-start text-[12px] leading-4 line-clamp-2">
                            <img src={postData?.image} className="h-32 w-full object-cover" />
                        </div>}
                        {postData.video && <div className="col-span-12 text-[12px] flex justify-center leading-4 line-clamp-2">
                            <video src={postData?.video} muted disablePictureInPicture autoPlay loop className="h-32 w-full object-cover" />
                        </div>}

                    </div>
                </div>}
                {postData.reposted_at && <>
                    <div className="flex items-center w-full">
                        <div className="grid grid-cols-12 w-full">
                            <div className="col-span-12">
                                <p className="text-[12px] text-gray-500">Reposted by <span className="text-[12px] text-[#000] font-bold">{postData.reposted_by.first_name} {postData.reposted_by.last_name}</span></p>
                            </div>
                            <div className="col-span-2">
                                <Avatar src={postData?.original_post?.created_by.avatar} className="h-8 w-8" circle />
                            </div>
                            <p className="col-span-10 flex justify-start items-center text-[12px] py-1 leading-4 line-clamp-2">
                                {postData?.original_post?.body}
                            </p>
                            {postData.original_post.image && <div className="col-span-12 flex justify-center text-start text-[12px] leading-4 line-clamp-2">
                                <img src={postData?.original_post.image} className="h-32 w-full object-cover" />
                            </div>}
                            {postData.original_post.video && <div className="col-span-12 text-[12px] flex justify-center leading-4 line-clamp-2">
                                <video src={postData?.original_post.video} muted disablePictureInPicture autoPlay loop className="h-32 w-full object-cover" />
                            </div>}
                        </div>
                    </div>
                    {/* <div className="flex items-center w-full">
                        <div className="grid grid-cols-12 w-full">
                            <p className="col-span-12 flex justify-start text-start text-[12px] py-1 leading-4 line-clamp-2">
                                {postData?.body}
                            </p>
                            {postData.image && <div className="col-span-12 flex justify-center text-start text-[12px] leading-4 line-clamp-2">
                                <img src={postData?.image} className="h-32 w-full object-cover" />
                            </div>}
                            {postData.video && <div className="col-span-12 text-[12px] flex justify-center leading-4 line-clamp-2">
                                <video src={postData?.video} muted disablePictureInPicture autoPlay loop className="h-32 w-full object-cover" />
                            </div>}
                        </div>
                    </div> */}
                </>}
                <div className="flex justify-center items-center my-2 gap-3">
                    <button
                        onClick={() => handleSubmit(postData?.id)}
                        className="bg-black text-white px-10 py-[3px] rounded-full text-[12px] flex items-center justify-center gap-1"
                    >
                        Block
                    </button>
                    <button
                        onClick={() => setBlockUser(false)}
                        className="rounded-full text-[12px] px-10 h-7 bg-[#d3d3d3] font-bold hover:bg-[#d3d3d3] text-[#000]"
                    >
                        Cancel
                    </button>
                </div>
                <div className="text-center leading-3">
                    <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
                    <p className="text-black text-[8px]">{copyright}</p>
                </div>
            </div>
        </div>
    )
}

