import { createSlice } from "@reduxjs/toolkit";


const copyRightSlice = createSlice({
    name: "copyRight",
    initialState: {
        label: 'C / FE / HomePage / V6 / SEP 05, 2024',
        copyRights: "Copyright © 2024  Galileo One Inc",
    },
    reducers: {
        setLabel: (state, action) => {
            state.label = action.payload
        },
    },

});

export const { setLabel } = copyRightSlice.actions;
export default copyRightSlice.reducer;
