import React, { useState, useEffect } from "react";
import { LuDot } from "react-icons/lu";
import { FaRegComment, FaRegHeart, FaHeart, FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import { LuRepeat2 } from "react-icons/lu";
import { FaRegBookmark, FaBookmark } from "react-icons/fa6";
import { FiBarChart2 } from "react-icons/fi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { GoMute, GoUnmute } from "react-icons/go";
import ReactPlayer from "react-player";
// import { groupPostData } from "../../../data";


const AccountOwnerGroupPost = ({ onShare, postData, groupId, getRelativeTime }) => {
    const navigate = useNavigate();
    const [likes, setLikes] = useState({});
    const [bookmarks, setBookmarks] = useState({});
    const [groupPostData, setGroupPostData] = useState([]);

    const handleLike = (postId) => {
        setLikes((prevLikes) => ({
            ...prevLikes,
            [postId]: !prevLikes[postId],
        }));
    };

    const handleBookmark = (postId) => {
        setBookmarks((prevBookmarks) => ({
            ...prevBookmarks,
            [postId]: !prevBookmarks[postId],
        }));
    };




    // VIDEO PLAYER FUNCTIONALITY
    const [playing, setPlaying] = useState({});
    const [muted, setMuted] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState({});
    const [duration, setDuration] = useState({});
    // const [initialRender, setInitialRender] = useState(true)

    const handlePlayPause = (id) => {
        setPlaying(prev => {
            const newState = { ...prev };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[id] = !prev[id]; // Toggle between play and pause
            return newState;
        });
    };

    // SOUND FUNCTIONALITY
    const handleMute = () => {
        setMuted(!muted);
    };

    const handleProgress = (id, state) => {
        setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
    };

    const handleDuration = (id, duration) => {
        setDuration(prev => ({ ...prev, [id]: duration }));
    };

    const formatDuration = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds);
        const timeString = date.toISOString().substr(14, 5);
        return timeString;
    };



    useEffect(() => {
        setGroupPostData(postData)
    }, [postData])

    return (
        <div className="px-0">
            {groupPostData?.map((data) => (
                <div
                    key={data.id}
                    className="px-2 py-1 grid grid-cols-12 border-b-[0.4px] border-[#b2b2b2]"
                >
                    <div className="col-span-2">
                        <img
                            alt={data.created_by.username}
                            src={data.created_by.avatar}
                            className="h-8 w-8 rounded-full object-cover"
                        />
                    </div>
                    <div className="col-span-10 -ml-2">
                        <div className="flex flex-col">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                    <p className="text-black text-[12px] font-bold">{data.created_by.first_name}  {data.created_by.last_name}</p>
                                    <div className="flex items-center">
                                        <p className="text-[#5c5c5c] text-[12px] font-thin">{data.created_by.username}</p>
                                        <LuDot className="text-[#5c5c5c] text-[12px]" />
                                        <p className="text-[#5c5c5c] text-[12px] font-thin">{getRelativeTime(data?.created_at)}</p>
                                    </div>
                                </div>
                                <BsThreeDotsVertical
                                    className="text-[#5c5c5c] cursor-pointer absolute right-0"
                                />
                            </div>
                            <p
                                className="text-black text-[12px]"
                                onClick={() => navigate(`/group/${groupId}/postview/${data.id}`)}
                            >
                                {data.body}
                            </p>
                        </div>
                        {data.image && (
                            <img
                                alt={data.body}
                                src={data.image}
                                onClick={() => navigate(`/group/${groupId}/postview/${data.id}`)}
                                className="w-full h-auto object-cover rounded-[6px]"
                            />
                        )}
                        {data.video && (
                            <div className="mt-1 rounded-sm relative" >
                                <ReactPlayer
                                    onClick={() => navigate(`/group/${groupId}/postview/${data?.id}`)}
                                    url={data.video}
                                    playing={playing[data?.id] || false}
                                    muted={muted}
                                    onProgress={(state) => handleProgress(data?.id, state)}
                                    onDuration={(duration) => handleDuration(data?.id, duration)}
                                    width="100%"
                                    height="100%"
                                    className="cursor-pointer"
                                />
                                <button
                                    onClick={() => handlePlayPause(data?.id)}
                                    className="absolute z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                                >
                                    {playing[data?.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                                </button>
                                <button
                                    onClick={handleMute}
                                    className="absolute z-30 bottom-2 right-2 text-[13px] text-white bg-[#0000008b] p-[4px] rounded-full"
                                >
                                    {muted ? <GoMute /> : <GoUnmute />}
                                </button>
                                <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                                    {formatDuration(duration[data?.id] - playedSeconds[data?.id] || 0)}
                                </button>
                            </div>
                        )}
                        <div className="flex items-center justify-between mt-2 text-[#4d4d4d]">
                            <div className="flex items-center cursor-pointer gap-1" onClick={() => navigate(`/group/${groupId}/comment/${data.id}`)}>
                                <FaRegComment className="text-[14px]" />
                                <p className="text-[12px]">{data.comments_count}</p>
                            </div>
                            <div className="flex items-center cursor-pointer gap-1" onClick={() => handleLike(data.id)}>
                                {likes[data.id] ? <FaHeart className="text-[14px] text-red-500" /> : <FaRegHeart className="text-[14px]" />}
                                <p className="text-[12px]">{data.likes_count}</p>
                            </div>
                            <div className="flex items-center cursor-pointer gap-1">
                                <LuRepeat2 className="text-[14px]" />
                                <p className="text-[12px]">{data.reposts_count}</p>
                            </div>
                            <div className="flex items-center cursor-pointer gap-1">
                                <FiBarChart2 className="text-[14px]" />
                                <p className="text-[12px]">{"5k"}</p>
                            </div>
                            <div className="flex items-center gap-2">
                                {data.usersaved ?
                                    <FaBookmark
                                        className="text-[12px] text-blue-500 cursor-pointer"
                                        onClick={() => handleBookmark(data.id)}
                                    /> :
                                    <FaRegBookmark
                                        className="text-[12px] cursor-pointer"
                                        onClick={() => handleBookmark(data.id)}
                                    />}
                                <AiOutlineShareAlt
                                    className="text-[14px] cursor-pointer"
                                    onClick={() => onShare("https://www.post.share.com")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {groupPostData.length === 0 &&
                <div className="flex justify-center items-start h-[30vh]">
                    <p className="text-[#5c5c5c] text-[14px]">No post available</p>
                </div>
            }
        </div>
    )
}

export default AccountOwnerGroupPost;
