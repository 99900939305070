import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { recoverPassword } from "../../redux/apislice/authSlice";
import toast, { Toaster } from "react-hot-toast";
import { EncryptFn } from "../../hooks/EncryptFn";
import { C_LOGO } from "../../utils/mainUrl";

const RecoverPass = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [email, setEmail] = useState("");
   const auth = useSelector((state) => state.auth);

   function validateEmail(email) {
      // Regular expression for validating an email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
   }

   const handleRecoverPass = async (e) => {
      e.preventDefault();
      if (validateEmail(email) && email.length > 0) {
         const toastId = toast.loading("Sending OTP...");
         try {
            const result = await dispatch(recoverPassword(email));
            if (result.error) {
               toast.error("Error", { id: toastId });
            } else {
               toast.success('OTP sent successfully!', { id: toastId });
               const encryptedEmail = EncryptFn(email);
               localStorage.setItem("email_id", encryptedEmail);

               if (auth.loading === false) {
                  setTimeout(() => {
                     navigate(`/enterotp`);
                  }, 3000);
               }
            }
         } catch (error) {
            toast.error("Failed to send OTP.", { id: toastId });
            console.error("error", error);
         }
      } else {
         toast.error("Please enter a valid email address.");
      }
   };
   return (
      <div className="flex flex-col justify-center items-center px-4 mt-[4rem]">
         <Toaster position="top-right" />
         {/* <p className="text-[#7000FF] font-bold text-[3.3rem] md:text-[40px] mb-3">Creative</p> */}
         <img src={C_LOGO} alt="logo" className="h-36 w-36" />
         <div className="h-[43vh] w-full rounded-[20px] p-4 bg-white overflow-hidden">
            <p className="font-bold text-[24px] md:text-[20px] text-center">Recover my password</p>
            <p className="text-[16px] md:text-[13px] text-center">Please enter your email for OTP verification</p>
            <form className="mt-7" onSubmit={handleRecoverPass}>
               <div className="mb-2">
                  <p className="text-black text-[15px] md:text-[13px] font-bold">Email</p>
                  <input
                     required
                     type="email"
                     placeholder="Enter your email address"
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                     className="w-full focus:outline-none border-gray-700 px-[8px] py-[8px] md:py-[4px] border-[1px] rounded-md"
                  />
               </div>
               <div className="flex justify-end items-center mb-8">
                  <p className="text-[#7000FF] font-bold text-[15px] md:text-[13px] cursor-pointer">
                     Change my email
                  </p>
               </div>
               <div className="flex justify-between items-center">
                  <button
                     className="bg-[#7000FF] text-white w-[38%] rounded-md py-[10px] md:py-[5px]"
                     onClick={() => navigate("/login")}
                  >
                     Back
                  </button>
                  <button
                     type="submit"
                     className="bg-[#7000FF] text-white w-[38%] rounded-md py-[10px] md:py-[5px]"
                  >
                     Send OTP
                  </button>
               </div>
            </form>
         </div>
      </div>
   )
}

export default RecoverPass