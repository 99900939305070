import React, { Fragment, useEffect, useState ,useCallback } from "react";
import { LuDot } from "react-icons/lu";
import { FaBookmark, FaHeart, FaPauseCircle, FaPlayCircle, FaRegBookmark, FaRegComment, FaRegHeart } from "react-icons/fa";
import { LuRepeat2 } from "react-icons/lu";
import { FiBarChart2 } from "react-icons/fi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TimeFormat } from "../../hooks/TimeFormat";
import ReactPlayer from "react-player";
import { GoMute, GoUnmute } from "react-icons/go";
import { getAccountPost, postSave } from "../../redux/apislice/postSlice";
import { postLike } from "../../redux/apislice/homeSlice";
import { createRepost } from "../../redux/apislice/repostSlice";

const Media = ({ onShare, setShareModal, setPostId ,user_id }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
   const [mainPost, setMainPost] = useState([])


    // VIDEO PLAYER FUNCTIONALITY
    const [playing, setPlaying] = useState({});
    const [muted, setMuted] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState({});
    const [duration, setDuration] = useState({});


    const handleShare = (id) => {
        setShareModal(true)
        setPostId(id)
    }

    const handlePlayPause = (id) => {
        setPlaying(prev => {
            const newState = { ...prev };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[id] = !prev[id]; // Toggle between play and pause
            return newState;
        });
    };

    // SOUND FUNCTIONALITY
    const handleMute = () => {
        setMuted(!muted);
    };

    const handleProgress = (id, state) => {
        setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
    };

    const handleDuration = (id, duration) => {
        setDuration(prev => ({ ...prev, [id]: duration }));
    };

    const formatDuration = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds);
        const timeString = date.toISOString().substr(14, 5);
        return timeString;
    };

    const fetchData = useCallback(async () => {
        await dispatch(getAccountPost(user_id)).then((result) => {
            if (result.error) {
                console.log(result.error)
            } else {
                setMainPost(result?.payload?.results?.posts?.filter(data => data.image !== null || data.video !== null))
            }
        });
    }, [user_id, dispatch])

    useEffect(() => {
        fetchData()
    }, [fetchData])


    const handleLike = async (id) => {
        const response = await dispatch(postLike(id));
        if (response.meta.requestStatus === 'fulfilled') {
            setMainPost((prevResults) =>
                prevResults.map((item) => {
                    if (item.id === id) {
                        // Toggle `userliked` and adjust `likes_count`
                        const isLiked = !item.userliked;
                        const likesCount = isLiked
                            ? item.likes_count + 1
                            : item.likes_count - 1;

                        return {
                            ...item,
                            userliked: isLiked,
                            likes_count: likesCount,

                        };
                    }
                    return item;
                })
            );

            // Refresh mainPost data after successful like
        } else {
            console.error('Like action failed');
        }
    };



    const handleSave = (id) => {
        dispatch(postSave(id)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                setMainPost((prevResults) =>
                    prevResults.map((item) => {
                        if (item.id === id) {
                            // Toggle `usersaved`
                            return {
                                ...item,
                                usersaved: !item.usersaved,
                            };
                        }
                        return item;
                    })
                );
            } else {
                console.error('Save action failed');
            }
        });
    }



    const handleRepost = (id) => {
        const payloadData = { original_post_id: id }
        dispatch(createRepost(payloadData)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {

                setMainPost((prevResults) =>
                    prevResults.map((item) => {
                        if (item.id === id) {
                            // Toggle `userreposted` and adjust `reposts_count`
                            const isReposted = !item.userreposted;
                            const repostsCount = isReposted
                                ? item.reposts_count + 1
                                : item.reposts_count - 1;

                            return {
                                ...item,
                                userreposted: isReposted,
                                reposts_count: repostsCount,

                            };
                        }
                        return item;
                    })
                );
            } else {
                console.error('Repost action failed');
            }
        });
    }


    return (
        <Fragment>
            <div className="px-0">
                {mainPost?.map((data) => {
                    const date = TimeFormat(data?.created_at);

                    return (
                        <div className="px-2 py-1 grid grid-cols-12 border-b-[0.4px] border-[#b2b2b2]" key={data.id}>
                            <div className="col-span-2">
                                <img
                                    alt="profileimg"
                                    className="h-8 w-8 rounded-full object-cover"
                                    src={data.created_by.avatar}
                                />
                            </div>
                            <div className="col-span-10 -ml-2">
                                <div className="flex flex-col">
                                    <div className="flex items-center justify-between relative">
                                        <div className="flex flex-col items-start leading-4 truncate mr-3">
                                            <p className="text-black text-[12px] font-bold">{data.created_by.first_name} {data.created_by.last_name}</p>
                                            <div className="flex items-center">
                                                <p className="text-[#5c5c5c] text-[12px] font-thin">{data.created_by.username}</p>
                                                <LuDot className="text-[#5c5c5c] text-[12px]" />
                                                <p className="text-[#5c5c5c] text-[12px] font-thin">{date}</p>
                                            </div>
                                        </div>
                                        <BsThreeDotsVertical
                                            className="text-[#5c5c5c] cursor-pointer absolute -right-2 top-[2px]"
                                        />
                                    </div>
                                    <p className="text-black text-[12px] mt-2 leading-[15px]">
                                        {data.body}
                                    </p>
                                </div>
                                {data.image === null ? null :
                                    <img
                                        alt={data.postContent}
                                        src={data.image}
                                        className="w-full h-auto object-cover rounded-[6px]"
                                    />
                                }
                                {data?.video &&
                                    <div className="mt-1 rounded-sm relative" >
                                        <ReactPlayer
                                            onClick={() => navigate(`/postview/${data?.id}`)}
                                            url={data.video}
                                            playing={playing[data?.id] || false}
                                            muted={muted}
                                            onProgress={(state) => handleProgress(data?.id, state)}
                                            onDuration={(duration) => handleDuration(data?.id, duration)}
                                            width="100%"
                                            height="100%"
                                            className="cursor-pointer"
                                        />
                                        <button
                                            onClick={() => handlePlayPause(data?.id)}
                                            className="absolute z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                                        >
                                            {playing[data?.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                                        </button>
                                        <button
                                            onClick={handleMute}
                                            className="absolute z-30 bottom-2 right-2 text-[13px] text-white bg-[#0000008b] p-[4px] rounded-full"
                                        >
                                            {muted ? <GoMute /> : <GoUnmute />}
                                        </button>
                                        <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                                            {formatDuration(duration[data?.id] - playedSeconds[data?.id] || 0)}
                                        </button>
                                    </div>
                                }
                                {/* <div className="grid grid-cols-10 items-center mt-2 text-[#4d4d4d]">
                                    <div className="col-span-2">
                                        <div className="flex items-center cursor-pointer gap-1" onClick={() => navigate("/group/888/comment/56789")}>
                                            <FaRegComment className="text-[14px]" />
                                            <p className="text-[12px]">{data.comments_count}</p>
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="flex items-center cursor-pointer gap-1" onClick={() => setLike(!like)}>
                                            {like ? <FaHeart className="text-[14px] text-red-500" /> : <FaRegHeart className="text-[14px]" />}
                                            <p className="text-[12px]">{data.likes_count}</p>
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="flex items-center cursor-pointer gap-1">
                                            <LuRepeat2 className="text-[14px]" />
                                            <p className="text-[12px]">{data?.postRepost}</p>
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="flex items-center cursor-pointer gap-1">
                                            <FiBarChart2 className="text-[14px]" />
                                            <p className="text-[12px]">{data.view_count}</p>
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="flex items-center justify-end gap-2">
                                            {bookmark ?
                                                <FaBookmark
                                                    className="text-[12px] text-blue-500 cursor-pointer"
                                                    onClick={() => setBookmark(!bookmark)}
                                                /> :
                                                <FaRegBookmark
                                                    className="text-[12px] cursor-pointer"
                                                    onClick={() => setBookmark(!bookmark)}
                                                />}
                                            <AiOutlineShareAlt
                                            onClick={()=>handleShare(data.id)}
                                                className="text-[14px] cursor-pointer"
                                            // onClick={() => onShare("other/user/account/post")}
                                            />
                                        </div>
                                    </div>
                                </div> */}

                                <div className="grid grid-cols-10 items-center mt-2 text-[#4d4d4d]">
                                    <div className="col-span-2">
                                        <div className="flex items-center cursor-pointer gap-1" onClick={() => navigate(`/post/comment/${data.id}`)}>
                                            <FaRegComment className="text-[14px]" />
                                            <p className="text-[12px]">{data.comments_count}</p>
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="flex items-center cursor-pointer gap-1">
                                            {data.userliked ? <FaHeart className="text-[14px] text-red-500" onClick={() => handleLike(data.id)} /> : <FaRegHeart className="text-[14px]" onClick={() => handleLike(data.id)} />}
                                            <p className="text-[12px]">{data.likes_count}</p>
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="flex items-center cursor-pointer gap-1">
                                            <LuRepeat2 className={`text-[14px] ${data?.userreposted ? "text-blue-600" : ""}`} onClick={() => handleRepost(data.id)} />

                                            <p className="text-[12px]">{data?.reposts_count}</p>
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="flex items-center cursor-pointer gap-1">
                                            <FiBarChart2 className="text-[14px]" />
                                            <p className="text-[12px]">{data.view_count}</p>
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="flex items-center justify-end gap-2">
                                            {data.usersaved ?
                                                <FaBookmark
                                                    className="text-[12px] text-blue-500 cursor-pointer"
                                                /> :
                                                <FaRegBookmark
                                                    className="text-[12px] cursor-pointer"
                                                    onClick={() => handleSave(data.id)}
                                                />}
                                            <AiOutlineShareAlt
                                                onClick={() => handleShare(data.id)}
                                                className="text-[14px] cursor-pointer"
                                            // onClick={() => onShare("other/user/account/post")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )

                })}
                {mainPost?.length === 0 && (<div className="text-center text-[#5c5c5c] mt-2 text-[13px] h-36 flex justify-center items-center">No media found</div>)}
            </div>
        </Fragment>
    )
}

export default Media