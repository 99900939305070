import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegComment, FaRegHeart, FaHeart, FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import { LuRepeat2 } from "react-icons/lu";
import { FaRegBookmark, FaBookmark } from "react-icons/fa6";
import { FiBarChart2 } from "react-icons/fi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { groupPostData } from "../../data";
import { getPostDetails, postSave, postView } from "../../redux/apislice/postSlice";
import { useDispatch, useSelector } from "react-redux";
import { createReply, deleteReply, getComments, getReply } from "../../redux/apislice/commentSlice";
import ReactPlayer from "react-player";
import { GoMute, GoUnmute } from "react-icons/go";
import { postLike } from "../../redux/apislice/homeSlice";
import { startFollow } from "../../redux/apislice/profileSlice";
import Skeleton from "react-loading-skeleton";
import { IoCaretBackCircle, IoClose, IoSend } from "react-icons/io5";
import { createRepost } from "../../redux/apislice/repostSlice";
import toast, { Toaster } from "react-hot-toast";
import ShareModal from "../common/ShareModal";
// import BlockModal from "../common/BlockModal";
import ReportModal from "../common/ReportModal";
import { TimeFormat } from "../../hooks/TimeFormat";
import PostBlockModal from "../common/PostBlockModal";
import { postBlock } from "../../redux/apislice/postBlockSlice";

const PostViewDetails = () => {
    const label = "C / FE / POST DETAILS / V2 / SEP 17, 2024";

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { postId } = params;
    const [likes, setLikes] = useState(false);
    const [likeCount, setLikeCount] = useState(578);
    const [bookmarks, setBookmarks] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [blockModal, setBlockModal] = useState(false);
    const [reportModal, setReportModal] = useState(false);
    const commentData = useSelector((state) => state.comments);
    const postDetails = useSelector((state) => state.post);
    const profile = useSelector((state) => state.profile);
    const { user, loading } = postDetails
    const [follow, setFollow] = useState(false)
    const [commentLikes, setCommentLikes] = useState(
        groupPostData.map((data) => ({
            id: data.id,
            likes: false,
            likeCount: data.likeCount || 0,
        }))
    );
    const [activeMenuId, setActiveMenuId] = useState(null);
    const [repliesVisible, setRepliesVisible] = useState({});
    const [replyId, setReplyId] = useState(null);
    const [isBottomDrawerOpen, setIsBottomDrawerOpen] = useState(false);
    const bottomDrawerRef = useRef(null);
    const menuRef = useRef(null);
    const [replyShowInput, setReplyShowInput] = useState(null)
    const [reply, setReply] = useState({ body: "" })

    const toggleBottomDrawer = () => {
        setIsBottomDrawerOpen(!isBottomDrawerOpen);
    };

    const handleReply = (comment_Id) => {
        toggleBottomDrawer()
        setReplyShowInput(comment_Id);
    };


    useEffect(() => {
        setFollow(user?.created_by?.user_follow)
    }, [user])


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setActiveMenuId(null);
                setReplyId(null);
            }
            if (bottomDrawerRef.current && !bottomDrawerRef.current.contains(event.target)) {
                setIsBottomDrawerOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef, bottomDrawerRef]);

    const handleLikeClick = async () => {
        setLikeCount(likes ? likeCount - 1 : likeCount + 1);
        setLikes(!likes);
        const response = await dispatch(postLike(postId));
        if (response.meta.requestStatus === 'fulfilled') {
            dispatch(getPostDetails(postId));
        } else {
            console.error('Like action failed');
        }
    };

    const handleCommentLikeClick = (commentId) => {
        setCommentLikes((prevCommentLikes) =>
            prevCommentLikes.map((comment) =>
                comment.id === commentId
                    ? {
                        ...comment,
                        likes: !comment.likes,
                        likeCount: comment.likes ? comment?.likeCount - 1 : comment?.likeCount + 1,
                    }
                    : comment
            )
        );
    };

    const toggleMenu = (commentId) => {
        setActiveMenuId((prevId) => (prevId === commentId ? null : commentId));
    };

    const toggleRepliesVisibility = (id) => {
        setRepliesVisible((prev) => ({
            ...prev,
            [id]: !prev[id]
        }));
        setReplyShowInput((prev) => prev === id ? null : id);

    };

    useEffect(() => {
        if (replyShowInput) {
            dispatch(getReply(replyShowInput));
        }
    }, [dispatch, replyShowInput]);


    //FOLLOW FUNCTIONALITY
    const handleFollow = async (user_id) => {
        await dispatch(startFollow(user_id))
        setFollow(!follow)
    }

    console.log(profile)

    // VIDEO PLAYER FUNCTIONALITY
    const [playing, setPlaying] = useState({});
    const [muted, setMuted] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState({});
    const [duration, setDuration] = useState({});

    const handlePlayPause = (id) => {
        setPlaying(prev => {
            const newState = { ...prev };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[id] = !prev[id]; // Toggle between play and pause
            return newState;
        });
    };

    // SOUND FUNCTIONALITY
    const handleMute = () => {
        setMuted(!muted);
    };

    const handleProgress = (id, state) => {
        setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
    };

    const handleDuration = (id, duration) => {
        setDuration(prev => ({ ...prev, [id]: duration }));
    };

    const formatDuration = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds);
        const timeString = date.toISOString().substr(14, 5);
        return timeString;
    };

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        const timeString = date.toLocaleString('en-US', options);
        const dateString = date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }).replace(',', '');
        return `${timeString}, ${dateString}`;
    }
    const timestamp = user?.created_at;
    const formattedTime = formatTimestamp(timestamp);

    const handleRepost = () => {
        const payloadData = { original_post_id: postId }
        dispatch(createRepost(payloadData)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                if (result.payload) {
                    toast.success("Repost successful");
                    dispatch(getPostDetails(postId));
                } else {
                    toast.error("Removed the repost");
                    dispatch(getPostDetails(postId));
                }
            } else {
                console.error('Repost action failed');
            }
        });
    }
    const handleSave = () => {
        dispatch(postSave(postId)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                const { detail } = result.payload
                toast.success(detail);
                setBookmarks(true);
            } else {
                console.error('Save action failed');
            }
        });
    }


    const handleSubmitReply = async () => {
        if (reply && reply.body) {
            const response = await dispatch(createReply({ comment_Id: replyShowInput, body: reply.body }));
            if (response.meta.requestStatus === 'fulfilled') {
                setReply({ body: "" });
                await dispatch(getReply(replyShowInput));
                dispatch(getComments(postId));
                toggleBottomDrawer()
            } else {
                toast.error('Failed to create the comment');
            }
        } else {
            toast.error('Failed to create the comment reply');
        }
    };


    const handleChangereply = (e) => {
        setReply({ ...reply, [e.target.name]: e.target.value });
    };


    const handleDeleteReply = async (reply_Id) => {
        if (reply_Id) {
            try {
                const response = await dispatch(deleteReply(reply_Id));
                if (response.meta.requestStatus === 'fulfilled') {
                    await dispatch(getComments(postId));
                    await dispatch(getReply(replyShowInput));
                } else {
                    console.error('Failed to delete the comment. Response meta or requestStatus is not fulfilled.');
                }
            } catch (error) {
                toast.error('An error occurred while deleting the reply:', error);
            }
        } else {
            toast.error('reply_Id is undefined or null.');

        }
    };

    const replyData = commentData.data?.filter((data) => data.id === replyShowInput);

    const handleBlockPost = async (postId) => {
        try {
            const response = await dispatch(postBlock({ postId }));
            if (postBlock.fulfilled.match(response)) {
                setBlockModal(false);
                navigate("/home")
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        dispatch(getPostDetails(postId));
        dispatch(getComments(postId));
        dispatch(postView(postId));
    }, [dispatch, postId]);

    return (
        <Fragment>
            <div className="h-screen">
                <Toaster position="top-right" />
                <div className="bg-[#d9d9d9] text-center">
                    {/* <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p> */}
                    <p className="text-black text-[9px]">Copyright © 2024  Galileo One Inc</p>
                </div>
                {loading ? <>
                    {[1].map((index) => (
                        <div className="mb-2 border border-gray-300 p-1" key={index}>
                            <div className="grid grid-cols-12">
                                <div className="col-span-2">
                                    <Skeleton height={43} width={43} />
                                </div>
                                <div className="col-span-10">
                                    <div className="flex flex-col">
                                        <div className="flex justify-between items-center">
                                            <div className="flex gap-1">
                                                <Skeleton width={100} />
                                                <Skeleton width={45} />
                                            </div>
                                            <Skeleton width={28} />
                                        </div>
                                        <Skeleton width={100} />
                                        <Skeleton count={1} />
                                    </div>
                                    <Skeleton height={125} />
                                    <div className="flex justify-between items-center">
                                        <Skeleton height={25} width={25} />
                                        <Skeleton height={25} width={25} />
                                        <Skeleton height={25} width={25} />
                                        <Skeleton height={25} width={25} />
                                        <Skeleton height={25} width={25} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </> :
                    <div className="py-1 border-b-[0.4px] border-[#b2b2b2] bg-white">
                        {/* User name, tagname */}
                        <div className="px-2 bg-white sticky top-0 z-10 py-1 w-full border-b-[0.4px] border-[#b2b2b2]">
                            <div className="flex items-center justify-between w-full">
                                <div className="flex items-center gap-2">
                                    <IoCaretBackCircle
                                        onClick={() => navigate(-1)}
                                        className="text-black text-[24px] cursor-pointer"
                                    />
                                    <img
                                        alt="user"
                                        className="h-8 w-8 rounded-full object-cover bg-gray-500"
                                        src={user?.created_by?.avatar}
                                    />
                                    <div className="flex flex-col leading-4">
                                        <p className="text-black text-[12px] font-bold">
                                            {user?.created_by?.first_name} {user?.created_by?.last_name}
                                        </p>
                                        <p className="text-[#5c5c5c] text-[12px] font-thin">
                                            {user?.created_by?.username}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-x-2">
                                    {profile?.user?.id !== user?.created_by?.id &&
                                        <button
                                            onClick={() => handleFollow(user?.created_by?.id)}
                                            className="text-white bg-black px-3 py-[3px] text-[11px] rounded-full"
                                        >
                                            {follow ? "Unfollow" : "Follow"}
                                        </button>
                                    }
                                    <BsThreeDotsVertical
                                        onClick={() => toggleMenu(0)} // 0 to represent post menu
                                        className="text-[#5c5c5c] cursor-pointer"
                                    />
                                </div>
                                {activeMenuId === 0 && (
                                    <div className="absolute top-0 right-2 bg-[#d9d9d9] py-1 rounded-md text-[12px]" ref={menuRef}>
                                        <p className="px-3 cursor-pointer border-b-[0.4px] border-[#b2b2b2]" onClick={() => navigate(`/post/comment/${postId}`)}>comment</p>
                                        <p className="px-3 cursor-pointer border-b-[0.4px] border-[#b2b2b2]" onClick={() => setReportModal(true)}>Report</p>
                                        <p className="px-3 cursor-pointer" onClick={() => setBlockModal(true)}>Block</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* Post image */}
                        <div className="flex flex-col bg-white">
                            <p className="text-black text-[12px] my-1 px-2">
                                {user?.body}
                            </p>
                        </div>
                        {user?.image &&
                            <img
                                alt="post"
                                src={user?.image}
                                className="w-full h-auto object-cover rounded-[6px] px-2"
                            />
                        }
                        {user?.video &&
                            <div className="mt-1 rounded-sm relative mx-2">
                                <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    url={user?.video}
                                    muted={muted}
                                    disablePictureInPicture
                                    playing={playing[user.id] || false}
                                    onProgress={(state) => handleProgress(user.id, state)}
                                    onDuration={(duration) => handleDuration(user.id, duration)}
                                />
                                <button
                                    onClick={() => handlePlayPause(user.id)}
                                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                                >
                                    {playing[user.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                                </button>
                                <button
                                    onClick={handleMute}
                                    className="absolute bottom-2 right-2 text-[13px] text-white bg-[#0000008b] p-[4px] rounded-full"
                                >
                                    {muted ? <GoMute /> : <GoUnmute />}
                                </button>
                                <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                                    {formatDuration(duration[user.id] - playedSeconds[user.id] || 0)}
                                </button>
                            </div>
                        }
                        {/* Like, Comment, Share, Bookmark */}
                        <div className="flex items-center justify-between mt-2 px-2 text-[#4d4d4d]">
                            <div className="flex items-center cursor-pointer gap-1" onClick={() => navigate(`/post/comment/${postId}`)}>
                                <FaRegComment className="text-[14px]" />
                                <p className="text-[12px]">{user?.comments_count}</p>
                            </div>
                            <div className="flex items-center cursor-pointer gap-1" onClick={handleLikeClick}>
                                {likes ? <FaHeart className="text-[14px] text-red-500" /> : <FaRegHeart className="text-[14px]" />}
                                <p className="text-[12px]">{user?.likes_count}</p>
                            </div>
                            <div className="flex items-center cursor-pointer gap-1" onClick={() => handleRepost()} >
                                <LuRepeat2 className="text-[14px]" />
                                <p className="text-[12px]">12</p>
                            </div>
                            <div className="flex items-center cursor-pointer gap-1">
                                <FiBarChart2 className="text-[14px]" />
                                <p className="text-[12px]">{user?.view_count}</p>
                            </div>
                            <div className="flex items-center gap-2">
                                {bookmarks ?
                                    <FaBookmark
                                        className="text-[12px] text-blue-500 cursor-pointer"
                                        onClick={() => handleSave()}
                                    /> :
                                    <FaRegBookmark
                                        className="text-[12px] cursor-pointer"
                                        onClick={() => handleSave()}
                                    />}
                                <AiOutlineShareAlt
                                    onClick={() => setShareModal(true)}
                                    className="text-[14px] cursor-pointer"
                                />
                            </div>
                        </div>
                    </div>
                }
                {/* Post time */}
                <div className="px-2 py-1 border-b-[0.4px] border-[#b2b2b2]" >
                    <div className="flex items-center gap-1 text-[12px] text-[#4d4d4d]">
                        <p>{formattedTime.split(', ')[0]}</p>
                        <p>·</p>
                        <p>{formattedTime.split(', ')[1]}</p>
                        <p>·</p>
                        <p className="text-[#000000] font-bold">{user?.view_count} Views</p>
                    </div>
                </div>
                {commentData.data?.map((data) => {
                    const commentLike = commentLikes.find(comment => comment.id === data.id);

                    return (
                        <div className="py-1 border-b-[0.4px] border-[#b2b2b2]" key={data.id}>
                            <div className="px-2 flex items-start justify-between relative">
                                <div className="flex items-start gap-2">
                                    <img
                                        alt="user"
                                        className="h-7 w-7 rounded-full object-cover"
                                        src={data?.created_by?.avatar}
                                    />
                                    <div className="flex flex-col leading-[14px]">
                                        <p className="text-black text-[12px] font-bold">{data?.created_by?.first_name}</p>
                                        <p className="text-[#5c5c5c] text-[12px] font-thin">{data?.created_by?.username}</p>
                                    </div>
                                </div>
                                <BsThreeDotsVertical
                                    onClick={() => toggleMenu(data.id)}
                                    className="text-[#5c5c5c] cursor-pointer absolute right-0"
                                />
                                {activeMenuId === data.id && (
                                    <div className="absolute top-[-3px] right-[7px] bg-[#d9d9d9] py-1 rounded-md text-[12px]" ref={menuRef}>
                                        <p className="px-3 py-[1px] cursor-pointer border-b-[0.4px] border-[#b2b2b2]" onClick={() => handleReply(data.id)}>Reply</p>
                                        <p className="px-3 py-[1px] cursor-pointer border-b-[0.4px] border-[#b2b2b2]" onClick={() => setReportModal(true)}>Report</p>
                                        <p className="px-3 py-[1px] cursor-pointer" onClick={() => setBlockModal(true)}>Block</p>
                                    </div>
                                )}
                            </div>
                            <div className="pt-1">
                                <p className="text-black text-[12px] px-2">{data?.body}</p>
                                <div className="flex items-center justify-between pt-[5px] px-2">
                                    {data?.reply_count ? <p
                                        className="text-[10px] font-bold text-light-blue-500 cursor-pointer"

                                    >
                                        {repliesVisible[data?.id] ? <span onClick={() => toggleRepliesVisibility(data?.id)}>
                                            Hide replies
                                        </span> : <span onClick={() => toggleRepliesVisibility(data.id)}>View replies</span>}
                                    </p> : null}


                                    {data?.reply_count ? <div
                                        className="flex items-center cursor-pointer gap-1 text-[#4d4d4d]"
                                        onClick={() => handleCommentLikeClick(data.id)}
                                    >
                                        {commentLike?.likes ? <FaHeart className="text-[12px] text-red-500" /> : <FaRegHeart className="text-[12px]" />}
                                        <p className="text-[10px]">{commentLike?.likeCount}</p>
                                    </div> : null}
                                </div>
                                {data.id === replyShowInput && (
                                    <>
                                        {commentData.replyData.map((reply) => (
                                            <div className="mt-2 pl-4 bg-[#d8d8d8] relative">
                                                <div className="px-2 pt-1 flex items-start justify-between relative">
                                                    <div className="flex items-start gap-2">
                                                        <img
                                                            alt="user"
                                                            className="h-7 w-7 rounded-full object-cover"
                                                            src={reply?.created_by.avatar}
                                                        />
                                                        <div className="flex flex-col leading-[14px]">
                                                            <p className="text-black text-[12px] font-bold">{reply?.created_by?.first_name}</p>
                                                            <p className="text-[#5c5c5c] text-[12px] font-thin">{reply?.created_by?.username}</p>
                                                        </div>
                                                    </div>

                                                    <BsThreeDotsVertical
                                                        className="text-[#5c5c5c] cursor-pointer absolute right-0"
                                                        onClick={() => setReplyId(reply.id)}
                                                    />

                                                    {replyId === reply?.id && (
                                                        <div className="absolute top-[1rem] right-[7px] bg-black py-1 rounded-md text-[12px]" ref={menuRef}>
                                                            <p className="px-3 text-white py-[1px] cursor-pointer hover:text-green-600" onClick={() => handleDeleteReply(reply?.id)}>Delete</p>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="py-1 px-2">
                                                    <p className="text-black text-[12px]">{reply?.body}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
            {isBottomDrawerOpen && (
                <div
                    ref={bottomDrawerRef}
                    id="drawer-bottom-example"
                    className="sticky overflow-hidden bottom-28 md:bottom-24 left-0 rounded-t-3xl right-0 z-50 w-full h-auto p-4 py-1 overflow-y-auto transition-transform bg-black dark:bg-gray-800 transform-none"
                    tabIndex="-1"
                    aria-labelledby="drawer-bottom-label"
                >
                    <h5
                        id="drawer-bottom-label"
                        className="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400"
                    >
                        Reply Comment
                    </h5>
                    <button
                        type="button"
                        onClick={toggleBottomDrawer}
                        aria-controls="drawer-bottom-example"
                        className=" bg-transparent hover:bg-red-500 text-red-600 hover:text-black rounded-lg text-sm w-5 h-5 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                        <IoClose className="font-bold text-[1.5rem]" />
                    </button>

                    <div className="grid grid-cols-12 mb-2">
                        <div className="col-span-2">
                            <img src={replyData[0]?.created_by?.avatar} alt="img" className="rounded-md h-8 w-8 object-cover cursor-pointer" />
                        </div>
                        <div className="col-span-8 flex justify-between flex-col">
                            <div className="flex flex-col justify-between">
                                <p className="text-[12px] text-gray-300 font-bold cursor-pointer">{replyData[0]?.created_by?.first_name} {replyData[0]?.created_by?.last_name}</p>
                                <p className="text-[10px] underline text-gray-400 font-bold cursor-pointer">{replyData[0]?.created_by?.username}</p>
                            </div>
                            <div className="mt-1">
                                <p className="text-[12px] text-gray-500 font-medium">{replyData[0]?.body}</p>
                            </div>
                        </div>
                        <div className="col-span-2 flex flex-col justify-between items-end relative">
                            <p className="text-[8px] font-semibold text-blue-300">{TimeFormat(replyData[0]?.created_at)}</p>
                        </div>
                    </div>
                    <div className="sticky -bottom-4 z-30 w-full bg-black py-2">
                        <div className="">
                            <div className="flex items-center justify-between gap-1">
                                <input
                                    name="body"
                                    value={reply.body}
                                    type="text"
                                    placeholder="Comment here"
                                    onChange={(e) => handleChangereply(e)}
                                    className="bg-white rounded-full focus:outline-none w-full px-3 py-1 text-gray-800"
                                />
                                {
                                    reply.body.length > 0 &&
                                    <div className="bg-white p-2 rounded-full cursor-pointer">
                                        <IoSend className="text-black" onClick={() => handleSubmitReply()} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            )}

            {shareModal && <ShareModal onClose={() => setShareModal(false)} url={`https://cagzeynida.com/postview/${postId}`} />}
            {blockModal && <PostBlockModal
                setBlockUser={setBlockModal}
                postData={user} handleSubmit={handleBlockPost} />}
            {reportModal && <ReportModal setReportPost={setReportModal} postId={postId} postData={user} />}
        </Fragment >
    );
};

export default PostViewDetails;
